import { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import './App.css'
import Layout from "./components/Layout"
import { getQueryStringValue, getPathnameValue } from './utils/index'
import config from './config';

const App: FC = () => {
  const [hasSign, setHasSign] = useState(true)

  // 替换掉地址栏上的sign，防止用户直接复制链接时给其他用户
  const handleSign = () => {
    // const [org, user, code] = getPathnameValue()
    // // 当前链接唯一key
    // const uniqueKey = org + user + code
    // window.sessionStorage.setItem('uniqueKey', uniqueKey)
    const sign = getQueryStringValue("sign")
    const partner = getQueryStringValue("partner")
    const token = getQueryStringValue("token")
    console.log(sign, partner);

    if (sign) {
      window.sessionStorage.setItem('sign', sign)
    }
    if (partner) {
      window.sessionStorage.setItem('partner', partner)
    }
    if (token) {
      window.sessionStorage.setItem('token', token)
    }

    // if (sign) {

    //   let href = window.location.href
    //   href = href.replace(`&sign=${encodeURIComponent(sign)}`, '')
    //   window.location.replace(href)
    // } else {
    //   const _sign = window.sessionStorage.getItem(uniqueKey)
    //   if (_sign) {
    //     setHasSign(true)
    //   } else {
    //     Modal.warning({
    //       title: '温馨提示',
    //       content: '无访问权限，请先回到首页后重新打开~',
    //       centered: true,
    //       onOk: () => {
    //         window.location.replace(config.osURL)
    //       }
    //     });
    //   }
    // }
  }

  useEffect(() => {
    handleSign()
  }, [])

  return (
    <div className="App">
      {
        hasSign ? <Layout /> : <></>
      }
    </div>
  );
}

export default App;
