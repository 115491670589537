// 校验工具函数

// 输入字符串是否为大于0整数，最大为999
const valifyNumber = (_: any, value: string) => {
    if (!Number(value) || Number(value) < 0 || String(value)?.includes('.')) {
        return Promise.reject('请输入大于0的整数')
    }
    if (Number(value) > 999) {
        return Promise.reject('请输入小于999的整数')
    }
    return Promise.resolve()
}

// 检验触发时间
const verifyTime = (_: any, value: string) => {
    if (value === '00:00:00') {
        return Promise.reject('触发时间必须大于0秒')
    }
    const gexp = /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
    if (gexp.test(value)) {
        return Promise.resolve()
    }
    return Promise.reject('请输入正确的时间格式')
}

// 检验是否输入全为空格或回车
const valifyEmpty = (_: any, value: string, tip: string) => {

    if (/\S/.test(value) || value === '') {
        return Promise.resolve()
    }
    return Promise.reject(tip)
}
export {
    valifyNumber,
    verifyTime,
    valifyEmpty
}