import React, { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import './index.less'
import TreeNode from '../TreeNode'
import down_svg from '../../icons/down.svg'
import right_svg from '../../icons/right.svg'
import utils from '../../utils'
import { TreeProps } from '../../types'
import { useDrop } from 'react-dnd'
import { Tooltip } from 'antd'
import SiderTopTitle from '../../../../components/SiderTopTitle'
import SvgIcon from '../../../../components/SvgIcon'
import { Type, projectType } from '../../../../utils/commonTypes'

const DEFAUTL_DELAY_SECONDS: number = 0.05

let Tree: React.FC<TreeProps> = (props, ref) => {

    const { isDrag = false, changeCourse, defaultFold = false, url, modal, treeType, selectedChapter, myResourceUrl, changeTab, projectType = '' } = props

    const [fold, setFold] = useState<boolean>(props?.fold ?? false)
    const [selected, setSelected] = useState<any>(props?.selected)
    const [nodes, setNodes] = useState<any>()
    const [client, setClient] = useState<WebSocket>()

    // 根据nodes生成树形结构
    const [treeNodes, setTreeNodes] = useState<any>([])

    //资源管理tab切换
    const [resourceTab, setResourceTab] = useState(0)

    // 点击折叠
    // const onFold = (): void => {
    //     const _fold = !fold
    //     setFold(_fold)
    //     props?.onFold && props?.onFold(_fold)
    // }

    // 点击刷新  废弃 外面刷新就行
    // const onRefresh = (): void => {
    //     const delay = setTimeout(() => {
    //         if (client) {
    //             client.close()
    //         }
    //         createWebsocketClient()
    //         clearTimeout(delay)
    //     }, DEFAUTL_DELAY_SECONDS * 1000)
    //     setTimeout(() => {
    //         props?.over()
    //     }, 3000)
    //     props?.onRefresh && props?.onRefresh()
    // }

    // 点击折叠
    const onClick = (data?: any) => {
        const _data = nodes?.map((it: any) => {
            if (it?.path === data?.path) {
                it.fold = !it.fold
            }
            return it
        })
        console.log(data);

        setNodes(_data)
        setSelected(data)
        props?.onClick && props?.onClick(data)
    }

    // 进入编辑
    const onEntryEdit = (data: any): any => {
        if (props?.doubleClickToEdit) {
            const _data = nodes?.map((it: any) => {
                if (it?.path === data?.path) {
                    it.edit = true
                }
                return it
            })
            setNodes(_data)
        }
        setSelected(data)
        props?.onEntryEdit && props?.onEntryEdit(data)
    }

    // 退出编辑
    const onBlurEdit = (data: any) => {
        const _data = nodes?.map((it: any) => {
            if (it?.path === data?.path) {
                it.edit = false
            }
            return it
        })
        setNodes(_data)
        setSelected(data)
        props?.onBlurEdit && props?.onBlurEdit(data)
    }

    // 进入更多
    const onEntryMore = (data: any, event: any) => {
        props?.onEntryMore && props?.onEntryMore(data, event)
    }

    // 创建
    const createWebsocketClient = () => {
        try {
            console.log('资源管理Url', url);

            if (url) {
                if (resourceTab === 0) {
                    const ws = new WebSocket(url)
                    setClient(ws)
                } else {
                    if (myResourceUrl) {
                        const ws = new WebSocket(myResourceUrl)
                        setClient(ws)
                    }

                }
            }
        } catch (error) {
            setClient(undefined)
        }
    }

    // 连接
    const onopen = () => {
        // setTryTimes(0)
        console.log('连接成功')
    }

    // 关闭
    const onclose = (event: any): any => {
        props?.onClose && props?.onClose(event)
        console.log('连接断开')
        if (event.code !== 1005 && modal === '1') {
            // setNodes(undefined)
            createWebsocketClient()
        }
        if (event.code !== 1005 && modal === '0') {
            if (props?.title === '资源管理') {
                //目录滞空
                // setNodes(undefined)
                createWebsocketClient()
            } else if (!nodes?.length) {
                // setNodes(undefined)
                createWebsocketClient()
            }
        }

        // props?.over?.()
    }

    const onerror = (err: any) => {
        console.log('连接错误--', err)
    }

    // 消息
    const onmessage = async (event: any): Promise<any> => {
        window?.parent?.postMessage({ type: 'closeLoading' }, '*')
        try {
            const str = event?.data
            if (str) {
                const received = JSON.parse(str)
                console.log('活动能力 => ', received);
                if ('stat' === received?.type) {
                    let filterData = received?.data ?? []
                    let rdata = filterData.filter((item: any) => {
                        if (item?.describe) {
                            return item
                        }
                        return item?.path !== '活动能力' && !(item?.parent?.includes('活动能力'))
                    })
                    if (props?.changedHandle) {
                        rdata = props?.changedHandle(rdata)
                    }
                    const _nodes = nodes
                    const k: any = {}
                    for (let r of _nodes ?? []) {
                        k[r?.path] = r
                    }
                    let data = rdata?.map((it: any) => {
                        const p = it?.path
                        const d = k[p]
                        if (d) {
                            it.fold = d?.fold
                            it.edit = d?.edit // 添加
                        }
                        if (it?.path === selected?.path) {
                            it.edit = selected?.edit ?? false
                            it.fold = selected?.fold ?? false
                        } else {
                            if (!d) {
                                it.edit = false
                                it.fold = defaultFold
                            }
                        }

                        return it
                    })
                    if (selected?.parent) {
                        data = data?.map((item: any) => {
                            if (item?.path === selected?.parent) {
                                return { ...item, fold: true }
                            }
                            return { ...item }
                        })
                    }
                    if (treeType === 'table') {
                        props.backCourseList(data)
                    }
                    setNodes(data)
                }
            }
        } catch (error) {
            console.log('数据处理异常 =>', error)
        }
    }

    //便利资源管理树结构
    const traverseTree = (nodeArray: any, path: any) => {

        for (const node of nodeArray) {
            // console.log(node.path, path);
            if (node?.path === path) {
                return false// 如果找到了目标节点，返回该节点
            }

            if (node?.children?.length > 0) {
                const foundNode: any = traverseTree(node.children, path);


                if (!foundNode) {
                    return false// 递归调用，继续在子节点中查找目标节点
                }
            }
        }
        return true
    }

    // 折叠变化
    useEffect(() => {
        setFold(props?.fold ?? false)
    }, [props?.fold])

    // 选中变化
    useEffect(() => {
        setSelected(props?.selected)
    }, [props?.selected])

    // 选中
    useEffect(() => {
        if (nodes?.filter((it: any) => it?.path === selected?.path)?.length > 0) {
            const data = nodes?.map((it: any) => {
                if (it?.path === selected?.path) {
                    it.edit = selected?.edit ?? false
                    it.fold = selected?.fold ?? false
                } else {
                    it.edit = false
                }
                return it
            })
            setNodes(data)
        } else {
            // onRefresh()
        }
        props?.onSelected && props?.onSelected(selected)
    }, [selected])

    // 链接变化
    useEffect(() => {
        if (url) {
            if (client) {
                client?.close()
            }
            setTreeNodes(undefined)
            setNodes(undefined)
            setClient(undefined)
            createWebsocketClient()
        }
    }, [url, resourceTab])

    // 提供的方法
    useImperativeHandle(ref, () => ({
        // 所有数据
        data: (): any => {
            return nodes
        },
        resourceTab: (): any => {
            return resourceTab
        },
        //上传文件时显示加载状态

        uploadFileChange: (file: any, path: any, data: any): void => {
            console.log('file  =>>>', file, path, data, treeNodes);

            //设置加载中显示的状态
            let fileItem = {
                edit: false,
                fold: false,
                isFolder: false,
                parent: "",
                path: file.name,
                title: file.name,
                ifUpload: true,
                file: path,
                expandIcon: {},
                foldIcon: {

                }
            }
            // if (data) {

            //     const value: any = traverseTree(treeNodes, data?.path)
            //     if (value) {
            //         if (value?.children) {
            //             value?.children.push(fileItem)
            //         } else {
            //             value.children = [fileItem]
            //         }
            //     }
            //     // console.log(treeNodes);

            //     setTreeNodes([...treeNodes])
            // } else {
            //     let list = [fileItem, ...treeNodes]
            //     setTreeNodes(list ?? [])
            // }
            // console.log(treeNodes);
            let list = [fileItem, ...treeNodes]
            setTreeNodes(list ?? [])

        },
        // 当前选中
        selected: (): any => {
            return selected
        },
        // 刷新数据  废弃  外面刷新就行
        // refresh: (): void => {
        //     onRefresh()
        // }
    }))

    // 允许当前区域可以自由拖拉
    const [, drop] = useDrop(() => ({
        accept: ['*']
    }))


    // 监听nodes节点生成树形结构
    useEffect(() => {
        let uplLoadList: any[] = []
        if (treeType === 'resource') {// 是否为资源管理
            let windowUploadList: any = []
            if (window.sessionStorage.getItem('uploadFile')) {
                windowUploadList = JSON.parse(window.sessionStorage.getItem('uploadFile') || '')
            }
            let windowFile: any = []
            if (windowUploadList.length > 0) {
                windowUploadList.forEach((item: any) => {
                    let fileItem = {
                        edit: false,
                        fold: false,
                        isFolder: false,
                        parent: "",
                        path: item.path,
                        title: item.file,
                        file: item.file,
                        ifUpload: true,
                        expandIcon: {},
                        foldIcon: {

                        }
                    }
                    console.log(selectedChapter?.path);

                    if ((item.path === selectedChapter?.path && resourceTab !== 1) || (item.path === props?.myUrl && resourceTab === 1)) {
                        windowFile.push(fileItem)
                    }
                })
            }
            let treeList: any[] = []
            if (treeNodes?.length > 0) {
                treeNodes.forEach((item: any) => {
                    if (item.ifUpload) {
                        treeList.push(item)
                    }
                })
            }

            uplLoadList = windowFile.filter((obj: any) => {
                return !treeList.some((bObj: any) => {
                    return obj.title === bObj.title
                });
            }).concat(treeList);
        }
        let noTreeList: any = utils.tree(nodes ?? []).filter((obj: any) => {
            return !obj.ifUpload
        })

        //将现有的数据和在上传的数据 做合并
        // let treeNodesList = uplLoadList.filter((obj: any) => {
        //     return traverseTree(treeNodes, obj?.file)
        // })
        console.log(uplLoadList);

        let treeNodesList = uplLoadList.filter((obj: any) => {
            // console.log(traverseTree(noTreeList, obj?.file));

            return traverseTree(noTreeList, obj?.file)
        }).concat(noTreeList);
        console.log(treeNodesList);


        setTreeNodes(treeNodesList)
    }, [nodes])

    useEffect(() => {
        if (client) {
            client.onmessage = onmessage
            client.onopen = onopen
            client.onclose = onclose
            client.onerror = onerror
        }
    }, [client, nodes])


    // 记录当前的选中的节点
    const [currentSelected, setCurrentSelected] = useState<any>([])
    useEffect(() => {
        if (currentSelected.length) {
            const temp = nodes.filter((item: any) => {
                return item.path.startsWith(currentSelected[1].path)
            })
            const is = temp.some((item: any) => {
                return item.path === selected?.path
            })
            if (is) {
                const first = currentSelected[0] ? currentSelected[0].split('/') : []
                const second = selected?.path.split('/')
                for (let i = 0; i < first.length; i++) {
                    second[i] = first[i]
                }
                const newpath = second.join('/')
                onClick({ ...selected, path: newpath, parent: currentSelected[0] })
            }
            setCurrentSelected([])
        }
    }, [currentSelected])

    //监听新增滚动
    useEffect(() => {
        const bodyElement = document.querySelectorAll('.body');
        const selectedElement = document.querySelectorAll('.selected');
        if (treeType === 'table') {
            if (bodyElement?.length > 0 && selectedElement?.length > 0) {
                let bodyItem = bodyElement[0] as HTMLElement
                let selectItem = selectedElement[0] as HTMLElement
                selectItem?.parentElement?.parentElement?.scrollTo({ top: selectItem?.offsetTop - bodyItem?.clientHeight / 2 - 10, behavior: 'smooth' });
            }
        }

    }, [nodes])

    // 用来改变树形结构函数
    const changeNodes = (changeNode: any, changeIndex: any, location: number) => {
        // 拖动目标与放置目标一致，不做处理
        if (changeNode.path === changeIndex.path) return

        // 判断当前放置的目录是否是同一级
        let isLevel = changeNode.describe.type === changeIndex.describe.type
        if (['text', 'video', 'notebook'].includes(changeNode.describe.type) && ['text', 'video', 'notebook'].includes(changeIndex.describe.type)) {
            isLevel = true
        }

        // 同名文件或目录判断：同一个目录下不允许相同的文件或目录
        if (isLevel) {
            let children = nodes.filter((item: any) => {
                return item.parent === changeIndex.parent
            })
            children = children.filter((item: any) => {
                return item.path !== changeNode.path
            })
            const isSame = children.some((item: any) => {
                return item.title === changeNode.title
            })
            if (isSame) {
                props?.tip('文件夹内已有重名课程，无法移动')
                return
            }
        } else {
            if (location === 1) {
                props?.tip('当前位置不可拖动')
                return
            }
            const children = nodes.filter((item: any) => {
                return item.parent === changeIndex.path
            })
            const isSame = children.some((item: any) => {
                return item.title === changeNode.title && item.path !== changeNode.path
            })
            if (isSame) {
                props?.tip('文件夹内已有重名课程，无法移动')
                return
            }
            if (children.some((item: any) => item.path === changeNode.path)) {
                // 说明该item已经在该目录中的第一个，无需做任何改变
                if (changeNode.describe.index === 0) {
                    return
                }
            }
        }

        // 目录改变后重新选中当前选中的目录
        if (isLevel) {
            setCurrentSelected([changeIndex.parent, changeNode])
        } else {
            setCurrentSelected([changeIndex.path, changeNode])
        }

        const title = changeNode.title
        const path = changeNode.path
        const parent = isLevel ? changeIndex.parent : changeIndex.path
        const describe = { ...changeNode.describe }

        // 需要修改的节点
        let children: Array<any>

        if (isLevel) {
            children = nodes.filter((item: any) => {
                return item.parent === changeIndex.parent
            })
            children = children.filter((item: any) => {
                return item.path !== changeNode.path
            })

            const newChangeNode = { ...changeNode, describe: { ...changeNode.describe } }
            if (location === 1) {
                newChangeNode.describe.index = changeIndex.describe.index - 0.5
            } else {
                newChangeNode.describe.index = changeIndex.describe.index + 0.5
            }
            newChangeNode.parent = parent
            children.push(newChangeNode)
            children.sort((a: any, b: any) => a.describe.index - b.describe.index)
        } else {
            children = nodes.filter((item: any) => {
                return item.parent === changeIndex.path && item.path !== changeNode.path
            })

            describe.index = -1
            const newChangeNode = { ...changeNode, title, path, parent, describe }
            children.push(newChangeNode)
            children.sort((a: any, b: any) => a.describe.index - b.describe.index)

        }

        const promise = children.map((item: any, index: number) => {
            return changeCourse(item.title, item.path, item.parent, { ...item.describe, index })
        })

        Promise.all(promise).then(res => {
            //    console.log('都成功了')
        }).catch(err => {
            // console.log(err)
        })
    }

    //   目录是否展开   改变目录是否展开
    const { open = true, changeOpen } = props


    // 针对课程目录，当点击课程目录外的其他地方，失去当前选中
    useEffect(() => {
        const handle = (e: any) => {
            const element = document.getElementById('body-drop-catalogue') as HTMLDivElement
            if (element && !element.contains(e.target)) {
                setSelected(undefined)
                props?.onClick?.(undefined)
            }
        }
        if (treeType === 'resource' && selected && Object.keys(selected)?.length) {
            document.body.addEventListener('click', handle)
        }
        return () => {
            if (treeType === 'resource' && selected && Object.keys(selected)?.length) {
                document.body.removeEventListener('click', handle)
            }
        }
    }, [treeType, selected])


    return (
        <div className='component'>
            <div className='component-header'>
                <SiderTopTitle
                    // title={(treeType === 'resource' && modal === '0') ? <div className='component-header-title'>
                    //     <span
                    //         className='component-header-btn'
                    //         style={{ fontWeight: resourceTab === 0 ? '600' : '400', color: resourceTab === 0 ? 'white' : '#9D9D9D' }}
                    //         onClick={() => {
                    //             setResourceTab(0)
                    //             changeTab?.(0)
                    //         }}
                    //     >{props?.title}</span>
                    //     {/* {
                    //         (treeType === 'resource' && modal === '0' && projectType !== Type.PROJECT) ? <span
                    //             className='component-header-btn'
                    //             style={{ marginLeft: '40px', fontWeight: resourceTab === 1 ? '600' : '400', color: resourceTab === 1 ? 'white' : '#9D9D9D' }}
                    //             onClick={() => {
                    //                 setResourceTab(1)
                    //                 changeTab?.(1)
                    //             }}
                    //         >我的资源</span> : <></>
                    //     } */}
                    // </div> : <span>{props?.title}</span>
                    // }
                    title={props?.title}
                    open={open}
                    changeOpen={changeOpen}
                    follow={treeType !== 'resource'}
                    showOpenTriangle={!!props?.title}
                    menus={
                        <>
                            {
                                props?.customIcons && props?.customIcons?.map((it: any, index: any) => {
                                    if (index > 0 && treeType === 'resource' && props.type !== 'active') {
                                        return <></>
                                    }
                                    if (props.type === 'active' && index < 1) {
                                        return <></>
                                    }
                                    if (!it?.icon) return <></>
                                    return <>
                                        <Tooltip key={it?.name} placement="bottomLeft" arrow={false} title={it?.name} overlayClassName='item-tooltip'>
                                            <div className='item-tooltip-mask' key={index} onClick={(event) => {
                                                it?.onClick?.(selected, event)
                                            }}>
                                                {it?.icon}
                                            </div>
                                        </Tooltip>
                                    </>
                                })
                            }
                        </>
                    }
                />
            </div>
            {
                open && <>
                    {
                        treeType === 'resource' && props.type !== 'active' && <div className='resourceBtn' onClick={() => {
                            props?.onClick && props?.onClick()
                        }}>
                            <div className='rightBtn' style={{ marginTop: props.type !== 'active' ? '' : '5px', }}>
                                {
                                    props?.customIcons && props?.customIcons?.map((it: any, index: any) => {
                                        // console.log(props?.customIcons, 'props?.customIconsprops?.customIcons');

                                        if (index < 1) {
                                            return <></>
                                        }
                                        if (index === 1 && resourceTab === 1) {
                                            return <></>
                                        }
                                        return <>
                                            {it?.icon && <Tooltip key={index} placement="bottom" title={it.name} arrow={false} color='white' overlayInnerStyle={{ color: '#666666', padding: '0 4px' }}>
                                                <div className='icon' key={index} onClick={(event) => {
                                                    it?.onClick && it?.onClick(selected, event)
                                                    event?.stopPropagation()
                                                }}>
                                                    {it?.icon}
                                                </div>
                                            </Tooltip>}
                                        </>

                                    }
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        !fold && <div className='body' style={{ paddingBottom: treeType === 'resource' ? '36px' : '' }}>
                            {treeNodes?.length > 0 ?
                                <div ref={drop} className='body-drop' id={treeType === 'resource' ? 'body-drop-catalogue' : ''}>
                                    {
                                        treeNodes?.map((it: any) =>
                                            <TreeNode
                                                data={it}
                                                key={it?.path + it?.size}
                                                nodes={nodes}
                                                isDrag={isDrag}
                                                changeNodes={changeNodes}
                                                moreIcon={props?.moreIcon}
                                                doubleClickToEdit={props?.doubleClickToEdit ?? false}
                                                showMoreIcon={props?.showMoreIcon ?? true}
                                                selected={selected}
                                                onClick={onClick}
                                                onEntryEdit={onEntryEdit}
                                                onBlurEdit={onBlurEdit}
                                                onEntryMore={onEntryMore}
                                            />
                                        )
                                    }
                                </div> : treeType !== 'resource' ? <div></div> : <div className='body-none'>
                                    这里还没有内容，你可以上传或者新建项目文件或文件夹噢~
                                </div>
                            }
                        </div>
                    }
                </>
            }

        </div>
    )
}


Tree = forwardRef(Tree as ForwardRefRenderFunction<any, TreeProps>)

export default Tree
