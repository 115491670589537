/*
 * 工具
 */
import React, { useEffect, useState } from 'react'
import { Modal, Tooltip, message } from 'antd'
import './index.less'
import SvgIcon from '../SvgIcon'
import AddTools from '../AddTools'
import { getPortImg } from '../../api/modules/community'
import Manage from './Manage'
import { setPort, queryPort, initPort, delPort, resetPort } from '../../api/modules/tools'
import { rename } from '../../api/modules/fs'
import SiderTopTitle from '../SiderTopTitle'
import defimg1 from '../../assets/images/toolsimgdark/1.png'
import defimg2 from '../../assets/images/toolsimgdark/2.png'
import defimg3 from '../../assets/images/toolsimgdark/3.png'
import defimg4 from '../../assets/images/toolsimgdark/4.png'
import defimg5 from '../../assets/images/toolsimgdark/5.png'
import defimg6 from '../../assets/images/toolsimgdark/6.png'
import defimg7 from '../../assets/images/toolsimgdark/7.png'
import config from '../../config'
import { courseTypes } from '../../utils/commonTypes'
import {getQueryStringValue} from "../../utils/index";
import {getTemplateById} from "../..//api/modules/cli";

const defImg = [defimg1, defimg2, defimg3, defimg4, defimg5, defimg6, defimg7]

type ToolsProps = {
  modal?: any
  section?: any          // 当前小节信息
  labCode?: any          // 实验室编码
  repo?: any             // 仓库
  envPod?: any           // 全局实验环境
  userId?: any           // 用户id
  currentChapter?: any   // 当前选中小节的所有信息
  open?: boolean         // 是否展开
  changeOpen?: Function  // 是否展开
  courseType?: string   // 课程类型
  examType?: any     // 考试
  libroEditorRef?: any
  [other: string]: any
  setcurrentChapter?:(data:any)=>void  //重制当前选中小节的所有信息
}

const Tools: React.FC<ToolsProps> = (props) => {

  const { courseType, examType, setcurrentChapter, modal, setSection, section, labCode, repo, envPod, userId, currentChapter, open, changeOpen, libroEditorRef } = props

  // 固定工具
  const tools = [
    {
      id: '_MdEditor_',
      name: 'Editor',
      icon: 'editor',
      disable: false,
      click: () => {
        if (section.type === 'notebook') {
          window.postMessage({
            type: 'pushContainer',
            component: 'LibroEditor'
          })
        }else if (section.type === 'text' && section?.editMode === 'new') {
          window.postMessage({
            type: 'pushContainer',
            component: 'Editor',
            value: {
              path: 'index.md'
            }
          })
        } else {
          window.postMessage({
            type: 'pushContainer',
            component: 'MdEditor',
            value: {
              path: 'index.md'
            }
          })
        }
      }
    },
    // {
    //   id: '_Markdown_',
    //   name: 'Markdown',
    //   icon: 'markdown',
    //   disable: false,
    //   click: () => {
    //     window.postMessage({
    //       type: 'pushContainer',
    //       component: 'Markdown'
    //     })
    //   }
    // },
    {
      id: '_VideoEditor_',
      name: 'VideoEditor',
      icon: 'videoeditor',
      disable: false,
      click: () => {
        window.postMessage({
          type: 'pushContainer',
          component: 'VideoEditor'
        })
      }
    },
    {
      id: '_VideoPlayer_',
      name: 'VideoPlayer',
      icon: 'videoplayer',
      disable: false,
      click: () => {
        window.postMessage({
          type: 'pushContainer',
          component: 'VideoPlayer'
        })
      }
    },
    // {
    //   id: '_ChatGPT_',
    //   name: 'ChatGPT',
    //   icon: 'gpt',
    //   click: () => {
    //     window.postMessage({
    //       type: 'pushContainer',
    //       component: 'GPT'
    //     })
    //   }
    // },
    // {
    //   id: '_Shell_',
    //   name: 'Shell',
    //   icon: 'shell',
    //   click: () => {
    //     window.postMessage({
    //       type: 'pushContainer',
    //       component: 'Xterm',
    //       value: {
    //         tool: "shell"
    //       }
    //     })
    //   }
    // },
    {
      id: '_CodePlayer_',
      name: 'CodePlayer',
      icon: 'codeplayer',
      disable: false,
      click: () => {
        window.postMessage({
          type: 'pushContainer',
          component: 'Webview',
          value: {
            path: `${config?.codePlayerURL}/embedded`,
            name: 'CodePlayer',
            // 这边无法直接通过icon传递icon组件，通过定义值，外面再添加icon
            customIcon: 'codeplayer'
          }
        })
      }
    },
  ]
  //Pod.yaml路径
  const location = getQueryStringValue("location")

  const [isAddTools, setIsAddTools] = useState(false)         // 控制添加端口工具的弹框
  const [showManage, setShowManage] = useState(false)         // 控制显示管理

  const [toolsData, setToolsData] = useState<any>([])         // 工具栏

  const [ports, setPorts] = useState<any>([])                 // 当前所有配置的端口工具
  const [portCount, setPortCount] = useState<number>(0)                 //当前port工具个数
  const [images, setImages] = useState<any>([])               // 静态图片
  const [loading, setLoading] = useState(false)               // 工具栏加载中

  const [currentDefault, setCurrentDefault] = useState(  0)      // 当前默认打开的工具


  // 初次加载静态图片
  useEffect(() => {
    getPortImg().then(res => {
      setImages(res.course)
    }).catch(() => { })
  }, [])

  // 获取静态的工具（与环境无关的工具，与模式、课程类型相关）
  const getStaticTools = () => {
    if (!section?.type) return []
    const _tools = tools.filter((item: any) => {
      // 创作模态：文本型课程显示MdEditor
      if (item.id === '_MdEditor_' && (modal !== '1' || section?.type === 'video')) {
        return false
      }
      // 创作模态：视频型课程显示VideoEditor
      if (item.id === '_VideoEditor_' && (modal !== '1' || section?.type === 'text')) {
        return false
      }
      // if (item.id === '_Markdown_' && modal === '0' && section?.type === 'video') {
      //   return false
      // }
      if (item.id === '_VideoPlayer_' && (section?.type !== 'video' || modal === '1')) {
        return false
      }
      return true
    })
    return _tools
  }

  // 获取shell工具
  const getShell = () => {
    if (section?.type && section?.template?.experEnv) {
      return [{
        id: '_Shell_',
        name: 'Shell',
        icon: 'shell',
        disable: envPod?.pod ? false : true,
        click: () => {
          window.postMessage({
            type: 'pushContainer',
            component: 'Xterm',
            value: {
              tool: "shell",
              defaultShell: defaultShell
            }
          })
        }
      }]
    } else {
      return []
    }
  }

  // 配置端口
  const fetchSetPort = async (lab: string, repo: string, path: string, template: string, port: object) => {
    if (!lab || !repo || !path || !template || !Object.keys(port)?.length) return
    setLoading(true)
    const data = { lab, repo, path, template, port }
    const res = await setPort(data)
    setLoading(false)
    return res
  }

  // 端口查询
  const fetchQueryPort = async (lab: string, repo: string, path: string, template: string) => {
    if (!lab || !repo || !path || !template) return
    const res = await queryPort({ lab, repo, path, template, choice: undefined })
    return res
  }

  // 请求删除端口
  const fetchDelPort = async (lab: string, repo: string, path: string, template: string, portIds: string) => {
    if (!lab || !repo || !path || !template || !portIds) return
    return await delPort({ lab, repo, path, template, portIds })
  }

  // 对设置的端口进行处理
  const handlePort = (res: any[]) => {
    res = res?.filter(item => item?.choice)
    return res?.map((item: any) => {

      // 对图片做映射处理，因为颜色不一致
      if (item?.image) {
        const res = item?.image?.match(/(\d+)\.png$/)?.[1]
        if (res && ['1', '2', '3', '4', '5', '6', '7'].includes(res)) {
          item.image = defImg[parseInt(res) - 1]
        }
      }
      if (item?.name === 'notebook' && envPod?.pod) {
        libroEditorRef?.current?.changeUrl(item?.host)
      }
      return {
        id: item?.id,
        name: item?.name,
        image: item?.image || defimg1,
        host: item?.host,
        type: item?.type ?? 'system',
        port: item?.port,
        disable: envPod?.pod ? false : true,   // 是否禁用
        click: () => {
          window.postMessage({
            type: 'pushContainer',
            component: 'Webview',
            value: {
              path: item?.host,
              name: item?.name,
              icon: item?.image || defimg1,
              pod: envPod
            }
          })
        }
      }
    })
  }

  // 添加工具端口
  const addPort = async (data: any) => {
    const params = {
      name: data?.name,
      image: data?.image,
      port: parseInt(data?.port),
      choice: true,
      isUse:true,
      type: modal === '1' ? 'creator' : 'learner'
    }
    const code = await fetchSetPort(labCode, repo, section?.path, section?.template?.experEnv, params)
    if (code) {
      const res = await fetchQueryPort(labCode, repo, section?.path, section?.template?.experEnv)
      setPorts(res || [])
    }
  }

  // 删除端口
  const deletePort = async (id: string) => {
    const code = await fetchDelPort(labCode, repo, section?.path, section?.template?.experEnv, id)
    if (code) {
      const res = await fetchQueryPort(labCode, repo, section?.path, section?.template?.experEnv)
      setPorts(res || [])
    }
  }

  let defaultShell=""
  const fetchDefaultShell=async ()=>{
    try {
      if (section?.template?.defaultShell){
        defaultShell=section?.template?.defaultShell
      }else {
        const res= await getTemplateById({id:envPod.labels.templateId,lab:envPod.labels.lab})
        defaultShell=res?.defaultShell;
      }
    }catch (e){

    }
  }

  // 聚合所有的工具
  useEffect(() => {
    if (section?.path) {
      const staticTools = getStaticTools()
      const shell = getShell()
      const port = handlePort(ports)
      setPortCount(port?.length)
      // if (envPod?.pod) {
      setToolsData([...staticTools, ...shell, ...port])
      // } else {
      //   setToolsData([...staticTools])
      // }
    }
    fetchDefaultShell();
  }, [envPod?.pod, ports, section])

  // pod改变初始化
  const init = async () => {
    // if (!envPod?.pod) {
    //   setPorts([])
    //   return
    // }
    let res=[];
    //如果是默认预览博客则封装端口工具
    if (location) {
      res = section?.template?.ports.map((i: any) => {
        if (i.isDefaultTool){
          setCurrentDefault(i.port)
        }
        const foundPort = envPod?.ports?.find((port: { targetPort: number }) => port.targetPort === i.port);
        return {
          ...i,
          host: "https://" + foundPort?.host,
          id: foundPort?.name
        }
      })
     const data= res?.filter((i:any)=>i.isUse !== false)
      setPorts(data ?? [])
    }else {
      res = await fetchQueryPort(labCode, repo, section?.path, section?.template?.experEnv)
      const data= res?.filter((i:any)=>i.isUse !== false)

      setPorts(data ?? [])
    }

  }

  // 根据条件重新查询
  useEffect(() => {
    if (labCode) {
      init()
    }
  }, [section, envPod?.pod, labCode])

  useEffect(() => {
    if (!envPod?.pod) {
      setIsAddTools(false)
      setShowManage(false)
    }
  }, [envPod?.pod])

  const showModal = () => {
    Modal.confirm({
      title: "工具无法打开！",
      content: '您还未启动环境，请先启动环境后再使用工具。',
      cancelText: '我知道了',
      okText: '启动环境',
      centered: true,
      width: 400,
      onOk(close) {
          close()
          // 直接启动环境：在启动环境组件里监听该消息
          window.postMessage({
              type: 'startupEnv'
          })
      }
  })
  }

  // 管理获取接口
  const fetchManagePorts = async () => {
    return await fetchQueryPort(labCode, repo, section?.path, section?.template?.experEnv)
  }

  // 管理保存
  const manageFinish = async (data: any, defaultOpen: number) => {
    const params = {
      lab: labCode,
      repo,
      path: section?.path,
      template: section?.template?.experEnv,
      ports: data ?? []
    }
    if (defaultOpen || defaultOpen === 0) {
      const reqData = {
        repo,
        key: userId,
        data: {
          title: currentChapter?.title,
          path: currentChapter?.path,
          parent: currentChapter?.parent,
          describe: currentChapter?.describe ?? {},
        }
      }
      // const tamp = data?.some((item: any) => item?.port === defaultOpen)
      if (reqData?.data?.describe?.template) {
        reqData.data.describe.template.defaultOpen = defaultOpen
        reqData.data.describe.template.defaultOpenName = data?.find((item: any) => item?.port === defaultOpen)?.name
      }
      const resData = await rename(reqData)
    }
    const code = await resetPort(params)
    if (code) {
      const res = await fetchQueryPort(labCode, repo, section?.path, section?.template?.experEnv)
      setPorts(res ?? [])
    }
    //当前小节具体有没有设置环境 判断环境继承 并添加 默认启动工具
    if (setcurrentChapter && currentChapter?.describe?.template===undefined) {
      const port = data.find((i:any) => i.port === defaultOpen);

      const currentChapterData= {
      ...currentChapter,
          describe: {
        ...currentChapter.describe,
            template: {
        ...currentChapter.template,
              defaultOpen:defaultOpen,
              defaultOpenName: port?.name,
              ports: data
        }

      },
      template:{
        ...currentChapter.template,
        defaultOpen:defaultOpen,
        defaultOpenName: port?.name,
        ports: data
      }
      }
      setcurrentChapter(currentChapterData)

     const sectionData={
      ...section,
          template:currentChapterData.describe.template
      }
      setSection(sectionData)
    }else{
      const port = data.find((i:any) => i.port === defaultOpen);
      setSection(
          {
            ...section,
            template: {
              ...section.template,
              defaultOpen:defaultOpen,
              defaultOpenName: port?.name,
              ports: data
            }
          }
      )
    }
    setShowManage(false)
  }

  return (
    <div id='tools' style={{height: '100%'}}>
      <SiderTopTitle
        title={courseType === courseTypes.COURSE && examType !== '0' ? `实验工具${section?.title ? '-' : ''}${section?.title ?? ''}` : '实验工具'}
        open={open}
        changeOpen={(open: boolean) => {
          changeOpen?.(open)
        }}
        menus={
          <div className='tools-menus'>
            <div className='tools-menus-left'>
              {
                loading ? <div className='tools-loading'>
                  <SvgIcon iconClass={'loading'} fontSize='16px' className='tools-loading-rotate' />
                  <span>加载中...</span>
                </div> : <></>
              }
            </div>
            <div className='tools-menus-right'>
              {
                // portCount > 0 || section?.template?.expertools
                section?.type && section?.template?.experEnv ? <>
                  <Tooltip placement="bottomLeft" arrow={false} title={'工具管理'} overlayClassName='item-tooltip'>
                    <div className='item-tooltip-mask'>
                      <SvgIcon iconClass={'tools-manage'} className='tools-menus-right-icon' onClick={async () => {
                        if (location) {
                          const split =location.split("/")
                          try {
                            if (split.length===4) {

                             const res = await getTemplateById({id: split[2], lab:split[1]})

                              if(res.openTools || res.openTools===null || res.openTools===undefined) {
                                 setCurrentDefault(0)
                              }else {
                              const  findIndex= res?.containers[0]?.ports?.find((i:any)=>i.isDefaultTool)
                                setCurrentDefault(findIndex === undefined ? -1 : findIndex.port)
                              }
                            }
                        } catch (e) {
                            setCurrentDefault(0)

                          }
                          setShowManage(true)
                          return
                        }
                        if (currentChapter?.describe?.template?.defaultOpen === undefined) {
                          let port = -1
                          if (currentChapter?.describe?.template !== undefined && currentChapter?.describe?.template?.ports?.length > 0) {
                            port = currentChapter?.describe?.template?.ports?.find((i: any) => i.isDefaultTool)?.port;
                          } else {
                            port = currentChapter?.template?.ports?.find((i: any) => i.isDefaultTool)?.port;
                          }
                          let res = {
                            openTools: true
                          }
                          try {
                            const split =section?.template.experEnv.split("/")
                            if (split.length===4){
                              res = await getTemplateById({id: split[2], lab:split[1]})
                            }
                          } catch (e) {
                          }
                          if(res.openTools === undefined || res.openTools === null ? true : res.openTools) {
                            setCurrentDefault(port === undefined ? 0 : port)
                          }else {
                            setCurrentDefault(port === undefined ? -1 : port)
                          }
                        } else {

                          setCurrentDefault(currentChapter?.describe?.template?.defaultOpen)
                        }

                        setShowManage(true)
                      }} />
                    </div>
                  </Tooltip>
                  <Tooltip placement="bottomLeft" arrow={false} title={'添加'} overlayClassName='item-tooltip'>
                    <div className='item-tooltip-mask'>
                      <SvgIcon iconClass={'tools-add'} className='tools-menus-right-icon' onClick={() => {
                        const res = ports?.filter((item: any) => modal === '1' ? item?.type === 'creator' : item?.type === 'learner') ?? []
                        if (res?.length >= 10) {
                          message.destroy()
                          message.warning('添加已达上限，请删除实验工具后重新添加')
                          return
                        }
                        setIsAddTools(true)
                      }} />
                    </div>
                  </Tooltip>
                </>:<></>
              }
            </div>
          </div>
        }
      >
        <div className='tools'>
          {
            toolsData.map((item: any) => {
              return (
                <div key={item.id} className={item?.disable ? 'tools-tool tool-disable' : 'tools-tool'} onClick={() => {
                  if (item?.disable) {
                    showModal()
                    return
                  }
                  item?.click?.()
                }}>
                  {
                    item?.port && item?.type !== 'system' ? <>
                      {
                        (modal === '1' && item?.type === 'creator' || modal === '0' && item?.type === 'learner') ?
                          <div className='tools-tool-del' onClick={(e) => { e?.stopPropagation(); deletePort(item?.id) }}>
                            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_2704_2947)">
                                <path className='path1' d="M11.3855 2.61921C11.9494 3.18313 12.3922 3.83993 12.7015 4.57134C13.0219 5.32881 13.1844 6.1332 13.1844 6.96215C13.1844 7.79097 13.0219 8.59536 12.7015 9.35283C12.3922 10.0842 11.9494 10.741 11.3855 11.3049C10.8216 11.8689 10.1648 12.3117 9.43336 12.621C8.67588 12.9414 7.8715 13.1038 7.04253 13.1038C6.21326 13.1038 5.40889 12.9414 4.6514 12.621C3.91998 12.3117 3.26321 11.8689 2.69928 11.3049C2.13538 10.7411 1.69258 10.0842 1.38322 9.35283C1.06283 8.59536 0.900391 7.79097 0.900391 6.96215C0.900391 6.13318 1.06283 5.32881 1.38322 4.57134C1.69256 3.83991 2.13536 3.18313 2.69928 2.61921C3.26319 2.0553 3.92001 1.6125 4.6514 1.30314C5.40889 0.982756 6.21327 0.820312 7.04253 0.820312C7.8715 0.820312 8.67588 0.982756 9.43336 1.30314C10.1648 1.6125 10.8215 2.05528 11.3855 2.61921Z" />
                                <path className='path2' d="M12.7015 4.57134C12.3922 3.83993 11.9494 3.18313 11.3855 2.61921C10.8215 2.05528 10.1648 1.6125 9.43336 1.30314C8.67588 0.982756 7.8715 0.820312 7.04253 0.820312C6.21327 0.820312 5.40889 0.982756 4.6514 1.30314C3.92001 1.6125 3.26319 2.0553 2.69928 2.61921C2.13536 3.18313 1.69256 3.83991 1.38322 4.57134C1.06283 5.32881 0.900391 6.13318 0.900391 6.96215C0.900391 7.79097 1.06283 8.59536 1.38322 9.35283C1.69258 10.0842 2.13538 10.7411 2.69928 11.3049C3.26321 11.8689 3.91998 12.3117 4.6514 12.621C5.40889 12.9414 6.21326 13.1038 7.04253 13.1038C7.8715 13.1038 8.67588 12.9414 9.43336 12.621C10.1648 12.3117 10.8216 11.8689 11.3855 11.3049C11.9494 10.741 12.3922 10.0842 12.7015 9.35283C13.0219 8.59536 13.1844 7.79097 13.1844 6.96215C13.1844 6.1332 13.0219 5.32881 12.7015 4.57134ZM9.70936 9.64284C9.55592 9.79628 9.31419 9.8033 9.16937 9.65853L7.00055 7.4897L4.83142 9.65854C4.68663 9.80331 4.44489 9.79629 4.29144 9.64286C4.13801 9.48941 4.13098 9.24767 4.27576 9.10286L6.44458 6.93417L4.27574 4.76534C4.13098 4.62058 4.13799 4.37881 4.29144 4.22538C4.44487 4.07193 4.68662 4.06491 4.83139 4.20968L7.00055 6.37851L9.16937 4.20965C9.31415 4.06489 9.5559 4.07192 9.70934 4.22535C9.8628 4.3788 9.86981 4.62056 9.72503 4.76533L7.55621 6.93417L9.72503 9.10286C9.86982 9.24765 9.8628 9.48941 9.70936 9.64284Z" />
                              </g>
                              <defs>
                                <clipPath id="clip0_2704_2947">
                                  <rect width="14" height="14" transform="translate(0 -0.00195312)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div> : <></>
                      }
                      {
                        item?.type === 'learner' ? <div className='tools-tool-custom'>
                          自定义
                        </div> : <></>
                      }
                    </> : <></>
                  }
                  <div className='tools-tool-icon'>
                    {
                      item.icon ? <SvgIcon iconClass={item.icon} style={{ width: '20px', height: '20px' }} /> :
                      <img src={item.image} alt="" style={{ width: '20px', height: '20px' }} />
                    }
                  </div>
                  <div className='tools-tool-name'>
                    <span>{item.name}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </SiderTopTitle>

      <AddTools open={isAddTools} onCancel={() => {
        setIsAddTools(false)
      }} ports={ports} finish={addPort} />
      <Manage section={section} open={showManage} onCancel={() => { setShowManage(false) }} getList={fetchManagePorts} modal={modal} finish={manageFinish} currentChapter={currentChapter} currentDefault={currentDefault} setCurrentDefault={setCurrentDefault}/>
    </div>
  )
}

export default Tools