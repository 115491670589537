import SvgIcon from '../../../components/SvgIcon'
import { Checkbox, Form, Input, InputRef, Select, TimePicker, message, Button } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import TipsAbility from './TipsAbility'
import VerifyAbility from './VerifyAbility'
import { valifyEmpty, valifyNumber, verifyTime } from './verifyTools'
import dayjs from 'dayjs'
import RichEditor from '../../../components/RichEditor'

const { TextArea } = Input

type QuesAndAnsProps = {
    quesandans?: Quesandans,
    category: 'text' | 'video',   //课程类型
    mark: boolean,  // 评分模式
    close: Function,
    finish?: (data: Quesandans) => void
}

// 问答数据
export interface Quesandans {
    id: string,  // 唯一标识
    category: 'radio' | 'checkbox',  // 单选  多选
    title: string,  //题目
    time: string, // 触发时间
    options: Array<{   //选项
        key: string,  //  选项key
        value: string  // 选项值
    }>,  //
    selected: string,  //  正确答案
    buttonName: string,  //  按钮名称
    score: number,  //   分数
    required: boolean,  //   是否必答
    requiredCorrect: boolean,  //   是否必须回答正确
    auxiliary: Array<{  //辅助活动
        type: "tip",  //辅助活动类型
        triggerTime: number,  //  触发时机  默认 0
        description: string  //  描述内容
    }> | null
}

const QuesAndAns: React.FC<QuesAndAnsProps> = (props) => {

    const [form] = Form.useForm()
    const TipsAbilityRef = useRef(null)  // 获取提示表单

    const { quesandans, category, mark, close, finish } = props

    const [questionTitle, setQuestionTitlee] = useState<any>()
    const questionEditor = useRef<any>(null)

    // 表单数据定义--------------------
    // 1 问答表单
    const [select, setSelect] = useState('')
    // 选项列表   默认单选
    const radioListMod = [  // 单选默认模板
        {
            key: 'a',
            value: '默认选项1',
            select: true,   // 是否选中
            del: false,   // 是否可以删除
            hover: false,   // hover是否高亮
        },
        {
            key: 'b',
            value: '默认选项2',
            select: false,
            del: true,
            hover: false,
        },
        {
            key: 'c',
            value: '默认选项3',
            select: false,
            del: true,
            hover: false,
        },
        {
            key: 'd',
            value: '默认选项4',
            select: false,
            del: true,
            hover: false,
        }
    ]

    // 单选和多选列表
    const [optionList, setOptionList] = useState<any[]>([])

    // 是否必答
    const [requiredAnswer, setRequiredAnswer] = useState(false)
    // 回答是否正确
    const [requiredTrue, setRequiredTrue] = useState(false)

    // 2 提示表单
    const [tips, setTips] = useState({
        trigger: 0,
        tip: ''
    })

    // 初始化表单数据
    useEffect(() => {
        if (quesandans) {
            const { title, category, buttonName, score, time, options, selected, required, requiredCorrect, auxiliary } = quesandans
            const datetime = time === '' ? dayjs('00:00:01', 'HH:mm:ss') : dayjs(time, 'HH:mm:ss')
            form.setFieldsValue({
                question: title,
                option: category,
                button: buttonName,
                score: score,
                time: datetime
            })
            setQuestionTitlee(title)
            // 单选还是多选数据处理
            const len = options.length
            const list = options?.map((item) => {
                const select = selected.split(',').includes(item.key)
                const del = len === 2 ? false : !select
                return { ...item, hover: false, select, del }
            })
            // if (category === 'radio') {
            //     setRadioList(list)
            // } else {
            //     setCheckboxList(list)
            // }
            setOptionList(list)
            setSelect(category)

            // 是否必答相关
            setRequiredAnswer(required)
            setRequiredTrue(requiredCorrect)

            // 是否有辅助活动
            if (auxiliary && auxiliary?.length) {
                setCanAddActivity(0)
                const tipp = auxiliary[0]
                setTips({
                    trigger: tipp.triggerTime,
                    tip: tipp.description
                })
            }
            return
        }
        form.setFieldsValue({
            question: '',
            option: 'radio',
            button: '提交',
            score: 10,
            time: dayjs('00:00:01', 'HH:mm:ss')
        })
        setSelect('radio')
        setOptionList(radioListMod)
    }, [quesandans])

    // 单选还是多选
    const change = (value: string) => {
        setSelect(value)
        switch (value) {
            case 'radio':
                const radioList = optionList.map((item, index, list) => {
                    const canSelect = index ? false : true
                    let canDel = !canSelect
                    if (list.length < 3) {
                        canDel = false
                    }
                    return { ...item, select: canSelect, del: canDel }
                })
                setOptionList(radioList)
                break
            case 'checkbox':
                const checkboxList = optionList.map((item, index, list) => {
                    const canSelect = index === 0 || index === 1
                    return { ...item, select: canSelect, del: !canSelect }
                })
                setOptionList(checkboxList)
                break
            default:
                break
        }
    }

    // Select删除的规则
    const deleteSelect = (key: string) => {
        const list = [...optionList]
        const li: any = optionList.find((item) => {
            return key === item.key
        })
        if (li.select) {
            message.destroy()
            message.error('正确答案不可删除')
        } else if (!li.del) {
            message.destroy()
            message.error('至少保留两个选项')
        } else {
            const newList = list.filter(item => {
                return item.key !== key
            })
            if (newList.length === 2) {
                const twoList = newList.map(item => {
                    return { ...item, del: false }
                })
                setOptionList(twoList)
            } else {
                setOptionList(newList)
            }
            message.success('删除成功')
        }
    }

    // Select选择正确答案规则
    const choiceSelect = (key: string) => {
        const list = [...optionList]
        if (select === 'radio') {
            const index: any = list.findIndex((item) => {
                return key === item.key
            })
            if (!list[index].select) {
                list[index].select = true
                list[index].del = false
                for (let i = 0; i < list.length; i++) {
                    if (i !== index) {
                        list[i].select = false
                        if (list.length > 2) {
                            list[i].del = true
                        }
                    }
                }
                setOptionList(list)
            }
        } else {
            if (list.length > 2) {
                let index = 0
                list.forEach(item => {
                    if (item.select && key !== item.key) {
                        index++
                    }
                })
                if (index >= 2) {
                    const n: any = list.findIndex((item) => {
                        return key === item.key
                    })
                    list[n].select = !list[n].select
                    list[n].del = !list[n].del
                    setOptionList(list)
                }
            }
        }
    }

    // Select输入框数据的数据控制
    const selectInput = (key: string, value: string) => {
        const list = [...optionList]
        const li: any = list.find((item) => {
            return key === item.key
        })
        li.value = value
        setOptionList(list)
    }

    // Select删除框的hover数据控制 
    const selectDelete = (key: string, hover: boolean) => {
        const list = [...optionList]
        const li: any = list.find((item) => {
            return key === item.key
        })
        li.hover = hover
        setOptionList(list)
    }

    // Select添加选项
    const selectAddOption = () => {
        let newList = [...optionList]
        if (select === 'radio' && optionList.length === 2) {
            newList = newList.map(item => {
                return { ...item, del: item.select ? false : true }
            })
        }
        setOptionList([...newList, {
            key: nanoid(5),
            value: '默认选项',
            select: false,
            del: true,
            hover: false,
        }])
    }

    // 必答选择的规则
    useEffect(() => {
        if (!requiredAnswer && requiredTrue) {
            setRequiredTrue(false)
        }
    }, [requiredAnswer])

    // 必须正确选择的规则
    useEffect(() => {
        if (requiredTrue) {
            setRequiredAnswer(true)
        }
    }, [requiredTrue])

    /**  当前可添加的活动
     *   0   任何辅助活动都不可以添加
     *   1   可以添加提示活动
     */
    const [canAddActivity, setCanAddActivity] = useState(1)

    // 确定
    const submit = () => {
        const el: any = TipsAbilityRef?.current
        el?.set()
        const title = questionEditor?.current?.getHtml()
        form.setFieldsValue({
            question: title,
        })
        Promise.all([el?.validateFields(), form.validateFields()]).then(res => {
            const { question, button, score, time } = res[1]
            const fenzhi = Number(score) || 0
            const datetime = time?.format('HH:mm:ss') || ''
            let selecttrue: string[] = []
            let temp = false
            const list = optionList.map((item) => {
                if (item.value === '') {
                    temp = true
                }
                if (item.select) {
                    selecttrue.push(item.key)
                }
                return {
                    key: item.key,
                    value: item.value
                }
            })
            if (temp) {
                console.log('校验没通过')
                return
            }
            console.log("校验通过")
            const data: Quesandans = {
                id: quesandans?.id ?? '',
                category: select as any,
                title: question,
                options: list,
                selected: selecttrue.join(','),
                buttonName: button,
                score: fenzhi,
                required: requiredAnswer,
                requiredCorrect: requiredTrue,
                time: datetime,
                auxiliary: null
            }
            if (res[0]) {
                data.auxiliary = [{
                    type: 'tip',
                    triggerTime: 0,
                    description: res[0].tip
                }]
            }
            finish?.(data)
            close?.()
        }).catch(err => {
            console.log("校验不通过")
        })
    }


    // 监听是否是评分模式
    useEffect(() => {
        if (mark) {
            setRequiredAnswer(true)
        }
    }, [mark])

    return (
        <>
            <div className='ylzcc-form-head'>
                <span>{quesandans ? '编辑' : '新增'}选择题</span>
            </div>
            <Form
                name="basic"
                colon={false}
                validateTrigger='onBlur'
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    label="设置题目"
                    name={'question'}
                    rules={[
                        { required: true, message: '请输入题目内容' },
                        { min: 3, message: '请至少输入3个字' },
                        { validator: (_, value) => valifyEmpty(_, value, '请输入题目内容') }
                    ]
                    }
                >
                    <RichEditor placeholder='请输入题目内容' ref={questionEditor} defaultValue={questionTitle} />
                </Form.Item>


                <Form.Item
                    label="选项设置"
                    name={'option'}
                    rules={[{ required: true }]}
                >
                    <Select
                        style={{ width: 180 }}
                        onChange={change}
                        options={[
                            { value: 'radio', label: '单选' },
                            { value: 'checkbox', label: '多选' },
                        ]}
                    />
                </Form.Item>

                <Form.Item>
                    <div className='form-item-choise'>
                        <div className='form-item-choise-title'>
                            <span>选项文字</span>
                            <span>正确答案</span>
                        </div>
                        <div className='form-item-choise-select'>
                            {
                                optionList?.map(item => {
                                    return (
                                        <div className='form-item-choise-select-item' key={item.key}>
                                            <div className='form-item-choise-select-item-input'>
                                                <Input showCount maxLength={100} size='small' value={item.value} allowClear onChange={(e) => { selectInput(item.key, e.target.value) }} status={item.value.length ? '' : 'error'} />
                                                {
                                                    item.value.length ? <></> : <span className='tip'>选项内容不可为空，请至少输入1个字</span>
                                                }
                                            </div>
                                            <div className='form-item-choise-select-item-cho' onClick={() => { choiceSelect(item.key) }}>
                                                {
                                                    item.select ? <div className='icon'><SvgIcon iconClass={'select'} fontSize='24px' className='hover' /></div> : <div className='que'></div>
                                                }
                                            </div>
                                            <div className='form-item-choise-select-item-del' onMouseEnter={() => { selectDelete(item.key, true) }} onMouseLeave={() => { selectDelete(item.key, false) }} onClick={() => { deleteSelect(item.key) }}>
                                                {
                                                    item.del ? <SvgIcon iconClass={item.hover ? 'deletee-hover' : 'deletee'} fontSize='20px' /> : <SvgIcon iconClass={'deleteno'} fontSize='20px' />
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className='form-item-choise-select-item'>
                                <div className='form-item-choise-select-item-input' onClick={selectAddOption}>
                                    <div className='form-item-choise-select-item-input-add'>
                                        <span>+添加选项</span>
                                    </div>
                                </div>

                                <div className='form-item-choise-select-item-cho' style={{ visibility: 'hidden' }}>
                                    <div></div>
                                </div>
                                <div className='form-item-choise-select-item-del' style={{ visibility: 'hidden' }}>

                                </div>
                            </div>
                        </div>
                    </div>
                </Form.Item>

                <div style={{ width: '260px' }}>
                    <Form.Item
                        label="按钮名称"
                        name={'button'}
                        rules={[
                            { required: true, min: 2, message: '按钮名称不可为空，请至少输入2个字' },
                        ]
                        }
                    >
                        <Input showCount maxLength={6} />
                    </Form.Item>
                </div>

                {
                    mark ? <div style={{ width: '260px' }} className='form-item-score'>
                        <Form.Item
                            label="题目分值"
                            name={'score'}
                            validateFirst={true}
                            rules={[
                                { required: true, message: '请输入题目分值' },
                                { validator: valifyNumber }
                            ]
                            }
                        >
                            <Input placeholder="请输入题目分值" />
                        </Form.Item>
                    </div> : <></>
                }

                {
                    category === 'text' ? <></> :
                        <div style={{ width: '260px' }}>
                            <Form.Item
                                label="触发时间"
                                name={'time'}
                                rules={[
                                    { required: true, message: '请输入触发时间' },
                                    // { validator: verifyTime}
                                ]
                                }
                            >
                                <TimePicker />
                            </Form.Item>
                        </div>
                }

                <div className='form-item-check'>
                    <div>
                        <Checkbox checked={requiredAnswer} onChange={(e) => { setRequiredAnswer(e.target.checked) }} disabled={mark}>
                            是否必答
                        </Checkbox>
                        {
                            requiredAnswer && (
                                <div className='form-item-check-tip'>
                                    <SvgIcon iconClass='zhushi' />
                                    <span>勾选必答后，学习者必须完成该选择题才可以进行下一步操作。</span>
                                </div>
                            )
                        }
                    </div>
                    {
                        !mark ? (
                            <div>
                                <Checkbox checked={requiredTrue} onChange={(e) => { setRequiredTrue(e.target.checked) }}>
                                    是否必须回答正确
                                </Checkbox>
                                {
                                    requiredTrue && (
                                        <div className='form-item-check-tip'>
                                            <SvgIcon iconClass='zhushi' />
                                            <span>勾选必须回答正确后，学习者必须回答正确该选择题才可以进行下一步操作。</span>
                                        </div>
                                    )
                                }
                            </div>
                        ) : <></>
                    }
                </div>

                {
                    canAddActivity ? <div className='form-item-tip'>
                        <div className='form-item-tip-btn' onClick={() => { setCanAddActivity(0) }}>
                            <SvgIcon iconClass={'add'} fontSize='16px' />
                            <span>添加提示</span>
                        </div>
                    </div> : <div className='form-item-tiptab'>
                        <TipsAbility ref={TipsAbilityRef} close={() => { setCanAddActivity(1) }} tips={tips} />
                    </div>
                }


                <Form.Item>
                    <div className='form-item-button'>
                        <Button className='form-item-button-can' onClick={() => { close?.() }}>取消</Button>
                        <Button className='form-item-button-en' type='primary' onClick={submit}>确定</Button>
                    </div>
                </Form.Item>

            </Form>
        </>
    )
}

export default QuesAndAns