import { Button, Checkbox, Form, Input, Modal, TimePicker } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import SvgIcon from '../../../components/SvgIcon'
import TipsAbility from './TipsAbility'
import { valifyEmpty, valifyNumber, verifyTime } from './verifyTools'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import VerifyOther from './VerifyOther'
import RichEditor from '../../../components/RichEditor'

const { TextArea } = Input

type VerifyProps = {
    verify?: Ver,
    category: 'text' | 'video',   //课程类型
    mark: boolean,  // 评分模式
    close: Function,
    finish?: (data: Ver) => void
}

// 校验
export interface Ver {
    id: string,   //唯一标识
    title: string, // 校验描述
    ansGuide: string //答题引导
    code: string,  // 校验指令
    buttonName: string, //按钮名称
    required: boolean,  // 是否必答
    requiredCorrect: boolean,  // 回答是否必须正确
    score: number, // 分数
    time: string,  // 触发时间
    codeResult: string, ///指令结果
}

const Verify: React.FC<VerifyProps> = (props) => {

    const [form] = Form.useForm()
    const TipsAbilityRef = useRef(null)  // 获取提示表单

    const { verify, category, mark, close, finish } = props

    const [guideOpen, setGuideOpen] = useState(false)

    // 内容描述
    const richEditor = useRef<any>(null)
    const [defaultTitle, setDefaultTitle] = useState<any>()

    // 内容描述
    const showEditor = useRef<any>(null)
    const [defaultAnsGuide, setDefaultAnsGuide] = useState<any>()

    // 1 校验表单数据
    // 是否校验
    const [isVerify, setIsVerify] = useState(false)
    // 校验是否正确
    const [verifyTrue, setVerifyTrue] = useState(false)

    // 2 提示表单数据
    // 2 提示表单

    // 表单数据初始化
    useEffect(() => {
        if (verify) {
            const { title, ansGuide, code, buttonName, score, time, required, requiredCorrect, codeResult } = verify
            console.log(verify)
            const datetime = time === '' ? dayjs('00:00:01', 'HH:mm:ss') : dayjs(time, 'HH:mm:ss')
            if (ansGuide) {
                setGuideOpen(true)
            }
            form.setFieldsValue({
                title,
                ansGuide,
                code,
                buttonName,
                score,
                time: datetime,
                codeResult
            })
            setDefaultTitle(title)
            setDefaultAnsGuide(ansGuide)
            setIsVerify(required)
            setVerifyTrue(requiredCorrect)

            return
        }
        form.setFieldsValue({
            title: '',
            code: '',
            buttonName: '校验',
            score: 10,
            time: dayjs('00:00:01', 'HH:mm:ss'),
            codeResult: 'true'
        })
    }, [verify])


    /**  当前可添加的活动
     *   0   任何辅助活动都不可以添加
     *   1   可以添加提示活动
     */
    const [canAddActivity, setCanAddActivity] = useState(false)

    // 确定
    const submit = () => {
        const title = richEditor?.current?.getHtml()
        const ansGuide = showEditor?.current?.getHtml()

        form.setFieldsValue({
            title,
            ansGuide
        })
        const el: any = TipsAbilityRef?.current
        el?.set()

        Promise.all([form.validateFields(), el && el?.validateFields(),]).then(res => {
            console.log('验证通过')
            console.log(res);
            let validateData = {
                code: '',
                buttonName: '',
                codeResult: '',
            }
            if (res[1]) {
                const { code, buttonName, codeResult, } = res[1]
                validateData = {
                    code: code ?? '', buttonName: buttonName ?? '', codeResult: codeResult ?? '',
                }
            }
            const { title, score, ansGuide, time } = res[0]
            const datetime = time?.format('HH:mm:ss') || ''
            const data: Ver = {
                id: verify?.id ?? '',
                title: title,
                code: validateData.code,
                buttonName: validateData.buttonName,
                required: mark ? true : isVerify,
                requiredCorrect: verifyTrue,
                score: Number(score) || 0,
                time: datetime,
                codeResult: validateData.codeResult,
                ansGuide: ansGuide === '<p><br></p>' ? '' : ansGuide,
            }
            console.log(res, data);

            finish?.(data)
            close?.()
        }).catch(err => {
            console.log('校验失败')
        })
    }

    // 必须校验选择的规则
    useEffect(() => {
        if (!isVerify && verifyTrue) {
            setVerifyTrue(false)
        }
    }, [isVerify])

    // 校验必须正确选择的规则
    useEffect(() => {
        if (verifyTrue) {
            setIsVerify(true)
        }
    }, [verifyTrue])



    // 监听是否是评分模式
    useEffect(() => {
        if (mark) {
            setIsVerify(true)
        }
    }, [mark])


    return (
        <>
            <div className='ylzcc-form-head'>
                <span>{verify ? '编辑' : '新增'}编码测验</span>
            </div>
            <Form
                name="basic"
                colon={false}
                validateTrigger='onBlur'
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    label="内容描述"
                    name={'title'}
                    rules={[
                        { required: true, message: '请输入内容描述' },
                        { min: 3, message: '请至少输入3个字' },
                        { validator: (_, value) => valifyEmpty(_, value, '请输入内容描述') }
                    ]
                    }
                >
                    <RichEditor placeholder='请输入本次编码测验的内容描述' ref={richEditor} defaultValue={defaultTitle} />
                </Form.Item>
                <Form.Item
                    label={<div className='form-item-help'>
                        答题引导
                        {guideOpen && <div className='form-item-help-text' onClick={() => {
                            setGuideOpen(false)
                        }}>
                            收起
                        </div>}
                    </div>}
                    name={'ansGuide'}
                    style={{ marginLeft: '7px' }}
                >
                    {
                        !guideOpen && <div className='demo-open' onClick={() => {
                            setGuideOpen(true)
                        }}>展开</div>
                    }
                    {guideOpen && <RichEditor ref={showEditor} defaultValue={defaultAnsGuide} />
                    }
                </Form.Item>
                {
                    mark ? <div style={{ width: '260px' }} className='form-item-score'>
                        <Form.Item
                            label="题目分值"
                            name={'score'}
                            validateFirst={true}
                            rules={[
                                { required: true, message: '请输入题目分值' },
                                { validator: valifyNumber }
                            ]
                            }
                        >
                            <Input placeholder="请输入题目分值" />
                        </Form.Item>
                    </div> : <></>
                }

                {
                    category === 'text' ? <></> :
                        <div style={{ width: '260px' }}>
                            <Form.Item
                                label="触发时间"
                                name={'time'}
                                rules={[
                                    { required: true, message: '请输入触发时间' },
                                    // { validator: verifyTime}
                                ]
                                }
                            >
                                <TimePicker />
                            </Form.Item>
                        </div>
                }

                <div className='form-item-check'>
                    {/* <Checkbox checked={isVerify} onChange={(e) => { setIsVerify(e.target.checked) }} disabled={mark}>
                        是否必须执行校验
                    </Checkbox>
                    {
                        !mark ? <Checkbox checked={verifyTrue} onChange={(e) => { setVerifyTrue(e.target.checked) }}>
                            是否必须校验正确
                        </Checkbox> : <></>
                    } */}
                    <div className='form-item-check-checkBox'>
                        <Checkbox checked={isVerify} onChange={(e) => { setIsVerify(e.target.checked) }} disabled={mark}>
                            是否校验
                        </Checkbox>
                        {isVerify && <div className='checkBox-text'>
                            <SvgIcon iconClass={'promptIcon'} fontSize='14px' />
                            <span style={{ fontSize: '12px', color: '#999', marginLeft: '2px' }}>开启校验后，会执行【校验交互指令】对学习者的操作进行校验。</span>
                        </div>}
                    </div>

                </div>

                {
                    !isVerify ? <></> : <div className='form-item-tiptab'>
                        <VerifyOther ref={TipsAbilityRef} verify={verify} verifyTrue={verifyTrue} close={() => { setCanAddActivity(false) }} changeTrue={(e) => {
                            setVerifyTrue(e)
                        }} />
                    </div>
                }

                <Form.Item>
                    <div className='form-item-button'>
                        <Button className='form-item-button-can' onClick={() => { close?.() }}>取消</Button>
                        <Button className='form-item-button-en' type='primary' onClick={submit}>确定</Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    )
}

export default Verify