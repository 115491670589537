// 课程类型
export const courseTypes = {
  COURSE: 'COURSE',    // 课程类型
  BLOG: 'BLOG',        // 博客
  EXPERIENCE: 'EXPERIENCE' //体验课程
}

export const courseTypesName: any = {
  COURSE: '课程',    // 课程类型
  BLOG: '博客',        // 博客
  EXPERIENCE: '内容' //体验课程
}

// 考试类型
export const Type = {
  PROJECT: 'PROJECT'   // 项目工程类实操题
}

// 项目工程类实操题的子类型
export const projectType = {
  EXAM: 'EXAM',          // 考试中
  PREVIEW: 'PREVIEW',    // 预览态
  MARK: 'MARK',          // 老师修改 打分
}

// 示例演示运行工具
export const RunToolsTypes = {
  SHELL: 'Shell',
  CODEPLAYER: 'CodePlayer'
}