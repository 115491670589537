import http from "../index";
import config from '../../config'

const _ptyApiURL = config.ptyApiURL
// 去除零宽空格字符
const ptyApiURL = _ptyApiURL?.replace(/\u200B/g, '')

const osApiURL = config.osApiURL

// 表单查询实验环境
export async function getExperEnv(params: any) {
  return http.get(`${ptyApiURL}/cmd/template/list`, {
    owner: params?.owner,
    lab: params?.lab
  })
}

// 模板查询
export async function getTemplate(params: any) {
  return http.get(`${ptyApiURL}/cmd/templates`, {
    owner: params?.owner,
    lab: params?.lab
  })
}

// 模板查询根据envId和code
export async function getTemplateById(params: any) {
  return http.get(`${ptyApiURL}/cmd/template/get`, {
    id: params?.id,
    lab: params?.lab
  })
}

// 模板自定义内容格式校验
export async function verifyTemplate(template: string) {
  return http.post(`${ptyApiURL}/cmd/template/verify`, { template })
}

// 自定义环境保存
export async function saveEnv(params: any) {
  return http.post(`${ptyApiURL}/cmd/template/save`, {
    owner: params?.owner,
    lab: params?.lab,
    name: params?.name,
    template: params?.template,
    create: params?.create,
    id: params?.id || undefined
  })
}


// 端口查询配置
export async function queryPort(params: any) {
  return http.get(`${ptyApiURL}/cmd/config/ports`, {
    label: params?.label
  })
}

// 端口配置
export async function setPort(params: any, options: any = {}) {
  return http.post(`${ptyApiURL}/cmd/config/ports`, params, options)
}

// 端口配置
export async function deletePort(params: any) {
  return http.post(`${ptyApiURL}/cmd/config/ports/delete`, params)
}

// 启动、重启环境
export async function start(params: any) {
  return http.post(`${ptyApiURL}/api/start`, {
    repo: params?.repo,
    template: params?.template,
    expires: params?.expires,
    volumes: params?.volumes,
    ports: params?.ports,
    lab: params?.lab,
    userId: params?.userId,
    path: params?.path,
    force: params?.force,
    modal: params?.modal
  })
}

// 释放环境
export async function release(params: any) {
  return http.post(`${ptyApiURL}/api/release`, {
    repo: params?.repo,
    template: params?.template
  })
}


// 新增接口--------------
// 获取容器模板
export async function getContainerTemplate() {
  return http.get(`${ptyApiURL}/cmd/visual/templates`)
}

// 镜像验证
export async function validateImage(data: any) {
  return http.post(`${ptyApiURL}/cmd/visual/verify/image`, data)
}

// 环境保存
export async function saveEnvironment(data: any) {
  // return http.post(`${ptyApiURL}/cmd/visual/save`, data)
  return http.post(`${osApiURL}/api/v0.3/templateEnv/visual/save`, data)
}