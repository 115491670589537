import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import './index.less'
import QuesAndAns, { Quesandans } from './QuesAndAns'
import Verify, { Ver } from './Verify'
import Experiment, { Exper } from './Experiment'


type ActivityProps = {
  params?: any
  activity: Activity,
  show: boolean,
  close: () => void,
  finish?: (data: Quesandans | Exper | Ver) => void
  template?: any        // 环境信息
}

export type Activity = {
  type: 'question' | 'experiment' | 'validate', // 问答  实验   校验
  category: 'text' | 'video',  // 文档型课程   视频型课程
  mark: boolean, // 是否是评分模式
  data?: Quesandans | Exper | Ver
}



const ActivityForm: React.FC<ActivityProps> = (props) => {

  const { activity, show, close, finish, params, template } = props

  if (!show) {
    return <></>
  }

  
  const showType = () => {
    switch (activity?.type) {
      case 'question':
        return <QuesAndAns quesandans={activity?.data as Quesandans} category={activity?.category} mark={activity?.mark} close={close} finish={finish} />
      case 'experiment':
        return <Experiment params={params} template={template} experiment={activity?.data as Exper} category={activity?.category} mark={activity?.mark} close={close} finish={finish} />
      case 'validate':
        return <div className='verify-comtent'><Verify verify={activity?.data as Ver} category={activity?.category} mark={activity?.mark} close={close} finish={finish} /></div>
      default:
        return <></>
    }
  }

  return (
    <>
      <Modal
        open={show}
        wrapClassName={'ylzcc-modal'}
        onCancel={() => { close?.() }}
        footer={false}
        centered={true}
        width={'1020px'}
        maskClosable={false}
        destroyOnClose={true}
      >
        {
          showType()
        }
      </Modal>
    </>
  )
}


export default ActivityForm
