/*
 * 新增编辑目录信息
 */
import React, { useEffect, useState } from 'react'

import './index.less'
import SvgIcon from '../../SvgIcon'



export type SubmitKnobProps = {
  borderShow: boolean
  knobList: any
  changeKnob: (v: any) => void
}

const SubmitKnob: React.FC<SubmitKnobProps> = (props) => {

  const [courseList, setCourseList] = useState(props.knobList)

  const changeSub = (index: number) => {
    const list = [...courseList]
    list[index].konbShow = !list[index].konbShow
    setCourseList(list)
    props.changeKnob(list)
  }


  useEffect(() => {
    setCourseList(props.knobList)
    console.log(props.knobList);
  }, [props.knobList])

  useEffect(() => {
    console.log(courseList);

  }, [courseList])

  return (
    <div className='knob' style={props.borderShow ? { border: `1px solid  #e5e5e5`, borderTop: 'none' } : {}}>
      {courseList?.map((item: any, index: number) => (
        <div>
          {!item.isEmpty && <div className='knob-item' key={index}>
            <div className='knob-item-left'>
              {
                item?.describe?.type ===
                  "video"
                  ? <SvgIcon iconClass='video' fontSize='20px' /> : <SvgIcon iconClass='text' fontSize='20px' />
              }
              <div className='knob-item-left-instroction'>{item.title}</div>
            </div>
            <div className='knob-item-right' onClick={() => {
              changeSub(index)
            }}>
              {
                !item?.isChanged ? <SvgIcon iconClass='noSelect' fontSize='24px' /> : item.konbShow ? <SvgIcon iconClass='allSelect' fontSize='24px' /> : <div className='knob-item-right-none'></div>
              }
            </div>
          </div>}
        </div>
      ))}

    </div>
  )
}

export default SubmitKnob