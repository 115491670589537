import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Tooltip, message, Input } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import SvgIcon from '../../SvgIcon'
import Loading from '../../Loading'
import SubmitModal from '../../SubmitFiles'
import './index.less'
import { commitCatalog, getGitPull, getSubmitCatalog } from '../../../api/modules/repo'
import { saveQuestion, updateCourse } from '../../../api/modules/community'
import { getQueryStringValue } from '../../../utils';
import config from '../../../config';
import { Terminal } from 'xterm';
import { useSelector } from 'react-redux';
import EnvStartup from '../EnvStartup'; //环境相关
import EnvStartupMark from '../EnvStartupMark' //工程项目类实操题（判分服务）
import { Type, courseTypes, projectType, courseTypesName } from '../../../utils/commonTypes'
import ProjectRun from '../ProjectRun';
import ProjectMark from '../ProjectMark';

const { confirm } = Modal;

type HeaderProps = {
  modal?: string | null
  isUpdate?: boolean
  isPreview?: string | null
  courseName?: string   // 课程标题
  reqParams?: any
  wsUrl?: string,
  examType?: string
  section?: any //当前节信息
  labCode?: any //实验室编码
  courseCode?: string //课程编码
  courseType?: any //课程类型
  validateUrl?: string
  examDetail?: any  //考试内容详情
  ifUpload: boolean
  containers?: any //启动环境：用于判断容器里是否存在实验环境启动组件
  onRunningPod: (value?: any, closeShell?: boolean) => void //当前小节运行的容器
  onStatus?: (status: any) => void               // 当前环境的状态
  onStep?: (step: number) => void //实验环境启动组件联动，step：代表操作步骤
  onCurrentPodInfo?: (value: any) => void //实验环境启动组件联动，value:当前环境详细信息
  onCourseNameChange?: (value: string) => void //课程标题修改成功
}

const Header: React.FC<HeaderProps> = (props) => {
  const { modal, examDetail, examType, isUpdate, isPreview, courseName, reqParams, wsUrl, section, validateUrl, ifUpload, labCode, courseCode, courseType, containers, onRunningPod, onStatus, onStep, onCurrentPodInfo, onCourseNameChange } = props

  // 社区token
  const token = getQueryStringValue('token')

  // 实操题进入的类型
  const examstatus = getQueryStringValue('examstatus')

  //考试编码
  const examRecordCode = getQueryStringValue('examRecordCode')

  //用户ID
  const userId = getQueryStringValue('userId')

  //题目编码
  const questionCode = getQueryStringValue('questionCode')

  // 相应考试类型的子类型  exam preview mark
  const _projectType = getQueryStringValue("projectType")?.toUpperCase()
  // type
  const type = getQueryStringValue("type")?.toUpperCase()

  // 学习区 更新加载框
  const [updateLoading, setUpdateLoading] = useState(false)

  // 提交弹框
  const [submitModal, setSubmitModal] = useState(false)

  // 提交弹框内容
  const [subMitValue, setSubMitValue] = useState<any>()

  //考试保存弹窗
  const [examModal, setExamModal] = useState(false)

  // 环境信息
  const [podInfo, setPodInfo] = useState<any>()

  const { RTSave } = useSelector((state: any) => state.course)

  //退出弹窗
  const exitConfirm = () => {
    confirm({
      title: '确认退出？',
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: '退出后，可以点击编辑重新进入工作台进行创作。',
      okText: '确认',
      centered: true,
      className: 'warn',
      width: 400,
      cancelText: '取消',
      onOk() {
        close()
      }
    });
  };

  //正在上传提示弹窗
  const exitUploadConfirm = () => {
    confirm({
      title: '温馨提示',
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: '文件正在上传中，请勿中途退出。',
      okText: '我知道了',
      centered: true,
      className: 'warn',
      width: 400,
      cancelText: '我要退出',
      onCancel: () => {
        close()
        if (modal === '0') {
          window.close()
        }
      }
    });
  };

  // 创作 => 退出
  const close = () => {
    window?.parent?.postMessage({ type: 'shutopen' }, '*')
  }

  // 创作 => 预览
  const onOpenPreview = () => {
    let href = window.location.href
    href = href.replace('modal=1', 'modal=0')
    window.open(href + '&preview=1')
  }

  // 学习 => 退出
  const onClose = () => {
    confirm({
      title: '确认退出？',
      content: '已为您保存本次编辑的内容，下次进入可直接打开',
      centered: true,
      onOk: () => {
        window.close()
      }
    })
  }

  // 学习 => 更新课程
  const onGitPull = () => {
    confirm({
      title: '确认更新？',
      content: '更新后，课程内容将会被线上最新内容覆盖。',
      centered: true,
      onOk: () => {
        fetchGitPull()
      }
    })
  }

  // 更新操作
  const fetchGitPull = () => {
    setUpdateLoading(true)
    getGitPull(reqParams).then(() => {
      setUpdateLoading(false)
      window.location.reload()
    }).catch(res => {
      setUpdateLoading(false)
      if (res?.message) {
        message.error(res?.message)
      }
    })
  }


  // 判断目录是否有小节
  const judegDir = (dir: any) => {
    const list: any = []
    dir.forEach((item: any) => {
      const type: 'stage' | 'chapter' | 'text' | 'video' | 'notebook' | '' = item?.describe?.type
      if (['text', 'notebook', 'video', ''].includes(type)) {
        list.push(item)
      } else {
        if (item?.children && item?.children?.length) {
          const children = judegDir(item?.children)
          if (children && children?.length) {
            item.children = children
            list.push(item)
          }
        }
      }
    })
    return list
  }

  //提交
  const getSubMitValue = () => {

    getSubmitCatalog(reqParams).then(res => {
      res = JSON.parse(JSON.stringify(res || []))
      if (res.length > 0) {
        if (courseType === courseTypes.COURSE) {
          res = judegDir(res)
          setSubMitValue(res)
          ifNovalue(res)
        } else {
          commitCatalog({
            ...reqParams,
            path: [res[0].path]
          }).then((res) => {
            successShowConfirm()
            // if (res?.success) {
            //   successShowConfirm()
            // } else {
            //   message.destroy()
            //   message.warning(res?.message ?? '提交失败')
            // }
          })
          // .catch((err) => {
          //   message.destroy()
          //   message.warning(err?.message ?? '提交失败')
          // })
        }
      } else {
        message.warning('未输入课程内容，无法提交~')
      }
    })

  }

  //提交成功弹窗
  const successShowConfirm = () => {
    confirm({
      title: '提交成功！',
      icon: <SvgIcon iconClass='success' fontSize='22px' />,
      content: `快去发布你的${courseTypesName[courseType]}吧！`,
      okText: '去发布',
      centered: true,
      className: 'success1',
      width: 400,
      cancelText: '继续编辑',
      onOk() {
        goPublish()
      },
      onCancel() {
      },
    });
  };

  //判断当前章节是否为空
  const ifNovalue = (submitValue: any) => {
    let ifSub = false
    submitValue.forEach((item: any) => {
      if (item?.children?.length > 0) {
        item?.children?.forEach((aaItem: any) => {

          if (aaItem?.children?.length > 0) {
            aaItem?.children?.forEach((aaaItem: any) => {
              if (aaaItem?.children?.length > 0) {
                aaaItem?.children?.forEach((aaaaItem: any) => {
                  if (!aaaaItem?.isEmpty) {
                    changeSubmitModal(true)
                    ifSub = true
                  }
                })
              } else {
                if (!aaaItem?.isEmpty) {
                  changeSubmitModal(true)
                  ifSub = true
                }
              }
            })
          } else {
            if (!aaItem?.isEmpty && aaItem?.describe?.type !== 'chapter') {
              changeSubmitModal(true)
              ifSub = true
            }
          }
        })
      } else {
        if (!item?.isEmpty && item?.describe?.type !== 'stage' && item?.describe?.type !== 'chapter') {
          console.log(item);

          changeSubmitModal(true)
          ifSub = true
        }
      }
    })
    if (!ifSub) {
      message.warning('暂无小节内容')
    }
  }

  const changeSubmitModal = (value: any) => {
    setSubmitModal(value)
    if (!value) {
      setSubMitValue(null)
    }
  }

  const goPublish = () => {
    if (ifUpload) {
      exitUploadConfirm()
      return
    }
    window?.parent?.postMessage({ type: 'publish' }, '*')
  }

  //考试模式保存
  const savaExam = () => {

    if (section?.template?.validate) {
      validateWebsocket?.send(section?.template?.validateScript + '\r')
    }
    setExamModal(true)

  }

  const addExamValue = () => {
    setExamModal(false)
    if (examType === '0' && type !== Type.PROJECT) {
      saveQuestion({ userId, questionCode, examRecordCode, answer: section?.template?.validate && ifSuccess ? section?.template?.validateResult : '' }).then(res => {
        window?.parent?.postMessage({ type: 'examUpdate', value: { questionCode, examRecordCode } }, '*')
      })
    } else {
      window?.parent?.postMessage({ type: 'examUpdate', value: { questionCode, examRecordCode } }, '*')
    }
  }

  // 定时器：30秒发送一次
  const [timer, setTimer] = useState<any>()

  const [websocket, setWebsocket] = useState<any>()

  // 需要重连
  const [reconnect, setReconnect] = useState(false)

  //ws是否连接成功
  const [connect, setConnect] = useState(false)

  //剩余时间
  const [examTime, setExamTime] = useState('')

  // 实操题ws
  const handleWS = () => {
    try {
      if (websocket) {
        websocket?.close()
      }
      const ws = new WebSocket(`${config.osWSURL}/api/exam?code=${examRecordCode}&token=${token}`);
      setWebsocket(ws)
      setReconnect(false)
      timer && clearInterval(timer)

      ws.onopen = function () {
        setReconnect(false)
        setConnect(true)
      }

      ws.onmessage = function (res: any) {
        const { data } = res
        try {
          const _data = JSON.parse(data)
          if (_data.expire === '00:00:-2') {
            setExamTime('00:00:00')
          } else {
            setExamTime(_data.expire)
          }


        } catch (error) {
        }
      }

      ws.onclose = function (res: any) {

        setConnect(false)
        timer && clearInterval(timer)
        if (res.code !== 1000 && res.code < 3000) {

          setReconnect(true)
        }

      }
    } catch (error) {

    }
  }

  //实操题、工程项目类实操题的时间
  useEffect(() => {
    if (_projectType === projectType.EXAM || examstatus === 'exam') {
      handleWS()
    }
  }, [])

  useEffect(() => {
    if (connect) {
      const _timer = setInterval(() => {
        if (websocket) {
          websocket?.send('ping')
        }
      }, 1000)
      setTimer(_timer)
    } else {
      timer && clearInterval(timer)
    }
  }, [connect])

  useEffect(() => {
    return () => {
      timer && clearInterval(timer)
    }
  }, [timer])

  useEffect(() => {
    if (reconnect) {
      handleWS()
    }
  }, [reconnect])

  //校验的ws
  const [validateWebsocket, setValidateWebsocket] = useState<any>()

  const [validateCom, setValidateCom] = useState(false)

  // 失败
  const [fail, setFail] = useState(false)

  const [ifSuccess, setIfSuccess] = useState<any>(false)//是否成功

  const validWs = () => {
    if (!section?.template?.validate) return
    if (!validateUrl) return
    if (modal === '1') return
    const terminal = new Terminal()
    const ws = new WebSocket(validateUrl)
    setValidateWebsocket(ws)
    let root = ''
    ws.onopen = function () {
      setValidateCom(false)
    }
    ws.onmessage = function (e: any) {
      let data = e.data

      if (typeof data === 'string') {
        terminal.write(data);
        if (!root) {
          root = data
          return
        }
        const _data = data.split('\r\n')
        if (_data.includes(section?.template?.validateResult)) {
          console.log('考试功能 => 校验成功');
          setIfSuccess(true)
        } else {
          setFail(true)
        }
      } else {
        console.error('格式错误');
      }
    }
    ws.onclose = function (res: any) {
      setValidateCom(true)
    }
  }


  useEffect(() => {
    if (validateUrl) {
      validWs()
    }
    return () => {
      if (validateWebsocket) {
        validateWebsocket?.close()
      }
    }
  }, [validateUrl])

  useEffect(() => {
    if (validateCom) {
      validWs()
    }
  }, [validateCom])

  useEffect(() => {
    console.log(ifSuccess);
    if (ifSuccess) {
      return
    }
    if (fail) {
      setIfSuccess(ifSuccess ?? false)
    }



  }, [ifSuccess, fail])


  useEffect(() => {
    //监听是否重新拉取课程
    const handleContainer = (even: any) => {
      if (even.data.type === 'reGitPull') {
        fetchGitPull()
      }
    }
    window.addEventListener('message', handleContainer)
    return () => {
      window.removeEventListener('message', handleContainer)
    }
  }, [reqParams])

  /*课程标题 start*/

  // 课程标题是否可编辑，默认不可编辑
  const [isEditName, setIsEditName] = useState(false)

  // 课程标题编辑输入框ref
  const inputRef = useRef<any>(null);

  // 课程标题输入框值
  const [inputValue, setInputValue] = useState<string>('')

  // 保存标题
  const onSaveCourseName = () => {
    if (!inputValue) {
      message.warning(`请输入${courseTypesName[courseType]}标题!`)
      return
    }
    const patrn = new RegExp("^[a-zA-Z0-9 \\u4e00-\\u9fa5\\_\\-]+$")
    if (inputValue?.length < 3 || !patrn?.test(inputValue)) {
      message.warning('请输入3-50字的中文、英文、数字')
      return
    }
    // 更新接口
    updateCourse({
      code: courseCode,
      name: inputValue
    }).then(() => {
      setIsEditName(false)
      onCourseNameChange?.(inputValue)
    }).catch((res) => {
      message.error(res?.message || '请求出错')
    })
  }

  // 聚焦课程标题输入框
  useEffect(() => {
    if (isEditName) {
      inputRef?.current?.focus({
        cursor: 'end'
      });
    }
  }, [isEditName])

  // 标题
  useEffect(() => {
    setInputValue(courseName === '未命名' ? '' : courseName || '')
  }, [courseName])

  /*课程标题 end*/

  return (
    <>
      <div className='layout-opera'>
        {
          examType === '0' ?
            <div className='layout-opera-l'>
              {
                examType === '0' && <span className='layout-opera-l-type'>【考试】</span>
              }
              <span className='layout-opera-l-name'>
                <Tooltip key='courseName' placement="bottomLeft" arrow={false} title={courseName} overlayClassName='item-tooltip'>
                  <span className='layout-opera-l-name-text'>{courseName}</span>
                </Tooltip>
              </span>
              {
                (_projectType === projectType.EXAM || examstatus === 'exam') && (
                  <div className='layout-opera-l-time'>
                    <span className='layout-opera-l-time-left'>考试倒计时：</span>
                    <span className='layout-opera-l-time-rigth'>{examTime}</span>
                  </div>
                )
              }
            </div> :
            <div className='layout-opera-l'>
              {
                courseType && (
                  <span className='layout-opera-l-type'>【{modal === '1' ? '创作' : '学习'}-{courseTypesName[courseType]}】</span>
                )
              }

              {
                isEditName ? (
                  <span className='layout-opera-l-name-edit'>
                    <Input defaultValue={courseName === '未命名' ? '' : courseName} placeholder={`请输入${courseTypesName[courseType]}标题`} ref={inputRef} showCount maxLength={50} onChange={(e: any) => setInputValue(e?.target?.value)} />
                    <Tooltip key='cancel' placement="bottomLeft" arrow={false} title={'取消'} overlayClassName='item-tooltip'>
                      <>
                        <SvgIcon iconClass="cancel" fontSize='16px' onClick={() => setIsEditName(false)} />
                      </>
                    </Tooltip>
                    <Tooltip key='save' placement="bottomLeft" arrow={false} title={'保存'} overlayClassName='item-tooltip'>
                      <>
                        <SvgIcon iconClass="sure" fontSize='16px' onClick={onSaveCourseName} />
                      </>
                    </Tooltip>
                  </span>
                ) : (
                  <span className='layout-opera-l-name'>
                    <Tooltip key='courseName' placement="bottomLeft" arrow={false} title={courseName} overlayClassName='item-tooltip-title' style={{
                      textAlign: 'center'
                    }}>
                      <span className='layout-opera-l-name-text'>{courseName}</span>
                    </Tooltip>
                    {
                      modal === '1' ? (
                        <Tooltip key='edit' placement="bottomLeft" arrow={false} title={'编辑'} overlayClassName='item-tooltip'>
                          <>
                            <SvgIcon iconClass="edit" fontSize='16px' onClick={() => {
                              setIsEditName(true)
                            }} />
                          </>
                        </Tooltip>
                      ) : <></>
                    }
                  </span>
                )
              }

              {
                RTSave ? (
                  <div className='layout-opera-l-save'>
                    <SvgIcon iconClass="save" fontSize='16px' />
                    <span>已实时保存</span>
                  </div>
                ) : <></>
              }
              {
                modal !== '1' && isUpdate ? (
                  <div className='layout-opera-l-isUpdated'>
                    <SvgIcon iconClass="warning" fontSize='16px' />
                    <span onClick={onGitPull}>课程有新内容，点击更新</span>
                  </div>
                ) : <></>
              }
            </div>
        }
        <div className='layout-opera-r'>
          {
            examType === '0' && projectType.MARK === _projectType && examDetail?.userAnswer && podInfo?.labels?.appId && <ProjectMark podAppId={podInfo?.labels?.appId} />
          }

          {
            examType === '0' && examDetail?.userAnswer && <ProjectRun examType={examType} userAnser={examDetail?.userAnswer} section={section} examDetail={examDetail}></ProjectRun>
          }

          {
            (examType === '0' && projectType.MARK === _projectType ? (
              <EnvStartupMark examType={examType} section={section} labCode={labCode} modal={modal} containers={containers} onRunningPod={(value?: any, closeShell?: boolean) => {
                setPodInfo(value)
                onRunningPod(value, closeShell)
              }} onStatus={onStatus} onStep={onStep} onCurrentPodInfo={onCurrentPodInfo} />
            ) : (
              <EnvStartup examType={examType} section={section} labCode={labCode} modal={modal} containers={containers} onRunningPod={onRunningPod} onStatus={onStatus} onStep={onStep} onCurrentPodInfo={onCurrentPodInfo} />
            ))
          }
          {
            examType !== '0' && modal === '1' ? (
              <>
                <Button type="primary" className='exitBtn' onClick={() => {
                  if (window.parent === window) {
                    window.close()
                  }
                  if (ifUpload) {
                    exitUploadConfirm()
                  } else {
                    exitConfirm()
                  }
                }}>保存并退出</Button>
                <Button type="primary" ghost onClick={onOpenPreview}>预览</Button>
                <Button type="primary" onClick={getSubMitValue}>提交</Button>
              </>
            ) : <></>
          }
          {
            examType !== '0' && modal !== '1' ? isPreview ? (
              <Button type="primary" onClick={() => {
                window.close()
              }}>退出预览</Button>
            ) : (
              <Button type="primary" onClick={() => {
                if (ifUpload) {
                  exitUploadConfirm()
                } else {
                  onClose()
                }
              }}>保存并退出</Button>
            ) : <></>
          }
          {examType === '0' &&
            <>
              {
                type === Type.PROJECT ? <>
                  {examstatus !== 'mark' && examstatus !== 'view' && <Button type="primary" onClick={() => {
                    if (modal === '0') {
                      if (_projectType === projectType.PREVIEW) {
                        window.close()
                        setExamModal(true)
                      } else {
                        savaExam()
                      }
                    } else {
                      window.close()
                    }

                  }}>保存并退出</Button>}
                </> : <>
                  {modal === '0' && <div className='invaite-close' onClick={() => {
                    window?.parent?.postMessage({ type: 'examExit', value: { questionCode, examRecordCode } }, '*')
                  }}>
                    退出
                  </div>}
                  {examstatus !== 'mark' && examstatus !== 'view' && <Button type="primary" onClick={() => {

                    if (modal === '0') {
                      savaExam()
                    } else {
                      window.close()
                    }
                  }}>保存</Button>}
                </>
              }
              {/* {modal === '0' && <div className='invaite-close' onClick={() => {
                window?.parent?.postMessage({ type: 'examExit', value: { questionCode, examRecordCode } }, '*')
              }}>
                退出
              </div>} */}
            </>
          }
        </div>
      </div >

      <SubmitModal reqParams={reqParams} submitValueList={subMitValue} submitShow={submitModal} coursePublish={() => {
        goPublish()
      }} onshow={(v: any) => {
        changeSubmitModal(v)
      }}></SubmitModal>

      {
        updateLoading ? <Loading data="更新中..."></Loading> : <></>
      }
      <Modal wrapClassName={'timeOver'} open={examModal} footer={''} width={'414px'} style={{ top: "40%" }}>
        <div>
          <div className='timeOver-top'>
            <ExclamationCircleFilled style={{ color: '#FA8C16', fontSize: '22px' }} />
            <span className='timeOver-text'>确认保存？</span>
          </div>
          <div className='timeOver-text1'>已为您保存本次编辑的内容，可在重新进入修改！</div>
          <div className='timeOver-bottom'>
            <div className='timeOver-btnleft' onClick={() => {
              setExamModal(false)
            }}>取消</div>
            <div className='timeOver-btn' onClick={() => {
              addExamValue()
            }}>确定</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Header;
