import React, { useContext, useEffect, useState } from 'react'
import CodeEditor from '../../../components/CodeEditor'
import SharedDataContext from '../../../utils/share';
import ResourceManage from '../../../components/Layout/ResourceManage';
import PDFReader from '../../../components/PDFReader';
import { getFileSuffix, getQueryStringValue } from '../../../utils/index'
import config from '../../../config';

const DemoCode: React.FC = () => {

  // 获取共享数据
  const value = useContext(SharedDataContext);
  // 仓库
  const repo = getQueryStringValue('repo')

  // codeeditor的ws
  const [wsUrl, setWsUrl] = useState<any>()
  // language
  const [language, setLanguage] = useState<any>()
  // pdf阅读器
  const [pdfUrl, setPDFUrl] = useState('')

  // 打开文件
  const openFile = (e: any) => {
    // pdf
    if (e?.path?.endsWith('.pdf')) {
      setWsUrl('')
      const url = `${config.baseURL}/fs/content?repo=${repo}&path=${encodeURIComponent(value?.selectedChapter?.path)}&workdir=true&file=${encodeURIComponent(e?.path)}`
      setPDFUrl(url)
    } else {
      setPDFUrl('')
      const url = value?.chapterUrl?.replace('component=fs', 'component=file') + `&file=${encodeURIComponent(e?.path)}`
      setWsUrl(url)
      setLanguage(getFileSuffix(e?.title))
    }
  }

  useEffect(() => {
  }, [])

  return (
    <div className='experiment-democode'>
      <div className='experiment-democode-l'>
        <ResourceManage type={'active'} params={value?.params} chapterUrl={value?.chapterUrl} modal={'1'} changeGit={e => {
          value?.setCloneSuccess(e)
        }} selectedChapter={value?.selectedChapter} setSelectedFile={openFile} setFileInfo={e => {
          value?.setFileInfo(e)
        }} removeFile={e => {
          // 删除文件，删除对应的展示组件
          const path = `file=${encodeURIComponent(e?.path)}`
          if (wsUrl?.indexOf(path) > -1) {
            setWsUrl('')
          } else if (pdfUrl?.indexOf(path) > -1) {
            setPDFUrl('')
          }
        }}></ResourceManage>
      </div>
      <div className='experiment-democode-r'>
        {
          wsUrl ? <CodeEditor wsUrl={wsUrl} language={language} /> : pdfUrl ? <PDFReader url={pdfUrl} /> : <div className='experiment-democode-r-empty'>点击左侧内容打开编辑器</div>
        }
      </div>
    </div>
  )
}


export default DemoCode
