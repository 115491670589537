import React, { useContext, useEffect, useState } from 'react'
import { Button, message, Modal } from 'antd'
import CryptoJS from 'crypto-js'
import './index.less'
import SvgIcon from '../../SvgIcon'
import EnvCloud from '../EnvCloud'
import Loading from '../../Loading'
import config from '../../../config'
import { getQueryStringValue, formatTime } from '../../../utils'
import {start, release, getTemplateById} from '../../../api/modules/cli'
import SharedDataContext from '../../../utils/share'

// 前端的环境状态
enum EnvStatus {
  Startuping,//启动中
  Running,   //运行中
  Closing,   //关闭中
  Resetting  //重置中
}

type EnvStartupProps = {
  modal?: string | null
  examType: any //考试的实操题不展示倒计时
  labCode: string //实验室编码
  section: any //当前小节信息
  containers?: any //启动环境：用于判断容器里是否存在实验环境启动组件
  onRunningPod: (value?: any, closeShell?: boolean) => void //当前小节运行的容器
  onStatus?: (status: any) => void             // 当前环境状态
  onStep?: (step: number) => void //实验环境启动组件联动，step：代表操作步骤
  onCurrentPodInfo?: (value: any) => void //实验环境启动组件联动，value:当前环境详细信息
  [other: string]: any
}

const EnvStartup: React.FC<EnvStartupProps> = (props) => {

  const { modal, examType, labCode, section, containers = [], onRunningPod, onStatus, onStep, onCurrentPodInfo } = props

  // 获取共享数据   courseInfo  课程的目录结构
  const { courseInfo } = useContext(SharedDataContext)


  //Pod.yaml路径
  const location = getQueryStringValue("location")


  // 用户
  const userId = getQueryStringValue('userId')

  // 仓库
  const repo = getQueryStringValue('repo')

  // 环境监控
  const [websocket, setWebsocket] = useState<any>()

  // 是否需要重连
  const [reconnect, setReconnect] = useState(false)

  // 心跳定时器
  const [heartTimer, setHeartTimer] = useState<any>()

  // 环境监控数据
  const [allData, setAllData] = useState<any>([])
  // websocket当前的信息是否返回
  const [messageReturn, setMessageReturn] = useState(false)

  // 当前小节环境数据
  const [data, setData] = useState<any>()

  // 环境状态
  const [status, setStatus] = useState<any>('')

  // 重置状态 1:开始重置 2：重置开始释放旧环境
  const [resetStatus, setResetStatus] = useState<any>('')

  // 显示CPU和内存信息
  const [showInfo, setShowInfo] = useState(false)

  // 启动环境接口loading
  const [loading, setLoading] = useState(false)

  // 启动中，超过5s更换loading提示语
  const [overtime, setOvertime] = useState(false)

  // 超时定时器
  const [overtimeTimer, setOvertimeTimer] = useState<any>()

  // 是否调用“启动环境”方法
  const [startAction, setStartAction] = useState(false)

  // 是否调用“重新启动环境”方法
  const [restartAction, setRestartAction] = useState(false)

  // 是否调用“关闭环境”方法
  const [closeAction, setCloseAction] = useState(false)

  // 记录当前使用的section
  const [currentSection, setCurrentSection] = useState<any>()

  // 是否延用环境
  const [isContinue, setIsContinue] = useState(false)

  // 连续收到空数据的次数
  const [emptyTime, setEmptyTime] = useState(0)

  /**/

  // 连接返回的剩余时长
  const [expires, setExpires] = useState(0)

  // 剩余时长
  const [time, setTime] = useState(0)

  // 剩余时长timer
  const [timer, setTimer] = useState<any>()

  // 显示倒计时说明
  const [showExplain, setShowExplain] = useState(false)

  // 打开shell工具或端口工具
  const onOpenShell = (data:any) => {
    const _timer = setTimeout( async () => {
      if (section?.template?.defaultOpen && section?.template?.ports?.some((item: any) => item?.choice && item?.port === section?.template?.defaultOpen)) {
        window.postMessage({
          type: 'pushContainer',
          component: 'Webview',
          value: {
            path: 'https://' + data?.ports?.find((item: any) => item.targetPort === section?.template?.defaultOpen)?.host ?? '',
            name: section?.template?.defaultOpenName ?? '网页',
            pod: data
          }
        })
      } else {
        let defaultShell=""

        try {
          if (section?.template?.defaultShell){
            defaultShell=section?.template?.defaultShell
          }else {
            const res= await getTemplateById({id:data.labels.templateId,lab:data.labels.lab})
            defaultShell=res?.defaultShell;
          }
        }catch (e){

        }
        window.postMessage({
          type: 'pushContainer',
          component: 'Xterm',
          value: {
            defaultShell:defaultShell,
            from: 'startup'
          }
        })
      }
      clearTimeout(_timer)
    }, 1);
  }

  // 查找模板是否正在Running
  const isTemplateRunning = (template: string) => {
    const _template = CryptoJS.MD5(template).toString()
    // 过滤正在释放的环境
    const _dataArr = allData?.filter((v: any) => v?.template === _template && v?.status === 'Running' && v?.containers?.length && v?.expires > 0)
    if (_dataArr?.length) {
      return true
    }
    return false
  }

  // 查找模板是否启动失败
  const isTemplateFail = (template: string) => {
    const _template = CryptoJS.MD5(template).toString()
    // 过滤正在释放的环境
    const _dataArr = allData?.filter((v: any) => v?.template === _template && v?.success === false && v?.containers?.length && v?.expires > 0)
    if (_dataArr?.length) {
      return _dataArr[0]
    }
    return false
  }

  // 延用提醒
  const onContinue = () => {
    Modal.confirm({
      title: "温馨提示",
      content: '您上一次的资源还未释放，将为您延用上一次的环境。',
      okText: "直接进入",
      cancelText: "取消",
      centered: true,
      width: 400,
      onOk(close) {
        setIsContinue(true)
        setStatus(EnvStatus.Running)
        close()
      }
    })
  }

  // 启动失败原因说明
  const onFail = (value: any) => {
    // 判断容器是否存在实验环境组件，若有 => 不弹框显示失败原因
    const arr = containers?.filter((v: any) => {
      const arr = v?.components?.filter((v: any) => v?.id === '_EnvStart_')
      return !!arr?.length
    })
    if (!arr?.length) {
      Modal.confirm({
        title: "实验环境启动失败，请重新尝试！",
        content: value?.reasonMessage || '',
        okText: "重新启动",
        cancelText: "我知道了",
        centered: true,
        width: 400,
        onOk(close) {
          onRestart()
          close()
        }
      })
    }
  }

  // 重新启动环境
  const onRestart = () => {
    // 实验环境启动组件：环境拉起中
    onStep?.(2)
    setLoading(true)
    fetchStart(false, true)
    setStatus('')
  }

  /*
   * 启动环境
   * 判断该模板是否有旧环境 => 直接延用
   * 判断该模板是否有失败的环境 => 失败原因弹框说明
   */
  const onStartupEnv = () => {

    if (isTemplateRunning(location?? section?.template?.experEnv)) {
      onContinue()
      return
    }
    const failEnv = isTemplateFail(location?? section?.template?.experEnv)
    if (failEnv) {
      // 实验环境启动组件：环境启动失败
      onStep?.(4)
      onFail(failEnv)
      return
    }
    // 实验环境启动组件：环境拉起中
    onStep?.(2)
    // 开始启动环境
    setLoading(true)
    fetchStart()
  }

  // 请求接口: 启动环境 isReset: 重置环境  restart: 重新启动
  const fetchStart = (isReset?: boolean, restart?: boolean) => {
    start({
      repo,
      template: location?? section?.template?.experEnv,
      expires: section?.template?.duration,
      volumes: courseInfo?.filter((v: any) => v?.describe?.category === 'content')?.map((v: any) => v?.path),
      ports: section?.template?.ports,
      lab: labCode,
      userId,
      path: section?.path,
      force: isReset || restart ? true : false,
      modal: modal
    }).then(res => {
      const { success, code } = res
      if (success === false) {
        if (code === 'exist') {
          let data
          if (res?.data?.length) {
            data = res?.data[0]
          }
          if (data?.status === 'Running') {
            onContinue()
          } else if (data?.status === 'Terminating') {
            message.destroy()
            message.error('环境关闭中，请稍后...')
          } else {
            message.destroy()
            message.error(res?.message)
          }
        } else {
          message.destroy()
          message.error(res?.message)
        }
      } else {
        if (isReset) {
          // 设置重置状态：重置开始释放旧环境
          setResetStatus(2)
        } else {
          setStatus(EnvStatus.Startuping)
        }
      }
      setLoading(false)
      // 实验环境启动组件：环境初始化中
      onStep?.(3)
    }).catch(res => {
      setLoading(false)
      message.destroy()
      message.error(res?.message)
      onStep?.(3)
    })
  }

  // 关闭环境
  const onClose = () => {
    Modal.confirm({
      title: "确认关闭环境？",
      content: '关闭环境后，资源将会被回收。',
      centered: true,
      width: 400,
      onOk(close) {
        // 停止心跳
        heartTimer && clearInterval(heartTimer)
        // 设置环境状态为 关闭中
        setStatus(EnvStatus?.Closing)
        close()
        fetchRelease()
      },
      onCancel(close) {
        close()
      }
    })
  }

  // 重置环境
  const onReset = () => {
    Modal.confirm({
      title: "确认重置环境？",
      content: '确认重置环境后，将会为您销毁旧环境，重启一个新环境。',
      okText: "确定",
      cancelText: "取消",
      centered: true,
      width: 400,
      onOk(close) {
        // 停止心跳
        heartTimer && clearInterval(heartTimer)
        // 设置环境状态为 重置中
        setStatus(EnvStatus?.Resetting)
        // 设置重置状态：开始重置
        setResetStatus(1)
        // 调用重新启动接口
        fetchStart(true)
        close()
      },
      onCancel(close) {
        close()
      }
    })
  }

  // 释放环境
  const fetchRelease = () => {
    release({
      repo,
      template: section?.template?.experEnv
    }).then(res => {
      const { success } = res
      if (success === false) {
        message.destroy()
        message.error(res?.message)
      } else {
        setStatus('')
        message.destroy()
        message.success('环境关闭成功')
        onRunningPod({}, true)
      }
    }).catch(res => {
      message.destroy()
      message.error(res?.message)
    })
  }

  /* 时间控制 start */

  // 剩余时长的颜色 (10分钟)
  const currentColor = (time: number) => {
    if (time >= 600) {
      return "#30C213"
    } else {
      return "#F5222D"
    }
  }

  // 倒计时10分钟提示
  const onWarnPrompt = () => {
    Modal.destroyAll()
    Modal.warning({
      title: "温馨提示",
      content: '环境使用时长仅剩10分钟，10分钟后将自动关闭且回收资源。',
      okText: "我知道了",
      centered: true,
      width: 400
    })
  }

  // 倒计时结束
  const onTimeOut = () => {
    Modal.destroyAll()
    let _timer: any
    const instance = Modal.warning({
      title: "温馨提示",
      content: '环境使用结束，正在为您关闭环境。',
      okText: `我知道了（5s)`,
      centered: true,
      width: 400,
      onOk(close) {
        _timer && clearInterval(_timer)
        close()
        if (status === EnvStatus.Running) {
          setStatus(EnvStatus.Closing)
        }
      }
    })
    // 5s倒计时
    let time = 5
    _timer = setInterval(() => {
      time = time - 1
      instance.update({
        okText: `我知道了（${time}s)`,
      });
      if (time === 0) {
        clearInterval(_timer)
        instance.destroy()
        if (status === EnvStatus.Running) {
          setStatus(EnvStatus.Closing)
        }
      }
    }, 1000)
  }

  // 倒计时
  useEffect(() => {
    // 考试实操题 => 无倒计时
    // if (examType === '0') return
    timer && clearInterval(timer)
    if (status !== EnvStatus.Running) return
    setTime(expires)
    if (expires === 0) return
    if (expires === 600) {
      onWarnPrompt()
    }
    let _time = expires
    const _timer = setInterval(() => {
      _time = _time - 1
      if (_time === 600) {
        onWarnPrompt()
      }
      if (_time === 0) {
        onTimeOut()
        clearInterval(_timer)
      }
      setTime(_time)
    }, 1000)
    setTimer(_timer)
    return () => {
      timer && clearInterval(timer)
    }
  }, [expires, status])

  /* 时间控制 end */

  // 环境启动中是否超过5s
  useEffect(() => {
    if (status === EnvStatus.Startuping) {
      const timer = setTimeout(() => {
        setOvertime(true)
        clearTimeout(timer)
      }, 5000)
      setOvertimeTimer(timer)
    } else {
      overtimeTimer && clearTimeout(overtimeTimer)
      setOvertime(false)
    }
    onStatus?.(status)
  }, [status])

  // 环境监控的websocket
  const handleWS = () => {
    try {
      if (websocket && websocket?.readyState === 1) {
        return
      }
      const ws = new WebSocket(`${config?.ptyWSURL}/api/monitor?repo=${repo}`);
      setReconnect(false)
      // 连接成功
      ws.onopen = function () {
        setWebsocket(ws)
      }
      // 接收消息
      ws.onmessage = function (res: any) {
        const { data } = res
        const _data = JSON.parse(data)
        setAllData(_data)
        setMessageReturn(true)
      }
      // 连接关闭
      ws.onclose = function (res: any) {
        const code = res.code
        if (code < 3000) {
          setReconnect(true)
        } else {
          // 错误提示
        }
      }
    } catch (error) {

    }
  }

  // 心跳检测，心跳5s一次
  useEffect(() => {
    heartTimer && clearInterval(heartTimer)
    if (websocket?.readyState === 1) {
      const timer = setInterval(() => {
        if (data?.pod) {
          websocket?.send(data?.pod)
        }
      }, 5000)
      setHeartTimer(timer)
    }
  }, [websocket?.readyState, data?.pod])

  // 重新连接环境监控的websocket，1s重连一次
  useEffect(() => {
    if (reconnect) {
      const _timer = setTimeout(() => {
        handleWS()
        clearTimeout(_timer)
      }, 1000);
    }
  }, [reconnect])

  // 等待连接信息返回再启动的任务
  const [awaitStartTask, setAwaitStartTask] = useState<null | Function>(null)

  // 监听wesocket的信息是否返回，然后执行启动的逻辑
  useEffect(() => {
    if (messageReturn && awaitStartTask) {
      awaitStartTask?.()
      setAwaitStartTask(null)
    }
  }, [messageReturn, awaitStartTask])

  // 课程环境总控
  useEffect(() => {
    // 连接socket
    handleWS()
    // 监听启动环境事件（执行、实验）
    const handleMessage = (e: any) => {
      const data = e?.data
      /**
       * 增加一个参数判断
       * waitTempRunInfo: 是否等待websocket连接信息返回再启动
       * value: {
       *    waitTempRunInfo: true
       * }
       */
      const value = data?.value
      if (data?.type === 'startupEnv') {
        if (value?.waitTempRunInfo) {
          setAwaitStartTask(() => () => {
            window.postMessage({
                type: 'startupEnv'
            })
          })
        } else {
          setStartAction(true)
        }
      } else if (data.type === 'closeEnv') {
        setCloseAction(true)
      } else if (data.type === 'restartEnv') {
        setRestartAction(true)
      }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
      if (websocket?.readyState === 1) {
        websocket?.close(4999)
      }
    }
  }, [])

  // 调用启动环境方法
  useEffect(() => {
    if (startAction && status === '') {
      onStartupEnv()
      setStartAction(false)
    }
  }, [startAction])

  // 调用关闭环境方法
  useEffect(() => {
    if (closeAction) {
      // 停止心跳
      heartTimer && clearInterval(heartTimer)
      // 设置环境状态为 关闭中
      setStatus(EnvStatus?.Closing)
      fetchRelease()
      setCloseAction(false)
    }
  }, [closeAction])

  // 调用重新启动环境方法
  useEffect(() => {
    if (restartAction) {
      onRestart()
      setRestartAction(false)
    }
  }, [restartAction])

  // 当前小节的环境信息
  useEffect(() => {
    const fetchTemplate= async () => {
      const template = section?.template?.experEnv
      if (template) {
        const _template = CryptoJS.MD5(template).toString()
        const _dataArr = allData?.filter((v: any) => v?.template === _template)
        // 过滤正在释放的环境
        const filterArr = _dataArr?.filter((v: any) => v?.status !== 'Terminating')
        if (filterArr?.length) {
          setEmptyTime(0)
          const _data = filterArr[0]
          setData(_data)
          onCurrentPodInfo?.(_data)
          const _expires = _data?.expires
          setExpires(_expires > 0 ? _expires : 0)
          if (_data?.success && _data?.status === 'Running' && _data?.containers?.length) {
            // 运行状态且有容器信息 => 按钮状态变更
            if (status !== '' && (status !== EnvStatus.Resetting || resetStatus === 2) && status !== EnvStatus.Closing) {
              onRunningPod(_data)
              setStatus(EnvStatus.Running)
              if (status === EnvStatus.Startuping) {
                message.destroy()
                message.success('环境启动成功')
                // 打开shell工具
                if (  (template.startsWith("default") ? false :(section?.template?.openTools !==false && section?.template?.defaultOpen !== -1)) || section?.template?.defaultOpen>=0  || (section?.template?.defaultOpen===undefined && template.startsWith("default") )){
                  onOpenShell(_data)
                }
              }

              // 直接进入环境
              if (isContinue) {
                if ((template.startsWith("default") ? false : (section?.template?.openTools !==false && section?.template?.defaultOpen !== -1)) || section?.template?.defaultOpen>=0 || (section?.template?.defaultOpen===undefined && template.startsWith("default") )){
                  onOpenShell(_data)
                }
                setIsContinue(false)
              }

              // 清空重置状态
              if (resetStatus === 2) {
                setResetStatus('')
              }
            }
          }
          if (!_data?.success && _data?.reasonMessage && status === EnvStatus.Startuping) {
            setStatus('')
            onFail(_data)
          }
        } else {
          onCurrentPodInfo?.({})
          if (_dataArr?.length) {
            // 资源正在释放中
            onRunningPod({}, true)
          } else {
            if (status === EnvStatus.Running && time !== 0 && emptyTime < 2) {
              // 正在运行中的环境，在前端没主动关闭、重置的情况下，连续收到两次空数据才认为断开
              const _emptyTime = emptyTime + 1
              setEmptyTime(_emptyTime)
              return
            }
            setEmptyTime(0)
            setData({})
            setTime(0)
            setExpires(0)
            timer && clearInterval(timer)
            status !== EnvStatus.Startuping && setStatus('')
            if (status === EnvStatus.Closing) {
              message.destroy()
              message.success('环境关闭成功')
              onRunningPod({}, true)
            } else {
              onRunningPod({})
            }
          }
        }
      }
    }
    fetchTemplate()
  }, [section?.template?.experEnv, allData])

  // 小节切换时，模板不同时，显示 启动环境 按钮
  useEffect(() => {
    // 切换小节/没模板，回调空数据
    onRunningPod({})
    setStatus('')
    setTime(0)
    onStep?.(1)
  }, [section?.template?.experEnv])

  // 切换相同环境的两个小节，环境延用，toast提示
  useEffect(() => {
    message.destroy()
    setCurrentSection(section)
    if (currentSection?.path !== section?.path && currentSection?.template?.experEnv === section?.template?.experEnv && isTemplateRunning(section?.template?.experEnv) && status === EnvStatus.Running) {
      message.info('检测到相同环境设置，已为您延用上次的环境资源。')
    }
  }, [section?.path])

  return (
    <>
      {
        section?.template?.experEnv ? (
          <>
            {
              time > 0 && status === EnvStatus.Running && (
                <div className='env-time'>
                  <span style={{ color: currentColor(time) }}>{formatTime(time)}</span>
                  <SvgIcon iconClass='help' onClick={() => setShowExplain(true)} />
                  <Modal
                    open={showExplain}
                    wrapClassName={'ylzcc-modal-help'}
                    onCancel={() => { setShowExplain(false) }}
                    footer={false}
                    centered={true}
                    width={560}
                    destroyOnClose={true}
                  >
                    <div className='explain'>
                      <div>环境时长说明</div>
                      <p><span>1.&nbsp;</span><span>每节课程的环境都由创作者设置了使用时长，剩余时长为启动环境后，当前环境剩余的学习使用时长。</span></p>
                      <p><span>2.&nbsp;</span><span>每次成功启动环境后，从创作者设置的使用总时长开始倒计时。</span></p>
                      <p><span>3.&nbsp;</span><span>若环境发生重连，计时仍继续，不会重新开始计时。</span></p>
                      <p><span>4.&nbsp;</span><span>切换小节或点击右上角【退出】，平台会关闭并回收环境；但若当前小节/课程有人正在学习，则环境资源不会被回收。</span></p>
                      <p><span>5.&nbsp;</span><span>连续切换相同环境的小节时，环境资源延用，计时继续，不会重置或延长。</span></p>
                      <p><span>6.&nbsp;</span><span>连续切换不同环境的小节时，需要重新启动新的环境。</span></p>
                      <p><span>7.&nbsp;</span><span>在切换至不同环境的小节后，未启动新环境并切换回刚才启动环境的小节时，环境资源不需要重启，计时继续。</span></p>
                    </div>
                  </Modal>
                </div>
              )
            }
            <div className='env-startup' onMouseLeave={() => setShowInfo(false)}>
              {
                status !== '' && status !== EnvStatus.Startuping ? (
                  <>
                    <div className="cpuAndMemory">
                      <span>环境</span>
                      <span
                        onMouseEnter={() => setShowInfo(true)}
                      >
                        <SvgIcon iconClass='resource' />
                        <span>资源</span>
                      </span>
                      <span onClick={onReset}>
                        <SvgIcon iconClass='reset' />
                        <span>重置</span>
                      </span>
                      <span onClick={onClose}>
                        <SvgIcon iconClass='closeenv' />
                        <span>关闭</span>
                      </span>
                    </div>
                  </>
                ) : (
                  <Button loading={status === EnvStatus.Startuping || loading} type="primary" ghost onClick={() => onStartupEnv()}>{status === EnvStatus.Startuping && !loading ? '环境启动中' : '启动环境'}</Button>
                )
              }
              {
                showInfo && status !== '' && <EnvCloud status={status} containers={data?.containers} overtime={overtime} />
              }
            </div>

            {
              (status === EnvStatus.Closing || status === EnvStatus.Resetting) && (
                <div className='env-loading'>
                  <Loading data={status === EnvStatus.Closing ? "环境关闭中…" : "环境重置中…"} />
                </div>
              )
            }
          </>
        ) : <></>
      }
    </>

  )
}

export default EnvStartup;