/*
 * 新增编辑目录信息
 */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Modal, message } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons';
import Tree from '../../Tree'
import SvgIcon from '../../SvgIcon'
import './index.less'
import ImportModal from '../../ImportModal';
import { gitClone, mkdir, refreshResource, rename, rm, touch, upload, getFssResource, getFssFolder } from '../../../api/modules/fs'
import { getDownloadConfig } from '../../../api/modules/repo'
import config from '../../../config';
import { fileTypes } from '../../../utils/fileTypes';
import { Type, courseTypes } from '../../../utils/commonTypes';
import { uploadFolder } from './uploadFolder';
import DownloadSetting from './DownloadSetting';
import { downloadFolder } from './downloadFile'
import Loading from '../../Loading';
import { projectType as ProjectType } from '../../../utils/commonTypes'

// loading文案
const loadingDatas = ['文件下载中，请稍候~', '温馨提示，文件下载与网速有关哦~', '稍等片刻，正在加足马力冲刺！', '全力下载中，请不要中途离开']

const { confirm } = Modal;

type ResourceManageProps = {
  type?: string //资源管理类型
  chapterUrl: string
  modal: string
  myResourceUrl?: string
  changeGit: (returnValue: any) => void
  selectedChapter: any
  setMorePostion?: (value: any) => void
  setMore?: (value: any) => void
  setMoreMenus?: (value: any) => void
  setSelectedFile: (value: any) => void
  setFileInfo: (value: any) => void
  upLoadFile?: (value: any) => void
  removeFile?: (value: any) => void
  repo?: any     // 仓库
  labCode?: any  // 实验室编码
  userId?: any   // 用户id
  couseCode?: any// 课程编码
  courseType?: any //课程类型
  params: any
  open?: boolean       //是否展开
  changeOpen?: Function // 改变是否展开
  exam?: boolean        // 是否是考试   工程项目类实操题
  projectType?: string // 工程项目实操题 project
  examData?: any       // 考试相关数据
  section?: any
}

let ResourceManage: React.FC<ResourceManageProps> = (props, ref) => {
  const { exam,section, open, changeOpen, params, type, repo, labCode, couseCode, userId, chapterUrl, modal, selectedChapter, courseType, changeGit, setMorePostion, setMore, setMoreMenus, setSelectedFile, setFileInfo, upLoadFile, myResourceUrl, removeFile, projectType, examData } = props

  const [myUrl, setMyUrl] = useState<any>()

  const [resourceTab, setResourceTab] = useState(0)

  const resourcemanager = useRef<any>()

  //当前文件
  const [currentFile, setCurrentFile] = useState<any>()

  //是否展开折叠
  const [isChapterFold, setIsChapterFold] = useState(false)

  // 点击刷新的控制
  const [freshcourse, setFreshcourse] = useState(false)

  //编辑图标
  const [editIcon, setEditIcon] = useState(false)

  //删除图标
  const [deteleIcon, setDeteleIcon] = useState(false)

  //导入弹窗是否显示
  const [gitModal, setGitModal] = useState(false)

  // 下载设置弹框是否显示
  const [openDownloadSetting, setOpenDownloadSetting] = useState(false)

  // 下载设置数据
  const [settings, setSettings] = useState([])

  // 下载loading
  const [loading, setLoading] = useState(false)

  // 加载文案
  const [loadingData, setLoadingData] = useState(loadingDatas[0])

  const exitUploadConfirm = () => {
    confirm({
      title: exam ? '确认恢复默认考试资源？' : '确认恢复默认课程资源？',
      icon: <ExclamationCircleFilled rev={undefined} />,
      content: exam ? '恢复默认考试资源，将会更新考试内容+考试资源,自定义内容不会被覆盖。' : '恢复默认课程资源，将会更新课程内容+课程资源,自定义内容不会被覆盖。',
      okText: '确定',
      centered: true,
      className: 'warn',
      width: 400,
      cancelText: '取消',
      onOk: () => {
        window.postMessage({
          type: 'reGitPull',
        })
      },
      onCancel: () => {

      }
    });
  };

  //克隆
  const cloneGit = (v: any) => {
    changeGit(true)
    gitClone({
      repo: params?.get('repo'),
      path: resourceTab === 1 ? myUrl : selectedChapter?.path,
      workdir: true,
      private: resourceTab === 1 ? true : '',
      gitPath: v
    }).then(res => {
      changeGit(false)
      if (res.success) {
        message.success('Git项目文件上传成功')
        setGitModal(false)
      } else {
        message.warning(res.message)


      }

    }).catch(err => {

      changeGit(false)
      message.warning(err.message)
    })
  }

  //文件上传
  const upLoadFileUrl = (e: any) => {
    if (e) {
      saveFile(e)
      upLoadFile && upLoadFile(true)
      upload(e)
        .then(res => {
          message.success('上传成功')
          upLoadFile && upLoadFile(false)
          deteleFile(e)
        })
        .catch(error => {
          Modal.warning({
            title: '文件上传失败！',
            content: '因网络/环境原因，文件上传失败，请刷新工作台/刷新环境后再试试~',
            centered: true,
            width: 400,
            okText: '我知道了'
          })
          upLoadFile && upLoadFile(false)
          deteleFile(e)
        })
    }
  }

  //保存正在上传的文件
  const saveFile = (e: any) => {
    let windowUploadList: any = []
    if (window.sessionStorage.getItem('uploadFile')) {
      windowUploadList = JSON.parse(window.sessionStorage.getItem('uploadFile') || '')
    }
    let newwindowUploadList: any[] = []
    windowUploadList.forEach((obj: any) => {
      if (obj.repo === e.repo && obj.path === e.path) return
      newwindowUploadList.push(obj)
    })
    newwindowUploadList = [...newwindowUploadList, e]
    window.sessionStorage.setItem('uploadFile', JSON.stringify(newwindowUploadList))
  }

  //删除正在上传的文件
  const deteleFile = (e: any) => {
    let windowUploadList: any = []
    if (window.sessionStorage.getItem('uploadFile')) {
      windowUploadList = JSON.parse(window.sessionStorage.getItem('uploadFile') || '')
    }
    let newwindowUploadList: any[] = []
    windowUploadList.forEach((obj: any) => {
      if (obj.repo === e.repo && obj.path === e.path) return
      newwindowUploadList.push(obj)
    })
    window.sessionStorage.setItem('uploadFile', JSON.stringify(newwindowUploadList))
    refresh(false)
  }

  useEffect(() => {
    if (myResourceUrl) {
      let url = new URL(myResourceUrl ?? '')
      setMyUrl(url.searchParams.get('path'))
    }
  }, [myResourceUrl])



  useImperativeHandle(ref, () => ({
    changeCurrentFile: (data: any) => {
      setCurrentFile(data)
    },
  }));


  const customIcons = exam ? [
    {
      icon: (<SvgIcon iconClass='resourceIcon' ></SvgIcon>),
      name: '说明文档',
      onClick: () => {
        // 课程资源
        window.postMessage({
          type: 'pushContainer',
          component: 'Webview',
          value: {
            path: 'https://oscollegedoc.helplook.com/',
            name: '说明文档',
            type: 'file'
          }
        })
      }
    },
    {
      icon: (type === 'active' ? '' : <SvgIcon iconClass='fresh' style={{ color: '#999999' }} className={freshcourse ? 'freshh-rotate' : ''}></SvgIcon>),
      name: '刷新',
      onClick: (data: any) => {
        // setFreshcourse(true)
        // resourcemanager?.current?.refresh()
        refresh(true)
      }
    },
    {
      icon: (modal === '1' && type !== 'active' && <SvgIcon iconClass='download'></SvgIcon>),
      name: '下载设置',
      onClick: () => {
        setOpenDownloadSetting(true)
      }
    },
    {
      icon: (<SvgIcon iconClass='gitIcon' ></SvgIcon>),
      name: '克隆git项目文件',
      onClick: (data: any) => {
        setGitModal(true)
      }
    },
    {
      icon: (<SvgIcon iconClass='add-file' ></SvgIcon>),
      name: '新建文件',
      onClick: (data: any) => {
        setIsChapterFold(false)
        setMorePostion && setMorePostion(undefined)
        if (!chapterUrl) {
          message.error('请选择课程小节')
          return false
        }
        if (data) {
          data.edit = false
        }
        setCurrentFile(data)
        const all = resourcemanager?.current?.data()

        const parent = data?.isFolder ? data?.path ?? '' : data?.parent ?? ''
        const defaultTitle = '新建 文本文档.txt'
        let title = defaultTitle
        let count = 1
        while (all?.filter((it: any) => it?.parent === parent && it?.title === title)?.length > 0) {
          const name = defaultTitle?.substring(0, defaultTitle?.lastIndexOf('.'))
          const ext = defaultTitle?.substring(defaultTitle.lastIndexOf('.'))
          title = `${name}(${count})${ext}`
          count++
        }
        touch({
          repo: params?.get('repo'),
          key: params?.get('key'),
          path: resourceTab === 1 ? myUrl : selectedChapter?.path,
          workdir: true,
          private: resourceTab === 1 ? true : '',
          data: {
            title: title,
            parent: parent
          }
        }).then(data => {
          if (data) {
            data.edit = true
          }
          setCurrentFile(data)
          // resourcemanager?.current?.refresh()
          refresh(false)
        }).catch(error => {
          console.error('createFile error ->', error)
        })
      }
    },
    {
      icon: (<SvgIcon iconClass='add-folder' ></SvgIcon>),
      name: '新建文件夹',
      onClick: (data: any) => {
        setIsChapterFold(false)
        setMorePostion && setMorePostion(undefined)
        if (!chapterUrl) {
          message.error('请选择课程小节')
          return false
        }
        if (data) {
          data.edit = false
        }
        setCurrentFile(data)
        const all = resourcemanager?.current?.data()
        const parent = data?.isFolder ? data?.path ?? '' : data?.parent ?? ''
        const defaultTitle = '新建 文件夹'
        let title = defaultTitle
        let count = 1
        while (all?.filter((it: any) => it?.parent === parent && it?.title === title)?.length > 0) {
          title = `${defaultTitle}(${count})`
          count++
        }
        mkdir({
          repo: params?.get('repo'),
          key: params?.get('key'),
          path: resourceTab === 1 ? myUrl : selectedChapter?.path,
          workdir: true,
          private: resourceTab === 1 ? true : '',
          data: {
            title: title,
            parent: parent
          }
        }).then(data => {
          if (data) {
            data.edit = true
          }
          setCurrentFile(data)
          // resourcemanager?.current?.refresh()
          refresh(false)
        }).catch(error => {
          console.error('createFolder error ->', error)
        })
      }
    },
    {
      icon: (type === 'active' ? <SvgIcon iconClass='uploadIcon'></SvgIcon> : <SvgIcon iconClass='tree-more' ></SvgIcon>),
      name: type === 'active' ? '文件上传' : '更多',
      onClick: (data: any, event: any) => {
        if (type === 'active') {

          const input = document.createElement('input')
          input.type = 'file'
          input.addEventListener('change', function (e) {
            const files: any = input.files
            if (!files) return false
            const file = files[0]
            const size = file.size
            const maxSize = 300 * 1024 * 1024
            if (size > maxSize) {
              message.warning('文件大小不能超过300M！')
              return false
            }
            const prefix = (!data?.isFolder ? data?.parent : data?.path) ?? ''
            const path = `${prefix ? prefix + '/' : ''}${file?.name ?? '新建 文本文件.txt'}`
            resourcemanager?.current?.uploadFileChange(file, path, data)
            upload({
              repo: params?.get('repo'),
              path: selectedChapter?.path,
              file: path,
              workdir: true,
              data: {
                file: file
              }
            })
              .then(res => message.success('上传成功'))
              .catch(error => message.error('上传失败'))
          })
          input.click()
          setMorePostion && setMorePostion(undefined)
          return
        }
        setIsChapterFold(false)

        setMore && setMore(false)
        if (data) {
          data.edit = false
        }
        setCurrentFile(data)
        if (!chapterUrl) {
          message.error('请选择课程小节')
          return false
        }

        setMorePostion && setMorePostion({
          left: event?.clientX - 20,
          top: event?.clientY + 8,
        })
        setMoreMenus && setMoreMenus([{
          title: '上传文件',
          onClick: () => {
            const input = document.createElement('input')
            input.type = 'file'
            input.addEventListener('change', function (e) {
              const files: any = input.files
              if (!files) return false
              const file = files[0]
              const size = file.size
              const maxSize = 300 * 1024 * 1024
              if (size > maxSize) {
                message.warning('文件大小不能超过300M！')
                return false
              }
              const prefix = (!data?.isFolder ? data?.parent : data?.path) ?? ''
              const path = `${prefix ? prefix + '/' : ''}${file?.name ?? '新建 文本文件.txt'}`
              resourcemanager?.current?.uploadFileChange(file, path, data)
              upLoadFileUrl({
                repo: params?.get('repo'),
                path: resourceTab === 1 ? myUrl : selectedChapter?.path,
                workdir: true,
                private: resourceTab === 1 ? true : '',
                file: path,
                data: {
                  file: file
                }
              })


            })
            input.click()
            setMorePostion && setMorePostion(undefined)
          }
        },
        {
          title: '上传文件夹',
          onClick: async () => {
            setMorePostion && setMorePostion(undefined)
            const prefix = (!data?.isFolder ? data?.parent : data?.path) ?? ''
            const parent = `${prefix ? prefix + '/' : ''}`
            const _params = {
              repo: params?.get('repo'),
              path: resourceTab === 1 ? myUrl : selectedChapter?.path,
              workdir: true,
              private: resourceTab === 1 ? true : ''
            }
            try {
              await uploadFolder(_params, parent)
              refresh()
            } catch (error) {

            }
          }
        }])
      }
    },
  ] : [
    {
      icon: (<SvgIcon iconClass='resourceIcon' ></SvgIcon>),
      name: '说明文档',
      onClick: () => {
        // 课程资源
        window.postMessage({
          type: 'pushContainer',
          component: 'Webview',
          value: {
            path: 'https://oscollegedoc.helplook.com/',
            name: '说明文档',
            type: 'file'
          }
        })
      }
    },
    {
      icon: (type === 'active' ? '' : <SvgIcon iconClass='fresh' style={{ color: '#999999' }} className={freshcourse ? 'freshh-rotate' : ''}></SvgIcon>),
      name: '刷新',
      onClick: (data: any) => {
        // setFreshcourse(true)
        // resourcemanager?.current?.refresh()
        refresh(true)
      }
    },
    {
      icon: (modal === '1' && type !== 'active' && <SvgIcon iconClass='download'></SvgIcon>),
      name: '下载设置',
      onClick: () => {
        setOpenDownloadSetting(true)
      }
    },
    {
      icon: (modal === '0' && <SvgIcon iconClass='resetResource'></SvgIcon>),
      name: exam ? '恢复默认考试资源' : '恢复默认课程资源',
      onClick: (data: any) => {
        exitUploadConfirm()
      }
    },
    {
      icon: (<SvgIcon iconClass='gitIcon' ></SvgIcon>),
      name: '克隆git项目文件',
      onClick: (data: any) => {
        setGitModal(true)
      }
    },
    {
      icon: (<SvgIcon iconClass='add-file' ></SvgIcon>),
      name: '新建文件',
      onClick: (data: any) => {
        setIsChapterFold(false)
        setMorePostion && setMorePostion(undefined)
        if (!chapterUrl) {
          message.error('请选择课程小节')
          return false
        }
        if (data) {
          data.edit = false
        }
        setCurrentFile(data)
        const all = resourcemanager?.current?.data()

        const parent = data?.isFolder ? data?.path ?? '' : data?.parent ?? ''
        const defaultTitle = '新建 文本文档.txt'
        let title = defaultTitle
        let count = 1
        while (all?.filter((it: any) => it?.parent === parent && it?.title === title)?.length > 0) {
          const name = defaultTitle?.substring(0, defaultTitle?.lastIndexOf('.'))
          const ext = defaultTitle?.substring(defaultTitle.lastIndexOf('.'))
          title = `${name}(${count})${ext}`
          count++
        }
        touch({
          repo: params?.get('repo'),
          key: params?.get('key'),
          path: resourceTab === 1 ? myUrl : selectedChapter?.path,
          workdir: true,
          private: resourceTab === 1 ? true : '',
          data: {
            title: title,
            parent: parent
          }
        }).then(data => {
          if (data) {
            data.edit = true
          }
          setCurrentFile(data)
          // resourcemanager?.current?.refresh()
          refresh(false)
        }).catch(error => {
          console.error('createFile error ->', error)
        })
      }
    },
    {
      icon: (<SvgIcon iconClass='add-folder' ></SvgIcon>),
      name: '新建文件夹',
      onClick: (data: any) => {
        setIsChapterFold(false)
        setMorePostion && setMorePostion(undefined)
        if (!chapterUrl) {
          message.error('请选择课程小节')
          return false
        }
        if (data) {
          data.edit = false
        }
        setCurrentFile(data)
        const all = resourcemanager?.current?.data()
        const parent = data?.isFolder ? data?.path ?? '' : data?.parent ?? ''
        const defaultTitle = '新建 文件夹'
        let title = defaultTitle
        let count = 1
        while (all?.filter((it: any) => it?.parent === parent && it?.title === title)?.length > 0) {
          title = `${defaultTitle}(${count})`
          count++
        }
        mkdir({
          repo: params?.get('repo'),
          key: params?.get('key'),
          path: resourceTab === 1 ? myUrl : selectedChapter?.path,
          workdir: true,
          private: resourceTab === 1 ? true : '',
          data: {
            title: title,
            parent: parent
          }
        }).then(data => {
          if (data) {
            data.edit = true
          }
          setCurrentFile(data)
          // resourcemanager?.current?.refresh()
          refresh(false)
        }).catch(error => {
          console.error('createFolder error ->', error)
        })
      }
    },
    {
      icon: (type === 'active' ? <SvgIcon iconClass='uploadIcon'></SvgIcon> : <SvgIcon iconClass='tree-more' ></SvgIcon>),
      name: type === 'active' ? '文件上传' : '更多',
      onClick: (data: any, event: any) => {
        if (type === 'active') {

          const input = document.createElement('input')
          input.type = 'file'
          input.addEventListener('change', function (e) {
            const files: any = input.files
            if (!files) return false
            const file = files[0]
            const size = file.size
            const maxSize = 300 * 1024 * 1024
            if (size > maxSize) {
              message.warning('文件大小不能超过300M！')
              return false
            }
            const prefix = (!data?.isFolder ? data?.parent : data?.path) ?? ''
            const path = `${prefix ? prefix + '/' : ''}${file?.name ?? '新建 文本文件.txt'}`
            resourcemanager?.current?.uploadFileChange(file, path, data)
            upload({
              repo: params?.get('repo'),
              path: selectedChapter?.path,
              file: path,
              workdir: true,
              data: {
                file: file
              }
            })
              .then(res => message.success('上传成功'))
              .catch(error => message.error('上传失败'))
          })
          input.click()
          setMorePostion && setMorePostion(undefined)
          return
        }
        setIsChapterFold(false)

        setMore && setMore(false)
        if (data) {
          data.edit = false
        }
        setCurrentFile(data)
        if (!chapterUrl) {
          message.error('请选择课程小节')
          return false
        }

        setMorePostion && setMorePostion({
          left: event?.clientX - 20,
          top: event?.clientY + 8,
        })
        setMoreMenus && setMoreMenus([{
          title: '上传文件',
          onClick: () => {
            const input = document.createElement('input')
            input.type = 'file'
            input.addEventListener('change', function (e) {
              const files: any = input.files
              if (!files) return false
              const file = files[0]
              const size = file.size
              const maxSize = 300 * 1024 * 1024
              if (size > maxSize) {
                message.warning('文件大小不能超过300M！')
                return false
              }
              const prefix = (!data?.isFolder ? data?.parent : data?.path) ?? ''
              const path = `${prefix ? prefix + '/' : ''}${file?.name ?? '新建 文本文件.txt'}`
              resourcemanager?.current?.uploadFileChange(file, path, data)
              upLoadFileUrl({
                repo: params?.get('repo'),
                path: resourceTab === 1 ? myUrl : selectedChapter?.path,
                workdir: true,
                private: resourceTab === 1 ? true : '',
                file: path,
                data: {
                  file: file
                }
              })


            })
            input.click()
            setMorePostion && setMorePostion(undefined)
          }
        },
        {
          title: '上传文件夹',
          onClick: async () => {
            setMorePostion && setMorePostion(undefined)
            const prefix = (!data?.isFolder ? data?.parent : data?.path) ?? ''
            const parent = `${prefix ? prefix + '/' : ''}`
            const _params = {
              repo: params?.get('repo'),
              path: resourceTab === 1 ? myUrl : selectedChapter?.path,
              workdir: true,
              private: resourceTab === 1 ? true : ''
            }
            try {
              await uploadFolder(_params, parent)
              refresh()
            } catch (error) {

            }
          }
        }
        ])
      }
    },
  ]

  // 刷新资源管理   loading 刷新是否有lodding  默认没有  因为有些是背后默认刷新的
  const refresh = useCallback(async (loading: boolean = false) => {
    if (freshcourse) return
    if (loading) {
      setFreshcourse(true)
    }
    const res = await refreshResource({
      repo: params?.get('repo'),
      _private: resourceTab === 1 ? true : undefined,         // 我的资源 参数为true
      path: resourceTab === 1 ? myUrl : selectedChapter?.path, // 我的资源 path不一样
      workdir: true,
    }).catch().finally(() => {
      setTimeout(() => {
        setFreshcourse(false)
      }, 500);
    })
  }, [freshcourse, resourceTab, selectedChapter, myUrl])

  // 获取下载配置
  const fetchDownloadConfig = () => {
    getDownloadConfig({
      repo,
      path: selectedChapter?.path
    }).then(res => {
      setSettings(res)
    })
  }

  useEffect(() => {
    // 演示代码不设置
    if (selectedChapter?.path && type !== 'active') {
      fetchDownloadConfig()
    }
  }, [selectedChapter])

  // 判断是不是考试评分模式，如果是，所有资源都能下载， 我的资源还是不行
  // 当前存在实操题（暂时已经隐藏了）、工程项目类实操题 判分两种
  const isExamMark = useCallback(() => {
    if (
      (examData?.examType === '0' && examData?.type === Type.PROJECT && ProjectType.MARK === examData?._projectType) ||
      (examData?.examType === '0' && examData?.examstatus === 'mark') ||
      (examData?.examType === '0' && examData?.type === Type.PROJECT && ProjectType.PREVIEW === examData?._projectType && modal === '0')
    ) {
      return true
    } else {
      return false
    }
  }, [examData])

  return (
    <div className='resource-contant'>
      {open && <div style={{ position: 'relative' }}>
        <div className='resource-line'>

        </div>
      </div>}
      <Tree
        ref={resourcemanager}
        selectedChapter={selectedChapter}//当前选中小节
        url={chapterUrl}
        myResourceUrl={myResourceUrl}
        myUrl={myUrl}
        title={type === 'active' ? '' : courseType === courseTypes.EXPERIENCE ? '资源管理' : exam ? '考试资源' : courseType === courseTypes.COURSE ? `资源附件${section?.title ? '-' : ''}${section?.title ?? ''}` : '博客资源'}
        type={type || ''}
        treeType={'resource'}
        open={open}
        changeOpen={changeOpen}
        // fold={isChapterFold}
        // onFold={(f) => {
        //   setIsChapterFold(f)
        // }}
        changeTab={(e: any) => {
          if (e !== resourceTab) {
            setCurrentFile(undefined)
            setSelectedFile(undefined)
            setResourceTab(e)
          }
        }

        }
        modal={modal}
        projectType={projectType}
        // foldIcon={(<SvgIcon iconClass='right' ></SvgIcon>)}
        // expandIcon={(<SvgIcon iconClass='down' ></SvgIcon>)}
        moreIcon={type === 'active' ? <div style={{ display: 'flex', marginTop: '2px' }}>
          <div onMouseEnter={() => setEditIcon(true)}
            onMouseLeave={() => { setEditIcon(false) }}>{editIcon ? <SvgIcon iconClass='sourceEditHover' ></SvgIcon> : <SvgIcon iconClass='sourceEdit' ></SvgIcon>}</div>
          <div style={{ marginLeft: '4px' }} onMouseEnter={() => setDeteleIcon(true)}
            onMouseLeave={() => { setDeteleIcon(false) }}>{deteleIcon ? <SvgIcon iconClass='soueceDeteleHover' ></SvgIcon> : <SvgIcon iconClass='soueceDetele' ></SvgIcon>}</div>
        </div> : (<SvgIcon iconClass='tree-more' fontSize='14px'></SvgIcon>)}
        doubleClickToEdit={true}
        selected={currentFile}
        defaultFold={false}
        // over={
        //   () => {
        //     setTimeout(() => {
        //       setFreshcourse(false)
        //     }, 1000);
        //   }
        // }
        customIcons={customIcons}
        changedHandle={(data: any): any => {
          setFileInfo(data)
          return data?.map((it: any) => {
            if (it?.isFolder) {
              it.foldIcon = (<SvgIcon iconClass='tree-folder-notopen' fontSize='14px'></SvgIcon>)
              it.expandIcon = (<SvgIcon iconClass='tree-folder-open' fontSize='14px'></SvgIcon>)
            } else {
              const type: string = it?.title?.substring(it?.title?.lastIndexOf('.') + 1)
              // const type: string = it?.title?.match(/\.(.+)$/)?.[1]
              if (type && fileTypes[type]) {
                it.foldIcon = (<SvgIcon iconClass={fileTypes[type]} fontSize='14px'></SvgIcon>)
              } else {
                it.foldIcon = (<SvgIcon iconClass='tree-file' fontSize='14px'></SvgIcon>)
              }
              it.expandIcon = it.foldIcon
            }
            it.edit = false
            return it
          })
        }}
        onClick={(data?: any) => {

          setMorePostion && setMorePostion(undefined)

          if (data) {
            if (!data?.isFolder) {
              if (resourceTab === 1) {
                data.url = myUrl
              }
              setSelectedFile(data)
            }
          }
        }}
        onEntryEdit={(data: any) => {
          setMorePostion && setMorePostion(undefined)
          setCurrentFile(data)
        }}
        onBlurEdit={(data: any) => {
          if (resourceTab === 1) {
            data.url = myUrl
          }
          if (data) {
            data.title = data?.title.trim()
            data.edit = false
          }
          setCurrentFile(data)
          rename({
            repo: params?.get('repo'),
            key: params?.get('key'),
            path: resourceTab === 1 ? myUrl : selectedChapter?.path,
            workdir: true,
            private: resourceTab === 1 ? true : '',
            data
          }).then(res => {
            setCurrentFile(res)
            removeFile && removeFile(data)
            if (!res?.isFolder) {
              if (resourceTab === 1) {
                res.url = myUrl
              }
              setSelectedFile(res)
            }
            // resourcemanager?.current?.refresh()
            refresh(false)
          }).catch(err => {
            message.warning(err?.message)
          })
        }}
        onEntryMore={(data: any, event: any) => {
          if (resourceTab === 1) {
            data.url = myUrl
          }
          setMore && setMore(false)
          if (editIcon) {
            setMorePostion && setMorePostion(undefined)
            setCurrentFile(undefined)
            if (data) {
              data.edit = true
            }
            setCurrentFile(data)
            return
          }
          if (deteleIcon) {
            if (data) {
              data.edit = false
            }
            setCurrentFile(data)
            rm({
              repo: params?.get('repo'),
              key: params?.get('key'),
              path: resourceTab === 1 ? myUrl : selectedChapter?.path,
              workdir: true,
              private: resourceTab === 1 ? true : '',
              data
            }).then(res => {
              removeFile && removeFile(data)
              setCurrentFile(undefined)
              setMorePostion && setMorePostion(undefined)
              // resourcemanager?.current?.refresh()
              refresh(false)
            })
            return
          }
          setDeteleIcon(false)
          setEditIcon(false)
          setMorePostion && setMorePostion({
            left: event.clientX + 20,
            top: event.clientY - 10,
          })

          const menuData: any = [
            {
              title: '编辑',
              onClick: () => {
                setMorePostion && setMorePostion(undefined)
                setCurrentFile(undefined)
                if (data) {
                  data.edit = true
                }
                setCurrentFile(data)
              }
            },
            {
              title: '删除',
              onClick: () => {
                if (data) {
                  data.edit = false
                }

                setCurrentFile(data)
                rm({
                  repo: params?.get('repo'),
                  key: params?.get('key'),
                  path: resourceTab === 1 ? myUrl : selectedChapter?.path,
                  workdir: true,
                  private: resourceTab === 1 ? true : '',
                  data
                }).then(res => {

                  removeFile && removeFile(data)
                  setCurrentFile(undefined)
                  setMorePostion && setMorePostion(undefined)
                  // resourcemanager?.current?.refresh()
                  refresh(false)
                })
              }
            },
            {
              title: '复制路径',
              onClick: () => {
                const text = `${data.path}`;
                const elem = document.createElement("textarea");
                elem.value = text;
                document.body.appendChild(elem);
                elem.select();
                document.execCommand("copy");
                document.body.removeChild(elem);
                setMorePostion && setMorePostion(undefined)
                message.success('文件路径复制成功!')
              }
            }
          ]
          // 课程资源：允许下载的目录（我的资源不可下载）
          if (resourceTab === 0) {
            const allowDownload = settings?.filter((v: any) => v?.allowDownload).find((v: any) => {
              if (v?.path === data?.path?.split('/')?.[0]) {
                return true
              }
              return false
            })
            if (allowDownload || isExamMark()) {
              menuData.splice(2, 0, {
                title: '下载',
                onClick: () => {
                  setMorePostion && setMorePostion(undefined)
                  if (!data?.isFolder) {
                    // 单文件下载
                    getFssResource({
                      repo,
                      path: selectedChapter?.path,
                      file: data?.path
                    }).then(res => {
                      const blob = res
                      const url = URL.createObjectURL(blob)
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', data?.title)
                      document.body.appendChild(link)
                      link.click()
                      URL.revokeObjectURL(url)
                      document.body.removeChild(link)
                    }).catch(() => {
                      Modal.error({
                        title: '下载失败',
                        content: '因网络不稳定或中途离开等原因，导致本次下载失败，请稍后再试~',
                        centered: true,
                        width: 400,
                        okText: '我知道了'
                      })
                    })
                  } else {
                    // 文件夹下载
                    const _params = {
                      repo,
                      path: selectedChapter?.path,
                      file: data?.path,
                      title: data?.title
                    }
                    getFssFolder(_params).then(res => {
                      const children = res?.children
                      if (!children || children?.length === 0) {
                        message.warning('文件夹为空，无法下载！')
                        return
                      }
                      const size = res?.size
                      const maxSize = 300 * 1024 * 1024
                      // 浏览器支持showDirectoryPicker并且不嵌套在iframe里
                      if (typeof (window as any).showDirectoryPicker === 'function' && window.top === window.self && size <= maxSize) {
                        (window as any).showDirectoryPicker().then(async (directoryHandle: any) => {
                          setLoading(true)
                          let index = 0
                          const maxLen = loadingDatas?.length - 1
                          const _timer = setInterval(() => {
                            if (index === maxLen) {
                              index = 0
                            } else {
                              index++
                            }
                            setLoadingData(loadingDatas[index])
                          }, 3000)
                          downloadFolder(children, _params, false, directoryHandle).then(() => {
                            setLoading(false)
                            message.success('下载成功！')
                            clearInterval(_timer)
                          }).catch(() => {
                            setLoading(false)
                            clearInterval(_timer)
                          })
                        }).catch(() => { })
                      } else {
                        if (size > maxSize) {
                          Modal.confirm({
                            title: '温馨提示',
                            content: '因下载文件已超过300MB，为加快下载进度，本次下载已调整为压缩成压缩包进行下载。请确认操作',
                            centered: true,
                            width: 400,
                            cancelText: '取消下载',
                            okText: '下载压缩包',
                            onOk() {
                              downloadFolder(children, _params, true)
                            }
                          })
                        } else {
                          // 浏览器不支持 showDirectoryPicker / iframe不支持
                          downloadFolder(children, _params, true)
                        }
                      }
                    }).catch(() => {
                      message.error('获取文件夹内容失败！请稍后再试~')
                    })
                  }
                }
              });
            }
          }
          if (!data?.isFolder && /\.(html|htm)$/.test(data?.title ?? '')) {
            menuData.unshift({
              title: '运行',
              onClick: () => {
                // 课程资源
                let url: string
                if (resourceTab === 0) {
                  url = `${config.baseURL}/fss/resource/${repo}/${encodeURIComponent(selectedChapter?.path ?? '')}/workdir/${encodeURIComponent(data?.path ?? '')}`
                } else {  // 我的资源
                  url = `${config.baseURL}/fss/storage/${labCode}/${couseCode}/${userId}/${encodeURIComponent(data?.path ?? '')}`
                }
                if (url) {
                  window.postMessage({
                    type: 'pushContainer',
                    component: 'Webview',
                    value: {
                      path: url,
                      name: data?.title,
                      type: 'file'
                    }
                  })
                }
                setMorePostion && setMorePostion(undefined)
              }
            })
          }
          setMoreMenus?.(menuData)
        }}
      />
      <ImportModal ifTrue={gitModal} changeValue={() => {
        setGitModal(false)
      }} cloneGitValue={(e: any) => {

        cloneGit(e)
      }}></ImportModal>

      <DownloadSetting open={openDownloadSetting} data={resourcemanager?.current?.data()} settings={settings} params={{ repo, path: selectedChapter?.path }} onClose={value => {
        setOpenDownloadSetting(false)
        setSettings(value)
      }} />

      {
        loading && (
          <div className='download-loading'>
            <Loading data={loadingData} />
          </div>
        )
      }

    </div>
  )
}

ResourceManage = forwardRef(ResourceManage as any)

export default ResourceManage;