import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef, useContext } from 'react';
import Editor, { EditorProps, loader } from '@monaco-editor/react';
import { useDispatch, useSelector } from "react-redux";
import { updateRTSave } from '../../store/modules/course';
import { isJsonString, base64ToText, textToBase64, getQueryStringValue } from '../../utils'
import './index.less'
import SvgIcon from '../SvgIcon';
import SharedDataContext from '../../utils/share';
import { repoExamLog } from '../../api/modules/community';


const colors = [
  '#1677FF',
  '#16FF80',
  '#FF6F16',
  '#FF1616',
  '#2516FF',
  '#FD16FF',
  '#FF1629',
  '#F8FF16',
  '#16FFFA',
  '#93FF16',
  '#9F16FF',
  '#FFDB16',
  '#FF16B2',
  '#FF169C',
  '#6FFF16',
  '#16FFDD',
]

enum LanguageEnums {
  "abap" = "abap",
  "cls" = "apex",
  "azcli" = "azcli",
  "bat" = "bat",
  "cmd" = "bat",
  "c" = "c",
  "h" = "c",
  "mligo" = "cameligo",
  "clj" = "clojure",
  "cljs" = "clojure",
  "cljc" = "clojure",
  "edn" = "clojure",
  "coffee" = "coffeescript",
  "cpp" = "cpp",
  "cc" = "cpp",
  "cxx" = "cpp",
  "hpp" = "cpp",
  "hh" = "cpp",
  "hxx" = "cpp",
  "cs" = "csharp",
  "csx" = "csharp",
  "cake" = "csharp",
  "css" = "css",
  "dart" = "dart",
  "dockerfile" = "dockerfile",
  "ecl" = "ecl",
  "fs" = "fsharp",
  "fsi" = "fsharp",
  "ml" = "fsharp",
  "mli" = "fsharp",
  "fsx" = "fsharp",
  "fsscript" = "fsharp",
  "go" = "go",
  "graphql" = "graphql",
  "gql" = "graphql",
  "handlebars" = "handlebars",
  "hbs" = "handlebars",
  "tf" = "hcl",
  "tfvars" = "hcl",
  "hcl" = "hcl",
  "html" = "html",
  "htm" = "html",
  "shtml" = "html",
  "xhtml" = "html",
  "mdoc" = "html",
  "jsp" = "html",
  "asp" = "html",
  "aspx" = "html",
  "jshtm" = "html",
  "ini" = "ini",
  "properties" = "ini",
  "gitconfig" = "ini",
  "java" = "java",
  "jav" = "java",
  "js" = "javascript",
  "es6" = "javascript",
  "jsx" = "javascript",
  "mjs" = "javascript",
  "jl" = "julia",
  "kt" = "kotlin",
  "less" = "less",
  "lex" = "lexon",
  "lua" = "lua",
  "m3" = "m3",
  "i3" = "m3",
  "mg" = "m3",
  "ig" = "m3",
  "md" = "markdown",
  "markdown" = "markdown",
  "mdown" = "markdown",
  "mkdn" = "markdown",
  "mkd" = "markdown",
  "mdwn" = "markdown",
  "mdtxt" = "markdown",
  "mdtext" = "markdown",
  "s" = "mips",
  "dax" = "msdax",
  "msdax" = "msdax",
  "m" = "objective-c",
  "pas" = "pascal",
  "p" = "pascal",
  "ligo" = "pascaligo",
  "pl" = "perl",
  "php" = "php",
  "php4" = "php",
  "php5" = "php",
  "phtml" = "php",
  "ctp" = "php",
  "dats" = "postiats",
  "sats" = "postiats",
  "hats" = "postiats",
  "pq" = "powerquery",
  "pqm" = "powerquery",
  "ps1" = "powershell",
  "psm1" = "powershell",
  "psd1" = "powershell",
  "jade" = "pug",
  "pug" = "pug",
  "py" = "python",
  "rpy" = "python",
  "pyw" = "python",
  "cpy" = "python",
  "gpy" = "python",
  "gypi" = "python",
  "r" = "r",
  "rhistory" = "r",
  "rmd" = "r",
  "rprofile" = "r",
  "rt" = "r",
  "cshtml" = "razor",
  "redis" = "redis",
  "rst" = "restructuredtext",
  "rb" = "ruby",
  "rbx" = "ruby",
  "rjs" = "ruby",
  "gemspec" = "ruby",
  "pp" = "ruby",
  "rs" = "rust",
  "rlib" = "rust",
  "sb" = "sb",
  "scala" = "scala",
  "sc" = "scala",
  "sbt" = "scala",
  "scm" = "scheme",
  "ss" = "scheme",
  "sch" = "scheme",
  "rkt" = "scheme",
  "scss" = "scss",
  "sh" = "shell",
  "bash" = "shell",
  "aes" = "sophia",
  "sol" = "sol",
  "sql" = "sql",
  "st" = "st",
  "iecst" = "st",
  "iecplc" = "st",
  "lc3lib" = "st",
  "swift" = "swift",
  "sv" = "systemverilog",
  "svh" = "systemverilog",
  "tcl" = "tcl",
  "twig" = "twig",
  "ts" = "typescript",
  "tsx" = "typescript",
  "vb" = "vb",
  "v" = "verilog",
  "vh" = "verilog",
  "xml" = "xml",
  "dtd" = "xml",
  "ascx" = "xml",
  "csproj" = "xml",
  "config" = "xml",
  "wxi" = "xml",
  "wxl" = "xml",
  "wxs" = "xml",
  "xaml" = "xml",
  "svg" = "xml",
  "svgz" = "xml",
  "oft" = "xml",
  "xsl" = "xml",
  "yaml" = "yaml",
  "yml" = "yaml",
  'json' = 'json',
  'vue' = 'html'
}

interface Props extends EditorProps {
  userKey?: string
  wsUrl?: string
  onContentChange?: (value: string, save?: boolean) => void
  onMountEditor?: (editor: any, monaco: any) => void
  onPaste?: () => void //粘贴事件（处理图片）
  onChange?: (v:any) => void //editor内容改变
  [other: string]: any
}

// 中文配置
loader.config({
  "paths": { vs: 'https://cdn.bootcdn.net/ajax/libs/monaco-editor/0.36.1/min/vs' },
  "vs/nls": { availableLanguages: { "*": "zh-cn" } }
})

let CodeEditor: React.FC<Props> = (props, ref) => {

  const dispatch = useDispatch();

  const { RTSave } = useSelector((state: any) => state.course);

  const { userKey, wsUrl, language, onContentChange, onMountEditor, onPaste, onChange } = props

  // 获取全局的共享数据，决定是否禁用复制粘贴
  const { examData: { disableCopyPaste } } = useContext(SharedDataContext)

  const [websocket, setWebsocket] = useState<any>()

  // 内容
  const [content, setContent] = useState<any>(undefined)

  // 临时内容
  const [valueTemp, setValueTemp] = useState<any>(undefined)

  // 重设内容
  const [value, setValue] = useState('')

  // 保证第一次读取
  const [first, setFirst] = useState(false)

  // 实时保存的timer
  const [saveTimer, setSaveTimer] = useState<any>()

  // 内容设置的timer
  const [contentTimer, setContentTimer] = useState<any>()

  // 需要重连
  const [reconnect, setReconnect] = useState(false)

  // 正在编辑中
  const [editing, setEditing] = useState(false)

  // 所有协同光标位置
  const [cursors, setCursors] = useState<any>([])

  // 位置装饰
  const [decorations, setDecorations] = useState([]);

  // 最后发送的光标
  const [lastPosition, setLastPosition] = useState<any>()

  // 上下左右键
  const [fourPosition, setFourPosition] = useState<any>()

  // 更新内容
  const [updateValue, setUpdateValue] = useState(false)

  // 协同光标
  const [newCursors, setNewCursors] = useState<any>([])

  // 显示提示框
  const [showLoading, setShowLoading] = useState<any>(false)

  // 加载框
  const [loadingTimer, setLoadingTimer] = useState<any>()

  const editorDivRef = useRef<any>(null)
  const editorRef = useRef<any>(null);
  const monacoRef = useRef<any>(null);

  // websocket连接
  const handleWS = () => {
    try {
      if (!wsUrl) return
      if (websocket && websocket?.readyState === 1) {
        websocket?.close(4999)
      }

      const ws = new WebSocket(wsUrl);
      setWebsocket(ws)
      setReconnect(false)

      ws.onopen = function () {
        console.log('codeeditor连接成功 => ', wsUrl)
        setReconnect(false)
      }

      ws.onmessage = function (res: any) {
        const { data } = res
        handleText(data)
      }

      ws.onclose = function (res: any) {
        console.log('codeeditor 错误 => ', res);

        const code = res.code
        if (code < 3000) {
          setReconnect(true)
        }
      }

      ws.onerror = function (res: any) {
        console.log('codeeditor 错误 => ', res);
      }

      // 处理内容
      const handleText = (data: any) => {
        if (isJsonString(data)) {
          const json = JSON.parse(data)
          const type = json?.type
          const _data = json?.data
          if (type === 'read') {
            const mdText = _data ? base64ToText(_data) : ''
            onContentChange && onContentChange(mdText)
            setValueTemp(mdText)
          }
          if (type === 'cursor' && userKey) {
            let values = Object.values(_data)
            setCursors(values)
            values = values?.filter((v: any) => v?.key !== userKey)?.map((v: any) => v?.cursor)
            setNewCursors(values)
          }
        }
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (wsUrl && reconnect) {
      console.log('编辑器开始重连');
      handleWS()
    }
  }, [wsUrl, reconnect])

  useEffect(() => {
    if (wsUrl) {
      setCursors([])
      setNewCursors([])
      setShowLoading(false)
      setValueTemp(undefined)
      setFirst(false)
      handleWS()
    }
  }, [wsUrl])

  // 发送编辑的内容
  useEffect(() => {
    if (showLoading) {
      // 协同者在操作，只接收数据
      return
    }
    if (websocket?.readyState === 1) {
      try {
        if (!first) return
        const data = content ? textToBase64(content) : ''
        websocket?.send(JSON.stringify({
          type: "write",
          data: data
        }))
        if (!RTSave) {
          dispatch(updateRTSave({ RTSave: true }))
        }
      } catch (error) {
      }
    }
    if (content) {
      setEditing(true)
      if (contentTimer) {
        clearTimeout(contentTimer)
      }
      const _contentTimer = setTimeout(() => {
        setEditing(false)
      }, 5000);
      setContentTimer(_contentTimer)
    }
  }, [content])

  useEffect(() => {
    if (RTSave) {
      if (saveTimer) {
        clearTimeout(saveTimer)
      }
      const _saveTimer = setTimeout(() => {
        dispatch(updateRTSave({ RTSave: false }))
      }, 3000);
      setSaveTimer(_saveTimer)
    }
  }, [RTSave])

  // useEffect(() => {
  //   if (!editing) {
  //     const _cursors = cursors?.filter((v: any) => v?.key !== userKey)
  //     if (editorRef?.current && _cursors?.length && valueTemp !== undefined) {
  //       try {
  //         editorRef?.current?.setValue(valueTemp)
  //         if (lastPosition) {
  //           const position = new monacoRef.current.Position(lastPosition?.lineNumber, lastPosition?.column);
  //           editorRef?.current?.setPosition(position);
  //         }
  //       } catch (error) {

  //       }
  //     }
  //   }
  // }, [editing, valueTemp, editorRef?.current, cursors?.length])

  // useEffect(() => {
  //   // 在锁定的情况下，更新数据
  //   if (showLoading && newCursors?.length && editorRef?.current && valueTemp !== undefined) {
  //     try {
  //       editorRef?.current?.setValue(valueTemp)
  //       if (lastPosition) {
  //         const position = new monacoRef.current.Position(lastPosition?.lineNumber, lastPosition?.column);
  //         editorRef?.current?.setPosition(position);
  //       }
  //     } catch (error) {

  //     }
  //   }
  // }, [showLoading, valueTemp])

  useEffect(() => {
    if (valueTemp !== undefined && editorRef?.current && !first) {
      setFirst(true)
      editorRef?.current?.setValue(valueTemp)
      const position = new monacoRef.current.Position(1, 1);
      editorRef?.current?.setPosition(position);
    }
  }, [valueTemp, first, editorRef?.current])

  // 处理上下左右键
  const handleKeyDown = (editor: any, monaco: any) => {
    editor.onKeyDown((e: any) => {
      const position = editor.getPosition()
      let lineNumber = position.lineNumber
      let column = position.column
      if (e.keyCode === monaco.KeyCode?.UpArrow) {
        lineNumber = lineNumber === 1 ? 1 : lineNumber - 1
      } else if (e.keyCode === monaco.KeyCode?.DownArrow) {
        lineNumber = lineNumber + 1
      } else if (e.keyCode === monaco.keyCode?.LeftArrow) {
        column = column === 1 ? 1 : column - 1
      } else if (e.keyCode === monaco.keyCode?.RightArrow) {
        column = column + 1
      } else {
        return
      }
      const _position = new monaco.Position(lineNumber, column);
      setFourPosition(_position)
    });
  }

  useEffect(() => {
    if (fourPosition) {
      handleClickCursor(fourPosition)
    }
  }, [fourPosition])

  // 光标位置发送
  const handleClickCursor = (pos?: any) => {
    if (!document.activeElement?.className.includes('monaco-mouse-cursor-text')) return
    const position = pos?.lineNumber ? pos : editorRef?.current?.getPosition()
    if (!position) return
    if (websocket?.readyState === 1) {
      try {
        websocket?.send(JSON.stringify({
          type: "cursor",
          data: {
            lineNumber: position?.lineNumber,
            column: position?.column
          }
        }))
        setLastPosition(position)
      } catch (error) {
      }
    }
  };

  useEffect(() => {
    editorDivRef?.current?.addEventListener('click', handleClickCursor);
    return () => {
      editorDivRef?.current?.removeEventListener('click', handleClickCursor);
    };
  }, [editorDivRef, editorRef, websocket]);

  useEffect(() => {
    return () => {
      if (websocket && websocket?.readyState === 1) {
        websocket?.close(4999)
      }
    }
  }, [websocket])

  // 发送光标位置
  useEffect(() => {
    if (editing) {
      handleClickCursor()
    }
  }, [editing, editorRef.current?.getPosition()])

  // 处理协同人的编辑位置
  useEffect(() => {
    let timer: any
    if (!editing) {
      timer = setTimeout(() => {
        const _d = cursors.filter((v: any) => v?.key !== userKey).map((value: any) => {
          const v = value?.cursor
          const column = v?.column === 1 ? v?.column : v?.column - 1
          return {
            range: new monacoRef.current.Range(v?.lineNumber, column, v?.lineNumber, column + 1),
            options: {
              isWholeLine: false,
              inlineClassName: 'highlighted-cursor'
            }
          }
        })
        const newDecorations = editorRef.current?.deltaDecorations(decorations, _d);
        setDecorations(newDecorations || []);
        clearTimeout(timer)
        setTimeout(() => {
          const nodeList = document.querySelectorAll('.highlighted-cursor')
          nodeList.forEach((element: any, index: number) => {
            const _index = index % colors.length
            element.style.borderRightColor = colors[_index]
            element.style.setProperty('--my-variable', colors[_index])
          })
        }, 100);
      }, 100);
    }
    return () => {
      timer && clearTimeout(timer)
    }
  }, [editing, cursors])

  // 协同者在操作
  useEffect(() => {
    if (newCursors?.length) {
      setShowLoading(true)
      // loadingTimer && clearTimeout(loadingTimer)
      // const _loadingTimer = setTimeout(() => {
      //   setShowLoading(false)
      // }, 10000);
      // setLoadingTimer(_loadingTimer)
    } else {
      if (showLoading) {
        // 更新数据
        try {
          editorRef?.current?.setValue(valueTemp)
          if (lastPosition) {
            const position = new monacoRef.current.Position(lastPosition?.lineNumber, lastPosition?.column);
            editorRef?.current?.setPosition(position);
          }
        } catch (error) {

        }
      }
      setShowLoading(false)
    }
  }, [JSON.stringify(newCursors)])

  // 更新最新内容
  useEffect(() => {
    if (updateValue) {
      setUpdateValue(false)
      editorRef?.current?.setValue(valueTemp)
    }
  }, [updateValue])

  // 右键菜单增加粘贴图片的功能
  useEffect(() => {
    if (editorRef?.current && onPaste) {
      const editor = editorRef.current;
      // 自定义粘贴图片操作
      editor.addAction({
        id: 'pasteImage',
        label: '粘贴图片',
        contextMenuGroupId: 'navigation-9',
        contextMenuOrder: 1,
        run: function () {
          // 执行粘贴图片的操作
          onPaste()
        },
      })
    }
  }, [editorRef?.current])

  useImperativeHandle(ref, () => ({
    // 清屏
    clear: () => {
      editorRef?.current?.setValue('')
      setValue('')
      setContent('')
    },
    // 更新内容
    update: () => {
      const _timer = setTimeout(() => {
        setUpdateValue(true)
        clearTimeout(_timer)
      }, 500);
    },
    // 滚动到顶部
    scrollToTop: () => {
      editorRef?.current?.setScrollPosition({scrollTop: 0})
    }
  }))

  // 编辑器是否加载完成
  const [mounted, setMounted] = useState(false)
  const examRecordCode = getQueryStringValue('examRecordCode') || ''

  useEffect(() => {
    if (mounted && disableCopyPaste && editorRef?.current && monacoRef?.current) {
      editorRef.current?.addCommand(monacoRef?.current.KeyMod.CtrlCmd | monacoRef?.current.KeyCode.KeyC, () => {
        repoExamLog({
          action: 'COPY',
          examRecordCode
        }).catch(() => { })
      })
      editorRef.current?.addCommand(monacoRef?.current.KeyMod.CtrlCmd | monacoRef?.current.KeyCode.KeyV, () => {
        repoExamLog({
          action: 'PASTE',
          examRecordCode
        }).catch(() => { })
      })
      editorRef.current?.addCommand(monacoRef?.current.KeyMod.CtrlCmd | monacoRef?.current.KeyCode.KeyX, () => {})
    }
  }, [disableCopyPaste, mounted])

  return (
    <>
      <div className='editorDiv' ref={editorDivRef}>
        <Editor
          theme="vs-dark"
          {...props}
          value={value}
          language={language ? LanguageEnums[language as keyof typeof LanguageEnums] : 'plaintext'}
          onChange={(v: any) => {
            setContent(v)
            onChange && onChange(v)
          }}
          onMount={(editor: any, monaco: any) => {
            handleKeyDown(editor, monaco)
            editorRef.current = editor;
            monacoRef.current = monaco
            // 格式化
            editor.getAction('editor.action.formatDocument')?.run()
            onMountEditor && onMountEditor(editor, monaco)
            setMounted(true)
          }}
          options={{
            folding: true,
            lineDecorationsWidth: 1,
            wordWrap: 'on',
            // lineNumbersMinChars: 2
          }}
        />
      </div>
      {
        showLoading ? (
          <div className='codeEditor-loading'>
            <SvgIcon iconClass='lock' />
            <span>当前内容有人正在编辑噢，请稍后再来~</span>
          </div>
        ) : <></>
      }
    </>
  );
};

CodeEditor = forwardRef(CodeEditor as any)

export default CodeEditor