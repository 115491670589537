interface ConfigType {
  ptyApiURL: string | undefined //环境相关接口地址
  ptyWSURL: string | undefined //环境相关websocket地址
  osApiURL: string | undefined //开源社区接口地址
  osURL: string //开源社区的地址
  osWSURL: string
  baseURL: string //请求接口
  wsURL: string //websocket
  gptURL: string // gpt接口
  ojURL: string //判定服务
  ojWSURL: string //判定服务
  reportURL: string | undefined  // 数据上报服务
  reportWS: string | undefined  // 数据上报服务
  codePlayerURL: string | undefined  // codePlayer运行服务
  requestTimeOut: number //请求超时时间
}

const config: ConfigType = {
  ptyApiURL: process.env.REACT_APP_API_URL_PTY,
  ptyWSURL: process.env.REACT_APP_WS_URL_PTY,
  osApiURL: process.env.REACT_APP_API_URL_OS,
  osWSURL: process.env.REACT_APP_WS_URL_OS || '',
  osURL: process.env.REACT_APP_OS_URL || '',
  wsURL: process.env.REACT_APP_WS_URL || '',
  baseURL: process.env.REACT_APP_API_URL || '',
  gptURL: process.env.REACT_APP_WS_URL || '',
  ojURL: process.env.REACT_APP_OJ_URL || '',
  ojWSURL: process.env.REACT_APP_WS_OJ_URL || '',
  reportURL: process.env.REACT_APP_REPORT_URL,
  reportWS: process.env.REACT_APP_REPORT_WS,
  codePlayerURL: process.env.REACT_APP_CodePlayer_URL,
  requestTimeOut: 1000 * 60 * 5
}

export default config
