import React, { useEffect, useRef } from 'react'
import PDFObject from 'pdfobject';
import './index.less'

type PDFReaderProps = {
  url?: string //PDF文件的URL
}

const PDFReader: React.FC<PDFReaderProps> = (props) => {
  const { url } = props

  const pdfRef = useRef<any>()

  // 加载PDF内容
  useEffect(() => {
    if (!url) return
    PDFObject.embed(url, pdfRef?.current)
  }, [url])

  return (
    <div className='pdf-reader' ref={pdfRef}>
    </div>
  )
}

export default PDFReader;