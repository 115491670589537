import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import { Checkbox, Modal, Pagination, Popover, Table, Input, InputRef, message, Tooltip } from 'antd'
import SvgIcon from '../../../components/SvgIcon'
import { ColumnsType } from 'antd/es/table'

type ActivitiesProps = {
  activities?: any
  activitiesConfig?: any
  open?: boolean
  onClose: () => void
  onEdit: (value: any, type?: any) => void
  onBaseChange: (value: any, mark?: boolean) => void
  onDelete: (value: any) => void
}

interface TabType {
  key?: string,
  activity?: string,
  type?: string,
  score?: number
  [other: string]: any
}

const Activities: React.FC<ActivitiesProps> = (props) => {

  const { activities, activitiesConfig, open, onClose, onEdit, onBaseChange, onDelete } = props

  const inputRef = useRef<InputRef>(null)

  // 评分模式
  const [mode, setMode] = useState(activitiesConfig?.mark || false)

  // 折叠
  const [fold, setFold] = useState(activitiesConfig?.mark || false)

  // 是否在编辑几个分数
  const [isEditor, setIsEditor] = useState(false)

  // 总分数
  const [totalScore, setTotalScore] = useState(0)

  // 表格数据
  const [tabData, setTabData] = useState<any>([])

  // 条数
  const [pageSize, setPageSize] = useState(5)

  // 当前页
  const [current, setCurrent] = useState(1)

  // 帮助说明文字
  const content = (
    <div className='ylzcc-popover-help-tit'>
      该分数为小节内所有活动分数总和。
    </div>
  )

  // 及格分数
  const [passMask, setPassMask] = useState(0)

  // 表头
  const tabHeader: ColumnsType<TabType> = [
    {
      title: '活动名称',
      dataIndex: 'activity',
      key: 'activity',
      align: 'center',
      width: '40%',
      render: (_: string, record: TabType) => {
        return (
          <Tooltip placement="topLeft" title={record?.title?.replace(/<img[^>]+>/g, "【图片】")?.replace(/<table[^>]+>/g, "【表格】")?.replace(/<[^>]*>/g, "")} arrow={false} color={'white'} overlayInnerStyle={{ color: '#333333' }} overlayStyle={{ border: '1px solid #e5e5e5', borderRadius: '2px', boxShadow: '2px 2px 4px 0px rgba(92,117,153,0.10)' }}>
            <div className='tab-activity'>{record?.title?.replace(/<img[^>]+>/g, "【图片】")?.replace(/<table[^>]+>/g, "【表格】")?.replace(/<[^>]*>/g, "") || '--'}</div>
          </Tooltip>
        )
      }
    },
    {
      title: '活动类型',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '18%',
      render: (_: string, record: TabType) => {
        return (
          <span>
            {record?.type === 'question' ? '选择题' : ''}
            {record?.type === 'experiment' ? '示例演示' : ''}
            {record?.type === 'validate' ? '编码测验' : ''}
          </span>
        )
      }
    },
    {
      title: '分数',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      width: '18%',
      render: (_: number, record: any) => {
        if (!mode) {
          return <>--</>
        }
        if (record?.edit) {
          return (
            <div className='tab-score'>
              <Input bordered={false} maxLength={3} id={`input-${record?.id}`} value={record?.score} onPressEnter={(e: any) => {
                if (e.target.value === '' || e.target.value === '0') {
                  onEdit({ ...record, score: '1' }, 1)
                  onChangeEdit(record, false)
                  return
                }
                onEdit({ ...record, score: e.target.value }, 1)
                onChangeEdit(record, false)
              }}
                onFocus={() => {
                  if (isEditor) {
                    inputRef?.current?.blur()
                  }
                }}
                onChange={(e) => {
                  // console.log(e.target.value.length)             
                  if (/^\d+$/.test(e.target.value) || e.target.value === '') {
                    onChangeScore(record, e.target.value)
                  }
                }}
                onBlur={(e: any) => {
                  if (e.target.value === '' || e.target.value === '0') {
                    onEdit({ ...record, score: '1' }, 1)
                    onChangeEdit(record, false)
                    return
                  }
                  onEdit({ ...record, score: e.target.value }, 1)
                  onChangeEdit(record, false)
                }
                }
              />
            </div>
          )
        }
        return (
          <div className='tab-score'>
            <span>{_}</span>
            <span className='tab-score-svg' onClick={() => {
              if (!isEditor) {
                onChangeEdit(record, true)
              }
            }}>
              <SvgIcon iconClass='editorr' fontSize='14px' />
            </span>
          </div>
        )
      }
    },
    {
      title: '操作',
      dataIndex: 'editor',
      key: 'editor',
      align: 'center',
      width: '24%',
      render: (_: any, record: any) => {
        return (
          <div className='tab-editor'>
            <span onClick={() => onEdit(record)}>编辑</span>
            <span onClick={() => {
              Modal.confirm({
                title: '确认删除？',
                content: '删除活动后，编辑区的活动代码会被同步删除。',
                centered: true,
                okText: '我再想想',
                cancelText: '确认删除',
                onCancel: () => {
                  onDelete(record)
                }
              })
            }}>删除</span>
          </div>
        )
      }
    },
  ]


  useEffect(() => {
    tabData?.forEach((item: any) => {
      if (item?.edit) {
        // inputRef?.current?.blur()
        const edit = document.getElementById(`input-${item.id}`)
        edit?.focus()
      }
    })
  }, [tabData])

  // change => 分数编辑模式
  const onChangeEdit = (record: any, edit: any) => {
    const _activities = [...activities]
    const li: any = _activities.find((item) => {
      return record?.id === item.id
    })
    li.edit = edit
    setTabData(_activities)
  }

  //改变分数
  const onChangeScore = (record: any, value: string) => {
    const _activities = [...activities]
    const li: any = _activities.find((item) => {
      return record?.id === item.id
    })
    li.score = Number(value)
    setTabData(_activities)
  }

  // change => 评分模式
  const onCheckboxChange = (e: any) => {
    const checked = e.target.checked
    if (!checked) {
      Modal.confirm({
        title: '确认取消？',
        content: '取消评分模式，设置的评分内容将会被全部清空。',
        centered: true,
        okText: '我再想想',
        cancelText: '确认取消',
        onCancel: () => {
          setMode(false)
          setFold(false)
          onBaseChange({
            mark: false,
            passMask: 0
          }, true)
        }
      })
      return
    }
    setMode(checked)
    setFold(true)
    onBaseChange({
      mark: true,
      passMask
    }, true)
  }

  // 及格分数线
  const onPassMask = () => {
    if (passMask >= totalScore || passMask < 0) {
      setIsWarn(true)
      inputRef.current!.focus()
      return
    }
    setIsWarn(false)
    setIsEditor(false)
    onBaseChange({
      passMask
    })
  }

  useEffect(() => {
    setMode(activitiesConfig?.mark || false)
    setFold(activitiesConfig?.mark || false)
    setPassMask(activitiesConfig?.passMask || 0)
  }, [activitiesConfig])

  useEffect(() => {
    // 最大页码
    const max = Math.ceil(activities.length / pageSize)
    if (current > max) {
      setCurrent(max)
    } else {
      setCurrent(current || 1)
    }
    setTabData([...activities])
    const scores = activities.map((v: any) => Number(v?.score))
    const totalScore = scores.reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0)
    setTotalScore(totalScore)
  }, [activities])

  // 编辑的时候聚焦输入框
  useEffect(() => {
    if (isEditor) {
      inputRef?.current?.focus()
    }
  }, [isEditor])

  useEffect(() => {
    // console.log('---',typeof totalScore, typeof passMask)
    // console.log('---',totalScore, passMask)
    if (open && mode && passMask && totalScore <= passMask) {
      setIsEditor(true)
      setIsWarn(true)
    }
  }, [totalScore, passMask])


  // 及格分数警告
  const [isWarn, setIsWarn] = useState(false)

  return (
    <>
      <Modal
        open={open}
        wrapClassName={'ylzcc-activity-modal'}
        onCancel={onClose}
        footer={false}
        closable={false}
        centered={true}
        width={'728px'}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className='ylzcc-activity-modal-head'>
          <span>活动管理</span>
          <div className='ylzcc-activity-modal-head-close' onClick={onClose}>
            <SvgIcon iconClass={'closee'} fontSize='24px' />
          </div>
        </div>
        {/* <div className='ylzcc-activity-modal-mark'>
          <div className='ylzcc-activity-modal-mark-choice'>
            <Checkbox checked={mode} onChange={onCheckboxChange}><span className='ylzcc-activity-modal-mark-choice-ti'>评分模式</span></Checkbox>
            <SvgIcon iconClass={fold ? 'mark-top' : 'mark-bot'} fontSize='16px' className='ylzcc-activity-modal-mark-choice-left' onClick={() => { setFold(!fold) }} />
          </div>
        </div> */}

        {
          fold && (
            <div className='ylzcc-activity-modal-score'>
              <div className='ylzcc-activity-modal-score-one'>
                <span className='title'>总分数</span>
                <span className='number'>{totalScore}</span>
                <Popover arrow={false} placement="bottom" content={content} overlayClassName='ylzcc-popover-help'>
                  <span className='icon icon-help'>
                    <SvgIcon iconClass='help' fontSize='16px' />
                  </span>
                </Popover>
              </div>
              <div className='ylzcc-activity-modal-score-two'>
                <span className='title'>及格分数</span>
                <span className='number'>
                  {
                    isEditor ? <Input className='input' ref={inputRef} value={passMask} onChange={(e) => {
                      if (/^\d+$/.test(e.target.value)) {
                        setPassMask(Number(e.target.value))
                      } else if (e.target.value === '') {
                        setPassMask(Number(e.target.value))
                      }
                    }} onPressEnter={onPassMask} onBlur={(e) => { onPassMask() }} /> :
                      <>{passMask}</>
                  }
                  {
                    isWarn ? <div className='number-tip'>
                      <SvgIcon iconClass='tipred' fontSize='12px' />
                      <p className='name'>及格分数不能超过总分数</p>
                    </div> : <></>
                  }
                </span>
                {
                  isEditor ? <></> :
                    <span className='icon' onClick={() => { setIsEditor(true) }}>
                      <SvgIcon iconClass='editorr' fontSize='16px' />
                    </span>
                }
              </div>
              <div className='ylzcc-activity-modal-score-three'>
                <SvgIcon iconClass='tip1' fontSize='16px' />
                <span className='title'>完成后显示评分结果</span>
              </div>
            </div>
          )
        }

        <div className='ylzcc-activity-modal-tablee'>
          <Table
            dataSource={tabData?.filter((v: any, index: any) => index < current * pageSize && index >= (current - 1) * pageSize)}
            columns={tabHeader}
            bordered
            pagination={false}
          />
        </div>

        <div className='ylzcc-activity-modal-page'>
          <Pagination
            current={current}
            total={tabData?.length}
            showSizeChanger
            showQuickJumper={false}
            onChange={(page) => { setCurrent(page) }}
            pageSize={pageSize}
            onShowSizeChange={(current: number, size: number) => { setPageSize(size) }}
            size={'small'}
          />
          <button className='btn' onClick={() => {
            // if ((passMask < totalScore && passMask >= 0) || !mode) {
            onClose?.()
            // }
          }}>关闭</button>
        </div>
      </Modal>
    </>
  )
}


export default Activities
