import { Checkbox, Form, Input, Modal, Select } from 'antd'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { valifyEmpty } from './verifyTools'
import SvgIcon from '../../../components/SvgIcon'
import dayjs from 'dayjs'
import CodeEditor from '../../../components/CodeEditor'

const { TextArea } = Input

type VerifyOtherProps = {
  verify: any
  verifyTrue: any
  close: () => void,
  changeTrue: (v: any) => void

}

const VerifyOther = React.forwardRef((props: VerifyOtherProps, ref) => {

  const { verify, close, verifyTrue, changeTrue } = props

  const [form] = Form.useForm()

  //编辑器Ref
  const codeEditorRef = useRef<any>(null)

  //编辑器内容
  const [codeValue, setCodeValue] = useState('')

  // 演示脚本是否显示placeholder
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  const [codeHelpBtn, setCodeHelpBtn] = useState(false)

  // 校验是否正确
  const [verifyTrueOther, setVerifyTrueOther] = useState(verifyTrue)

  useImperativeHandle(ref, () => ({
    validateFields: form.validateFields,
    set: () => {
      form.setFieldValue('code', codeValue)
    },
  }))


  useEffect(() => {
    if (verify) {
      const { code, buttonName, codeResult, requiredCorrect } = verify
      form.setFieldsValue({

        code,
        buttonName,
        codeResult
      })
      setVerifyTrueOther(requiredCorrect)
    } else {
      form.setFieldsValue({
        buttonName: '校验',
        codeResult: 'true'
      })
    }
  }, [verify])

  return (
    <div className='ylzcc-ability tipability'>

      <Form
        name="basic"
        colon={false}
        validateTrigger='onBlur'
        autoComplete="off"
        form={form}
      >
        <div style={{ width: '260px' }}>
          <Form.Item
            label="交互按钮名称"
            name={'buttonName'}
            rules={[
              { required: true, message: '按钮名称不可为空' },
              { min: 2, message: '请至少输入两个字' },
            ]
            }
          >
            <Input showCount maxLength={6} />
          </Form.Item>
        </div>
        <Form.Item
          label={
            <div className='form-item-help'>
              校验交互指令
              <div className='form-item-help-text' onClick={() => { setCodeHelpBtn(true) }}>
                指令示例
              </div>
              <Modal
                open={codeHelpBtn}
                wrapClassName={'ylzcc-modal-item-help'}
                onCancel={() => { setCodeHelpBtn(false) }}
                footer={false}
                // closable={false}
                centered={true}
                width={'554px'}
                destroyOnClose={true}
              >
                <div className='title'>
                  示例
                </div>
                <div className='shili'>
                  假设我们要判断一个文件是否存在，可以使用以下示例来快速判断bash运行结果<br />
                  是否符合预期：<br />
                  &emsp;&emsp;```       <br />
                  &emsp;&emsp;if [ -f "file.txt" ]; <br />
                  &emsp;&emsp;then           <br />
                  &emsp;&emsp;&emsp;&emsp;echo true       <br />
                  &emsp;&emsp;else           <br />
                  &emsp;&emsp;&emsp;&emsp;echo false       <br />
                  &emsp;&emsp;fi       <br />
                  &emsp;&emsp;```<br />
                  这个示例中，我们使用了条件判断语句（if语句）来判断文件是否存在。<br />
                  如果文件存在，则输出"文件存在"；如果文件不存在，则输出"文件不存在"。<br />
                </div>
              </Modal>

            </div>
          }
          validateFirst={true}
          name={'code'}
          rules={[
            { validator: (_, value) => valifyEmpty(_, value, '请输入可执行的校验代码') },
            { required: true, message: '请输入可执行的校验代码' },
          ]
          }
        >
          <div className='demo-code'>
            {
              showPlaceholder && <div className='demo-code-placeholder' onClick={() => { codeEditorRef?.current?.focus() }}>请输入可执行的校验代码</div>
            }
            <CodeEditor language='sh' onMountEditor={(editor: any) => {
              codeEditorRef.current = editor
              // 编辑时设置code内容
              if (!verify) return
              const { code } = verify
              if (code) {
                setShowPlaceholder(false)
                setCodeValue(code)
              }
              codeEditorRef?.current?.setValue(code)
            }} onChange={(value: any) => {
              // 设置演示脚本内容
              setCodeValue(value)
              console.log(value);

              if (value) {
                form.setFieldValue('code', value)
              }
              setShowPlaceholder(false)
            }} />

          </div>
        </Form.Item>

        {/* <Form.Item
          label="提示内容"
          name={'tip'}
          rules={[
            { required: true, message: '请输入提示内容' },
            { min: 1, message: '请至少输入1个字' },
            { validator: (_, value) => valifyEmpty(_, value, '请输入提示内容') }
          ]
          }
        >
          <TextArea
            showCount
            maxLength={500}
            style={{ height: 120, resize: 'none', minHeight: '120px' }}
            placeholder="请输入提示内容"
          />
        </Form.Item> */}
        <Form.Item
          label={
            <div className='form-item-help'>
              校验正确结果

            </div>
          }
          name={'codeResult'}
          rules={[
            { required: true, message: '指令结果不可为空' },
            { min: 1, message: '请至少输入1个字' },
            { validator: (_, value) => valifyEmpty(_, value, '指令结果不可为空') }
          ]
          }
        >
          <TextArea
            showCount
            maxLength={100}
            style={{ height: 76, resize: 'none', minHeight: '76px' }}
            placeholder="请输入指令结果"
          />
        </Form.Item>
        <div className='form-item-check'>
          <div className='form-item-check-checkBox'>
            <Checkbox checked={verifyTrueOther} onChange={(e) => {
              setVerifyTrueOther(e.target.checked)
              changeTrue(e.target.checked)
            }}>
              是否必须校验正确
            </Checkbox>
            {verifyTrueOther && <div className='checkBox-text'>
              <SvgIcon iconClass={'promptIcon'} fontSize='14px' />
              <span style={{ fontSize: '12px', color: '#999', marginLeft: '2px' }}>勾选必答后，学习者必须执行该编码校验才可以进行下一步操作。</span>
            </div>}
          </div>
        </div>
      </Form>
    </div>
  )
})

export default VerifyOther