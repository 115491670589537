import { Button, Checkbox, Form, Input, Modal, Select, TimePicker, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { valifyEmpty, valifyNumber } from './verifyTools'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import CodeEditor from '../../../components/CodeEditor'
import SvgIcon from '../../../components/SvgIcon'
import RichEditor from '../../../components/RichEditor'
import DemoCode from './DemoCode' //演示代码
import { write, read } from '../../../api/modules/fs'
import { base64ToText, textToBase64 } from '../../../utils'
import { RunToolsTypes } from '../../../utils/commonTypes'


dayjs.extend(customParseFormat);

type ExperimentProps = {
    params?: any
    experiment?: Exper,
    category: 'text' | 'video',   //课程类型
    mark: boolean,  // 评分模式
    close: Function,
    finish?: (data: Exper) => void
    template?: any            // 环境信息
}

// 实验
export interface Exper {
    id: string,        //唯一标识
    title: string,     //演示内容
    code?: string,       //演示脚本内容 
    codePath: string,     //演示脚本路劲
    showCode: boolean,     // 是否显示指令
    buttonName: string,     // 按钮名称
    required: boolean,     // 是否必须执行校验
    score: number,     // 分数
    time: string,     //  触发时间
    auxiliary: Array<{     //  辅助活动  可添加提示和校验
        type: "validate",   //校验类型
        title: string,     // 校验描述
        code: string,      // 校验指令
        buttonName: string,    // 校验按钮
        required: boolean,    // 是否必须
        codeResult: string, //指令结果
        triggerTime: number, // 触发时机默认0
        requiredCorrect: boolean,   // 是否必须回答正确
        auxiliary: Array<{    // 提示辅助活动
            type: "tip",
            triggerTime: number,  // 触发时机  默认0
            description: string   // 提示内容
        }> | null
    }> | null,
    runTool: string        // 运行工具
    codeLanguage: string  // 代码语言
}

const Experiment: React.FC<ExperimentProps> = (props) => {

    const [form] = Form.useForm()

    const { experiment, params, category, mark, close, finish, template } = props

    const richEditor = useRef<any>(null)
    const codeEditorRef = useRef<any>(null)

    // 1 表单数据
    // 是否显示指令
    const [isShowCode, setIsShowCode] = useState(false)
    // 是否必须执行实验
    const [verifyTrue, setVerifyTrue] = useState(false)
    // 演示代码是否展开
    const [open, setOpen] = useState(false)
    // 演示内容
    const [defaultTitle, setDefaultTitle] = useState<any>()
    // 演示脚本是否显示placeholder
    const [showPlaceholder, setShowPlaceholder] = useState(true)
    // 演示脚本解释说明--弹框
    const [openCodeModal, setOpenCodeModal] = useState(false)
    // 演示代码解释说明--弹框
    const [openDemoModal, setOpenDemoModal] = useState(false)

    // 上传脚本
    const uploadShell = () => {
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.accept = ".sh,.bash"

        fileInput.addEventListener('change', (event: any) => {
            const file = event?.target?.files[0]
            // 读取文件内容
            const reader = new FileReader();
            reader.onload = function (event: any) {
                const content = event?.target?.result;
                codeEditorRef?.current?.setValue(content)
            };
            reader.readAsText(file);
        })

        fileInput.click()
    }

    // 确定
    const submit = () => {
        const title = richEditor?.current?.getHtml()
        const code = codeEditorRef?.current?.getValue()
        form.setFieldValue('code', code)
        form.setFieldValue('title', title)
        form.validateFields().then(res => {
            const experimentData = res
            // 创建文件夹：名称为演示内容（前5位）+ 唯一活动标识（后5位）/ 创建文件写入内容：演示脚本.sh
            let codePath: any = experiment?.codePath
            if (!codePath) {
                const _id = uuid()
                let _title = experimentData?.title
                _title = _title.replace(/<img[^>]+>/g, "【图片】")
                _title = _title.replace(/<table[^>]+>/g, "【表格】")
                codePath = '活动能力/' + _title?.replace(/<[^>]*>/g, "")?.substring(0, 5) + '-' + _id?.slice(-5) + '/演示脚本.sh'
            }
            write({
                ...params,
                workdir: true,
                file: codePath,
                data: textToBase64(experimentData?.code)
            }).then(() => {
                const data: Exper = {
                    id: experiment?.id ?? '',
                    title: experimentData?.title,
                    codePath,
                    showCode: isShowCode,
                    buttonName: experimentData?.buttonName,
                    score: Number(experimentData?.score) || 0,
                    required: verifyTrue,
                    time: experimentData?.time?.format('HH:mm:ss') || '',
                    auxiliary: null,
                    runTool: experimentData?.runTool || '',
                    codeLanguage: experimentData?.codeLanguage
                }
                finish?.(data)
                close?.()
            })
        }).catch(() => { })
    }

    // 读取演示脚本代码
    const fetchRead = () => {
        if (experiment?.codePath) {
            read({
                ...params,
                workdir: true,
                file: experiment?.codePath
            }).then(res => {
                const data = res.data
                const _data = base64ToText(data)
                codeEditorRef?.current?.setValue(_data)
                form.setFieldValue('code', _data)
            }).catch(() => { })
        } else {
            codeEditorRef?.current?.setValue(experiment?.code)
            form.setFieldValue('code', experiment?.code)
        }
    }

    // 表单数据初始化
    useEffect(() => {
        if (experiment) {
            const { title, buttonName, score, time, showCode, required, runTool, codeLanguage } = experiment
            const datetime = time === '' ? dayjs('00:00:01', 'HH:mm:ss') : dayjs(time, 'HH:mm:ss')

            form.setFieldsValue({
                title,
                buttonName,
                score,
                time: datetime,
                runTool,
                codeLanguage
            })
            setDefaultTitle(title)
            setIsShowCode(showCode)
            setVerifyTrue(required)
            setShowPlaceholder(false)
            return
        }
        form.setFieldsValue({
            title: '',
            code: '',
            buttonName: '执行',
            score: 10,
            time: dayjs('00:00:01', 'HH:mm:ss'),
            runTool: '',
            codeLanguage: undefined
        })
    }, [experiment])

    // 评分模式 -- 必答
    useEffect(() => {
        if (mark) {
            setVerifyTrue(true)
        }
    }, [mark])

    // useEffect(() => {
    //     console.log(form);

    // }, [form])

    // 代码语言
    const runTool = Form?.useWatch('runTool', form)

    if (runTool !== RunToolsTypes.CODEPLAYER) {
        form?.setFieldValue('codeLanguage', undefined)
    }

    return (
        <>
            <div className='ylzcc-form-head'>
                <span>{experiment ? '编辑' : '新增'}示例演示</span>
            </div>
            <Form
                name="basic"
                colon={false}
                validateTrigger='onBlur'
                autoComplete="off"
                form={form}
                labelAlign="right"
            >
                <Form.Item
                    label="演示内容"
                    name={'title'}
                    rules={[
                        { required: true, message: '请输入演示内容' },
                        { validator: (_, value) => valifyEmpty(_, value, '请输入演示内容') }
                    ]
                    }
                >
                    <RichEditor placeholder='请输入本次演示的内容' ref={richEditor} defaultValue={defaultTitle} />
                </Form.Item>

                <Form.Item
                    label={
                        <div className='form-item-help form-item-act'>
                            演示脚本
                            <div className='form-item-help-btn' onClick={() => setOpenCodeModal(true)}>解释说明</div>
                            <div className='form-item-help-btn' onClick={uploadShell}>上传脚本</div>
                            <Modal
                                open={openCodeModal}
                                wrapClassName={'ylzcc-modal-item-help'}
                                onCancel={() => { setOpenCodeModal(false) }}
                                footer={false}
                                centered={true}
                                width={'580px'}
                                destroyOnClose={true}
                            >
                                <div className='explain'>
                                    <p>1、【演示示例】活动能力适用于教学演示场景</p>
                                    <p>2、【演示内容】为教学演示的内容</p>
                                    <p>3、【演示脚本】为教学演示的示例代码，为【演示内容】对应的可执行的shell脚本</p>
                                    <p>4、【示例演示】模块下，点击【执行】按钮，则在shell中执行【演示脚本】</p>
                                    <p>5、【演示脚本】可调用【演示代码】进行演示实验</p>
                                </div>
                            </Modal>
                        </div>
                    }
                    name={'code'}
                    validateFirst={true}
                    rules={[
                        { required: true, message: '请输入演示脚本' },
                        { validator: (_, value) => valifyEmpty(_, value, '请输入演示脚本') },
                    ]

                    }
                >
                    <div className='demo-code'>
                        {
                            showPlaceholder && <div className='demo-code-placeholder' onClick={() => { codeEditorRef?.current?.focus() }}>点击按钮时，在shell执行演示脚本</div>
                        }
                        <CodeEditor language='sh' onMountEditor={(editor: any) => {
                            codeEditorRef.current = editor
                            // 编辑时设置code内容
                            if (!experiment) return
                            // 读取内容
                            fetchRead()
                        }} onChange={(value: any) => {
                            setShowPlaceholder(false)
                            // 设置演示脚本内容
                            if (value) {
                                form.setFieldValue('code', value)
                            }
                        }} />
                    </div>
                </Form.Item>

                <Form.Item
                    label={
                        <div className='form-item-help form-item-act' style={{ paddingLeft: '10px' }}>
                            演示代码
                            <div className='form-item-help-btn' onClick={() => setOpenDemoModal(true)}>解释说明</div>
                            {
                                open && <div className='form-item-help-btn' onClick={() => setOpen(false)}>收起</div>
                            }
                            <Modal
                                open={openDemoModal}
                                wrapClassName={'ylzcc-modal-item-help'}
                                onCancel={() => { setOpenDemoModal(false) }}
                                footer={false}
                                centered={true}
                                width={'460px'}
                                destroyOnClose={true}
                            >
                                <div className='explain'>
                                    <p>1、【演示代码】可与【演示脚本】配套使用</p>
                                    <p>2、【演示代码】和资源管理一致，可上传/新建文档、项目文件</p>
                                    <p>3、【演示脚本】可调用【演示代码】进行演示实验</p>
                                </div>
                            </Modal>
                        </div>
                    }
                >
                    {
                        open ? <DemoCode /> : <div className='demo-open' onClick={() => setOpen(true)}>展开</div>
                    }
                </Form.Item>

                <Form.Item
                    label="运行工具"
                    name={'runTool'}
                    rules={[
                        { required: true, message: '请选择运行工具', validateTrigger: "onSubmit", whitespace: true },
                    ]}
                    validateFirst
                >
                    <Checkbox checked={runTool === RunToolsTypes.SHELL} onChange={(e) => {
                        if (e.target.checked && !template?.experEnv) {
                            message.destroy()
                            message.warning('请先在小节编辑中设置环境')
                            return
                        }
                        form?.setFieldValue('runTool', e.target.checked ? RunToolsTypes.SHELL : '')
                    }}>shell</Checkbox>
                    <Checkbox checked={runTool === RunToolsTypes.CODEPLAYER} onChange={(e) => {
                        form?.setFieldValue('runTool', e.target.checked ? RunToolsTypes.CODEPLAYER : '')
                    }}>codeplayer</Checkbox>
                </Form.Item>

                {
                    runTool === RunToolsTypes.CODEPLAYER ? <Form.Item
                        label="代码语言"
                        name={'codeLanguage'}
                        rules={[
                            { required: true, message: '请选择代码语言', validateTrigger: "onSubmit", whitespace: true },
                        ]}
                        validateFirst
                    >
                        <Select
                            style={{width: '180px'}}
                            placeholder="请选择代码语言"
                            // fieldNames={{ label: 'tips', value: 'code' }}
                            options={[
                                {
                                    label: 'python',
                                    value: 'python'
                                },
                                {
                                    label: 'c',
                                    value: 'c'
                                },
                                {
                                    label: 'c++',
                                    value: 'cpp'
                                },
                                {
                                    label: 'java',
                                    value: 'java'
                                },
                                {
                                    label: 'c#',
                                    value: 'csharp'
                                },
                                {
                                    label: 'javascript',
                                    value: 'javascript'
                                },
                                {
                                    label: 'typescript',
                                    value: 'typescript'
                                },
                                {
                                    label: 'vb',
                                    value: 'vb'
                                },
                                {
                                    label: 'php',
                                    value: 'php'
                                },
                                {
                                    label: 'golang',
                                    value: 'golang'
                                },
                                {
                                    label: 'rust',
                                    value: 'rust'
                                },
                                {
                                    label: 'html',
                                    value: 'html'
                                },
                            ]}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                        />
                    </Form.Item> : <></>
                }

                <div style={{ width: '260px' }}>
                    <Form.Item
                        label="按钮名称"
                        name={'buttonName'}
                        rules={[
                            { required: true, message: '按钮名称不可为空' },
                            { min: 2, message: '请至少输入2个字' },
                        ]
                        }
                    >
                        <Input showCount maxLength={6} />
                    </Form.Item>
                </div>

                {
                    mark ? <div style={{ width: '260px' }} className='form-item-score'>
                        <Form.Item
                            label="题目分值"
                            name={'score'}
                            validateFirst={true}
                            rules={[
                                { required: true, message: '请输入题目分值' },
                                { validator: valifyNumber }
                            ]
                            }
                        >
                            <Input placeholder="请输入题目分值" />
                        </Form.Item>
                    </div> : <></>
                }

                {
                    category === 'text' ? <></> :
                        <div style={{ width: '260px' }}>
                            <Form.Item
                                label="触发时间"
                                name={'time'}
                                rules={[
                                    { required: true, message: '请输入触发时间' },
                                    // { validator: verifyTime}
                                ]
                                }
                            >
                                <TimePicker />
                            </Form.Item>
                        </div>
                }

                <div className='form-item-check'>
                    <div>
                        <Checkbox checked={isShowCode} onChange={(e) => { setIsShowCode(e.target.checked) }}>
                            显示脚本
                        </Checkbox>
                        {
                            isShowCode && (
                                <div className='form-item-check-tip'>
                                    <SvgIcon iconClass='zhushi' />
                                    <span>勾选显示脚本后，演示脚本对学习者可见。</span>
                                </div>
                            )
                        }
                    </div>
                    <div>
                        <Checkbox checked={verifyTrue} onChange={(e) => { setVerifyTrue(e.target.checked) }} disabled={mark}>
                            是否必答
                        </Checkbox>
                        {
                            verifyTrue && (
                                <div className='form-item-check-tip'>
                                    <SvgIcon iconClass='zhushi' />
                                    <span>勾选必答后，学习者必须执行该演示脚本才可以进行下一步操作。</span>
                                </div>
                            )
                        }
                    </div>
                </div>

                <Form.Item>
                    <div className='form-item-button'>
                        <Button className='form-item-button-can' onClick={() => { close?.() }}>取消</Button>
                        <Button className='form-item-button-en' type='primary' onClick={submit}>确定</Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    )
}

export default Experiment