import type { LibroModel } from '@difizen/libro-core';
import {
  LibroToolbarArea,
  LibroView,
  NotebookCommands,
  ExecutableCellView,
} from '@difizen/libro-core';
import type { CommandRegistry } from '@difizen/mana-app';
import {
  CommandContribution,
  inject,
  ModalService,
  singleton,
} from '@difizen/mana-app';
import { CloudLabLibroService } from './cloud-lab-libro-service';
import { LibroCellView, LibroJupyterModel } from '@difizen/libro-jupyter';
import { Modal } from 'antd';



@singleton({ contrib: CommandContribution })
export class CloudlabLibroCommandContribution implements CommandContribution {

  @inject(ModalService) protected readonly modalService: ModalService;
  @inject(CloudLabLibroService) protected cloudLabLibroService: CloudLabLibroService;

  registerCommands(command: CommandRegistry): void {
    command.registerHandler(NotebookCommands['SideToolbarRunSelect'].id, {
        execute: () => {
        },
        isVisible: (commandRegister,cell, libro, path) => {
            if (
                !cell ||
                !libro ||
                !ExecutableCellView.is(cell) ||
                !(libro instanceof LibroView)
            ) {
                return false;
            }
            return (
                (libro?.model as LibroModel).executable &&
                path === LibroToolbarArea.CellRight
            );
        },
        isEnabled: (commandRegister,cell, libro) => {
            if (!libro || !(libro instanceof LibroView)) {
                return false;
            }
            if(this.cloudLabLibroService.globalEnvStatus!==1) {
                return true
            };
            return (
                (libro.model as LibroJupyterModel).kernelConnection !== undefined &&
                (libro.model as LibroJupyterModel).kernelConnecting === false
            );
        },
      });
    command.registerHandler(NotebookCommands['TopToolbarRunSelect'].id, {
        execute: () => {
        },
        isVisible: (commandRegister,cell, libro, path) => {
            if (
                !libro ||
                !(libro instanceof LibroView) ||
                path !== LibroToolbarArea.HeaderCenter
            ) {
                return false;
            }
            return (libro?.model as LibroModel).executable;
        },
        isEnabled: (commandRegister,cell, libro) => {
            if (!libro || !(libro instanceof LibroView)) {
                return false;
            }
            if(this.cloudLabLibroService.globalEnvStatus!==1) {
                return true
            };
            return (
                (libro.model as LibroJupyterModel).kernelConnection !== undefined &&
                (libro.model as LibroJupyterModel).kernelConnecting === false
            );
        },
    });
    command.registerHandler(NotebookCommands['RunCell'].id, {
        execute: async (commandRegister,cell, libro) => {
          if (
            !cell ||
            !libro ||
            !(cell instanceof LibroCellView) ||
            !(libro instanceof LibroView)
          ) {
            return;
          }
          if ((libro.model as LibroModel).executable) {
            libro.runCell(cell);
          }
        },
        isEnabled: (cell, libro) => {
          if (!libro || !(libro instanceof LibroView)||this.cloudLabLibroService.globalEnvStatus!==1) {
            return false;
          }
          return true;
        },
    });
    command.registerHandler(NotebookCommands['RunCell'].id, {
        execute: async (commandRegister,cell, libro) => {
          if (
            !cell ||
            !libro ||
            !(cell instanceof LibroCellView) ||
            !(libro instanceof LibroView)
          ) {
            return;
          }
          if ((libro.model as LibroModel).executable) {
            libro.runCell(cell);
          }
        },
        isEnabled: (cell, libro) => {
          if (!libro || !(libro instanceof LibroView)||this.cloudLabLibroService.globalEnvStatus!==1) {
            return false;
          }
          return true;
        },
    });
    command.registerHandler(NotebookCommands['RunCellAndInsertBelow'].id, {
        execute: async (commandRegister,cell, libro) => {
          if (
            !cell ||
            !libro ||
            !(cell instanceof LibroCellView) ||
            !(libro instanceof LibroView)
          ) {
            return;
          }
          if ((libro.model as LibroModel).executable) {
            libro.runCellandInsertBelow(cell);
          }
        },
        isEnabled: (cell, libro) => {
          if (!libro || !(libro instanceof LibroView)||this.cloudLabLibroService.globalEnvStatus!==1) {
            return false;
          }
          return true;
        },
    });
    command.registerHandler(NotebookCommands['RunCellAndSelectNext'].id, {
        execute: async (commandRegister,cell, libro) => {
          if (
            !cell ||
            !libro ||
            !(cell instanceof LibroCellView) ||
            !(libro instanceof LibroView)
          ) {
            return;
          }
          if ((libro.model as LibroModel).executable) {
            libro.runCellandSelectNext(cell);
          }
        },
        isEnabled: (cell, libro) => {
          if (!libro || !(libro instanceof LibroView)||this.cloudLabLibroService.globalEnvStatus!==1) {
            return false;
          }
          return true;
        },
    });
  }
}
