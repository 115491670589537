import type { IContentsModel, INotebookContent, NotebookOption } from '@difizen/libro-jupyter';
import { ContentContribution } from '@difizen/libro-jupyter';
import { Deferred, getOrigin, singleton } from '@difizen/mana-app';
import { base64ToText, isJsonString } from '../../utils';
import { CloudLabLibroModel } from './libro-model';
// 处理内容
const handleText = (data: any,userKey?:string) => {
  let notebookContent:INotebookContent = {
    cells: [],
    metadata: {},
    nbformat: 4,
    nbformat_minor: 5,
  };
  if (isJsonString(data)) {
    const json = JSON.parse(data)
    const type = json?.type
    const _data = json?.data
    if (type === 'read' && _data) {
      const mdText = base64ToText(_data);
      notebookContent = JSON.parse(mdText)
    }
  }
  return notebookContent;
}
@singleton({ contrib: ContentContribution })
export class CloudLabLibroContentContribution implements ContentContribution {
  canHandle = () => {
    return 10;
  };

  async loadContent(options: NotebookOption, model: CloudLabLibroModel) {
    const jupyterModel = getOrigin(model) as CloudLabLibroModel;
    const ws = new WebSocket(options.wsUrl);
    jupyterModel.ws = ws;
    jupyterModel.wsDefer.resolve()
    const loadContentDefer = new Deferred<void>();

    let notebookContent:INotebookContent = {
      cells: [],
      metadata: {},
      nbformat: 4,
      nbformat_minor: 5,
    };
    jupyterModel.ws.onmessage = (res)=> {
      const { data } = res
      notebookContent = handleText(data);
      loadContentDefer.resolve()
    }
    let currentFileContents:IContentsModel = {
      name:options.name,
      path:options.wsUrl.replace(/^wss:\/\//, ''),
      type:'notebook',
      writable:true,
      created:'libro',
      last_modified:'libro',
      content:notebookContent
    };
    currentFileContents.content.nbformat_minor = 5;
    jupyterModel.currentFileContents = currentFileContents;
    jupyterModel.filePath = currentFileContents.path;
    jupyterModel.id = currentFileContents.path;
    jupyterModel.lastModified = jupyterModel.currentFileContents.last_modified;
    if (jupyterModel.executable) {
      jupyterModel.startKernelConnection();
    }
    await loadContentDefer.promise;
    return notebookContent;
  }
}
