const utils = {
    // 构建树结构
    tree: (nodes: any[], parent?: string): Array<any> => {
        try {
            const items = new Array<any>()
            const children = nodes?.filter(node => {
                if (parent) {
                    return node?.path?.startsWith(parent) && !(node?.path?.replace(`${parent}/`, ''))?.includes('/')
                }
                return node?.path === node?.title
            })?.sort((a, b) => a.index - b.index)
            nodes = nodes.filter((it: any) => children.filter((i: any) => i.path === it.path).length === 0)
            for (let child of children) {
                const _children = utils.tree(nodes, child.path)
                const _node: any = {
                    ...child,
                    children: _children
                }
                if (0 === _children?.length) {
                    delete _node?.children
                }
                items?.push(_node)
            }
            return items
        } catch (error) {
            return []
        }
    },

    // 深拷贝
    clone: (source: any) => {
        return JSON.parse(JSON.stringify(source))
    },

    // query参数
    query: (url: string) => {
        const query: any = {}
        const str = url?.split("?")[1]
        str?.split("&")?.forEach((pair: string) => {
            try {
                const [key, value] = pair.split('=')
                query[key] = decodeURIComponent(value ?? '')
            } catch (error) {
                // console.error(utils.now(), 'parase query error', error)
            }
        })
        return query
    },

    // 判断类型，设置拖拽规则
    judge: (type: string) => {
        switch (type) {
            case 'stage':
                return ['stage', 'chapter']
            case 'chapter':
                return ['chapter', 'text', 'video', 'notebook', '']
            case '':
            case 'text':
            case 'video':
            case 'notebook':
                return ['text', 'video', 'notebook', '']
            default:
                return []
        }
    },


}



export default utils
