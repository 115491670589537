import React, { memo, useState } from 'react'
import './index.less'
import { Checkbox, Tooltip } from 'antd'

import imagesvg from '../../assets/images/toolsImg/1.png'
import AddTools from '../AddTools'

interface PortItemProps {
    image: string             // logo
    name: string              // 工具名称
    port: number              // 端口
    editor?: ((data: any) => void) | null | undefined      // 编辑
    del?: (() => void) | null | undefined          // 删除
    checked: boolean          // 选中
    changeChecked?: (check: boolean) => (void) | null | undefined // 改变选中
    edited?: boolean          // 是否可编辑
    ports?: any               // 所有的端口工具
    defaultOpen?: boolean     // 默认打开选择
    defaultCheck?: boolean    // 默认打开是否选中
    defaultOpenFun?: (check: boolean) => (void) | null | undefined // 改变默认打开
    modal?: boolean           // 当前状态  true 创作  false 学习
    setCurrentDefault?: (data:number) => void
    currentDefault?: number
}

const PortItem: React.FC<PortItemProps> = memo((props) => {

    const { image = imagesvg, name, port, editor, del, checked, changeChecked, edited = true, ports = [], defaultOpen = false, defaultCheck, defaultOpenFun, modal = true } = props

    const [open, setOpen] = useState(false)

    console.log('image svg', image, imagesvg)

    return (
        <div className='portitem'>
            <img src={image || imagesvg} alt="" className='portitem-img'/>
            <Tooltip placement='bottomLeft' overlayClassName={'tooltip-portitem'} title={name} arrow={false}>  
                <span className='portitem-title'>{name}</span>
            </Tooltip>
            <span className={edited && editor ? 'portitem-light' : 'portitem-dark'} style={{marginRight: '10px'}} onClick={() => {edited && editor && setOpen(true)}}>编辑</span>
            <span className={edited && del ? 'portitem-light' : 'portitem-dark'} onClick={() => {edited && del && del?.()}}>删除</span>
            <span className='portitem-line'></span>
            <Checkbox checked={checked} disabled={!edited} onChange={(e) => { edited && changeChecked?.(e.target.checked);

                    if (props.setCurrentDefault!==undefined && props.currentDefault!==undefined && props.currentDefault===port ) {
                        props?.setCurrentDefault(0)
                    }
                                                                                                            } } style={{marginLeft: defaultOpen ? '4px' : ''}}></Checkbox>
            <AddTools open={open} onCancel={() => { setOpen(false) }} finish={editor || (() => {})} ports={ports} initValue={{name, port, image}}/>
            {
                defaultOpen && <Checkbox checked={defaultCheck} disabled={!modal || !checked} onChange={(e) => { defaultOpenFun?.(e.target.checked)} } style={{marginLeft: '42px'}}></Checkbox>
            }
        </div>
    )
})

export default PortItem